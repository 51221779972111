import React, { useState } from "react";
import { Button, Divider, Grid, TextField, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link, useParams } from "react-router-dom";
import "./Search.css";
import Datatable from "../../Components/Datatable/Datatable";
import { getLeads, unSetLead, getDropdown } from "./ApiCalls";
import { Alert } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useQuery } from "react-query";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InputMultiAutocomplete } from '../../Components/Common';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Cookies from 'js-cookie';

const Search = () => {

  const page_id = useParams(); 

  const [myPage, setMyPage] = useState(0);
  const handleCurrentPage = (currentpage) => {
    setMyPage(currentpage);
  }
  
  React.useEffect(() => { 
    if(page_id.pageId && page_id.pageId !== 'undefined'){
      let pid = page_id.pageId.split('=')[1];
      setMyPage(parseInt(pid));
    }
  }, [page_id]); 

  // delete confirm box
  const [open, setOpen] = useState(false);
  const [leadId, setLeadId] = useState('');
  const handleClickOpen = (lid) => {
    setOpen(true);
    setLeadId(lid);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [multipleIds, setMultipleIds] = useState([]);

  const query = useQuery("allleads", () => {
    return getLeads()
  },{})
  const getdropdown = useQuery("dropdown", () => {
    return getDropdown()
  },{})

  if(query.isLoading || getdropdown.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  if(query.isError || getdropdown.isError){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }

  console.log(getdropdown)

  const handleCallback = (childData) =>{
    if(childData === 'delete'){
      console.log('delete')
      deleteLead(multipleIds)
    }else{
      setMultipleIds(childData)
      console.log(childData)
    }
  }

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  // const redirect = () =>{
  //   navigate("/leads");
  // }

  const deleteLead = async(lids) => {
    unSetLead(lids)
    .then((response) => {
      if(response.data === 'lead deleted'){
        handleClose();
        notify("Leads Deleted Successfully");
      }
      console.log(response)
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get('source'))
    console.log(data.get('service_type'))
};

  const columns = [
    {
      name: "Actions",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 10000
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 10000
          }
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Box sx={{ display: "flex" }}>
              <Tooltip
                title="Quick Edit"
                component={Link}
                to={`/quick-edit/${query.data[dataIndex].id}/mypage=${myPage}`} 
              >
                <IconButton><AutoFixHighIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              <Tooltip
                title="Edit"
                component={Link}
                to={`/edit-lead/${query.data[dataIndex].id}/mypage=${myPage}`}
                // onClick={() => {
                //   alert(query.data[dataIndex].id);
                // }}
              >
                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              <Tooltip
                title="View"
                component={Link}
                to={`/view-lead/${query.data[dataIndex].id}/mypage=${myPage}`}
              >
                <IconButton><VisibilityIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin'  ? 
              <Tooltip
                title="Delete"
                component="label"
                onClick={() => handleClickOpen(query.data[dataIndex].id)} 
              ><IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
              </Tooltip>
              : ''} 
            </Box>
            // <Box sx={{ display: "flex" }}>
            //   <IconButton
            //     color="primary"
            //     aria-label="edit"
            //     component="label"
            //     onClick={() => {
            //       alert(data[dataIndex].id);
            //     }}
            //   >
            //     <EditIcon />
            //   </IconButton>
            //   <IconButton
            //     color="error"
            //     aria-label="delete"
            //     component="label"
            //     onClick={() => {
            //       alert(data[dataIndex].id);
            //     }}
            //   >
            //     <DeleteIcon />
            //   </IconButton>
            // </Box>
          );
        },
      },
    },
    {
      name: "name",
      label: "Lead Name",
    },
    {
      name: "mobile",
      label: "Mobile 1",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "pname",
      label: "Project Name",
    },
    {
      name: "lead_status",
      label: "Lead Status",
    },
    {
      name: "lead_date",
      label: "Lead Date",
    },
    {
      name: "source",
      label: "Source",
    },
    {
      name: "city",
      label: "City",
    },
    {
      name: "locality",
      label: "Locality",
    },
    {
      name: "assigned_telecaller",
      label: "Assigned Telecaller",
    },
    {
      name: "assigned_sales_manager",
      label: "Assigned Sales Manager",
    },
    {
      name: "assigned_team_leader",
      label: "Team Leader",
    },
    {
      name: "assigned_branch_admin",
      label: "Assigned Branch Admin",
    },
    {
      name: "posted_by",
      label: "Posted By",
    },
    {
      name: "id",
      label: "Id",
      options: {
        // display: false,
      },
    }
  ];

  return (
    <>
     {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' || Cookies.get('role') === 'Team Leader' || Cookies.get('role') === 'Sales Manager'  ? 
     <Breadcrumb 
        PageName="Total Leads" 
        BackButton={[true, 'Add', '/add-lead', <AddIcon />]} 
        AssignButton={[multipleIds.length !== 0 ? true : false, 'Assign Leads', `/assign-leads-to/${multipleIds}/mypage=${myPage}`, <AddIcon />]}
      />  
      :
      <Breadcrumb 
        PageName="Total Leads" 
        BackButton={[true, 'Add', '/add-lead', <AddIcon />]}  
      /> 
      }
    <Box component="form" onSubmit={handleSubmit}>
    <Accordion style={{margin: '0%'}} className="accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{paddingLeft: '24px', paddingRight: '24px'}}
        >
          <Typography variant="h6" component='h6' m={0} sx={{fontSize: '16px', fontWeight: 600}}>Advance Search</Typography> 
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{px: '0'}}> 
          <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Source"
                options={getdropdown.data.source.data.map((source)=>({title:source.source}))}
                inputname="source"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Service Type"
                options={[
                  { title: 'New Project'}, 
                  { title: 'Rent'},
                  { title: 'Resale'}, 
                ]}
                inputname="service_type"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Project Name"
                options={getdropdown.data.project.data.map((project)=>({title:project.pname}))}
                inputname="pname"
              />
            </Grid>
            {/* <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Lead Quality"
                options={[
                  { title: 'Good'}, 
                  { title: 'Average'},
                  { title: 'Poor'}, 
                ]}
                inputname=""
              />
            </Grid> */}
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Country"
                options={[
                  { title: 'India'}, 
                  { title: 'Dubai'},
                  { title: 'US'}, 
                ]}
                inputname="country"
              />
            </Grid> 
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="State"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="state"
              />
            </Grid>
          </Grid>

          <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="City"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="city"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Locality"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="locality"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Property Type"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="ptype"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Property Category"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="pcategory"
              />
            </Grid> 
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Configuration"
                options={getdropdown.data.configuration.data.map((config)=>({title:config.configuration}))}
                inputname="configuration"
              />
            </Grid>
          </Grid>

          <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
            
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Lead Status"
                options={getdropdown.data.status.data.map((status)=>({title:status.status}))}
                inputname="status"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Branch Admin"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''},  
                ]}
                inputname="branch_admin"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Team Leader"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="team_leader"
              />
            </Grid>
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Sales Manager"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="sales_manager"
              />
            </Grid> 
            <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Tele Caller"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname="tele_caller"
              />
            </Grid>
          </Grid>

          <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
            
            {/* <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="VDNB Leads"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname=""
              />
            </Grid>   */}
            {/* <Grid item md={2} >
              <InputMultiAutocomplete
                inputLable="Fresh Leads"
                options={[
                  { title: ''}, 
                  { title: ''}, 
                  { title: ''}, 
                ]}
                inputname=""
              />
            </Grid>   */}
          </Grid>

          <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item md={2} >
                <MobileDatePicker 
                  className="mobiledatepick"
                  label="Lead Date From"
                  inputFormat="MM/DD/YYYY"
                  // onChange={}
                  // onAccept={}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                /> 
              </Grid>
              <Grid item md={2} >
                <MobileDatePicker 
                  className="mobiledatepick"
                  label="Lead Date To"
                  inputFormat="MM/DD/YYYY"
                  // onChange={}
                  // onAccept={}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                /> 
              </Grid>
              <Grid item md={2} >
                <MobileDatePicker 
                  className="mobiledatepick"
                  label="Followup Date From"
                  inputFormat="MM/DD/YYYY"
                  // onChange={}
                  // onAccept={}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                />  
              </Grid>
              <Grid item md={2} >
                <MobileDatePicker 
                  className="mobiledatepick"
                  label="Followup Date To"
                  inputFormat="MM/DD/YYYY"
                  // onChange={}
                  // onAccept={}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                />  
              </Grid> 
            </LocalizationProvider>
          </Grid>
 
          <Grid container>
            <Grid item md={12} justifyContent='left' textAlign='left'>
              <Divider sx={{my: 2}} />
              <Button type="submit" style={{marginLeft: '1.8rem'}} variant="outlined" size="medium" startIcon={<SearchIcon />} sx={{textTransform: 'capitalize'}}>
                Search Leads
              </Button>
            </Grid>  
          </Grid> 
        </AccordionDetails>
      </Accordion>
      </Box>

      <br />
      
      <Datatable sendDataToParent = {handleCallback} TabelTitle="Total Lead" data={query.data === 'No Data Found' ? [] : query.data} columns={columns} currentPage = {myPage} changeCurrentPage = {handleCurrentPage}  />

      {/* delete confirm */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => deleteLead(leadId)} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
      
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
export default Search;
