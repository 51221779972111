import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getSourceCount = async() => {
    try{
        const facebookCount = await Axios.post(`${API_URL}/desktop/dashboard/getFacebookLeadCount`);
        const googleCount = await Axios.post(`${API_URL}/desktop/dashboard/getGoogleLeadCount`);
        const housingCount = await Axios.post(`${API_URL}/desktop/dashboard/getHousingLeadCount`);
        const acresCount = await Axios.post(`${API_URL}/desktop/dashboard/getAcresLeadCount`);
        const magicbricksCount = await Axios.post(`${API_URL}/desktop/dashboard/getMagicbricksLeadCount`);
        // const response = await Axios.post('http://localhost:3004/status/getAllStatus');
        return {facebookCount, googleCount, housingCount, acresCount, magicbricksCount};
    }catch(err){
        return err;
    }
}

const getAllLeadStatusList = async () => {
    try {
        const response = await Axios.post(
            `${API_URL}/desktop/dashboard/getLeadStatusCount`
        );
        return response;
    } catch (err) {
        return err;
    }
};

const getAllUsersList = async () => {
    try {
        const response = await Axios.post(
            `${API_URL}/desktop/dashboard/getTotalUsers`
        );
        return response;
    } catch (err) {
        return err;
    }
};

export {getSourceCount, getAllLeadStatusList, getAllUsersList}