import Axios from 'axios';
import { API_URL } from '../../App';

const getLeads = async(LIMIT,page,status) =>{
    try{
        const response = await Axios.post(`${API_URL}/status/getNewLeads`, {limit: LIMIT, page: page, status: status.leadStatus});
        return {response, nextPage: page + 10 };
    }catch(err){
        return err;
    }
}

const getNewLeadCount = async() =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/status/getNewLeadCount`);
        return responseCount.data[0].newlead_count
    }catch(err){
        return err;
    }
}

export {getLeads, getNewLeadCount}