import React from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Button, Tooltip, Divider, Grid, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { getLeads, unSetLead, getSearchResult, getSearchResultCount, getDropdown, setClick } from './ApiCalls';
import AddIcon from "@mui/icons-material/Add";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import MasterCSV from '../../Components/masterCSV.csv';
import UsersCSV from '../../Components/usersCSV.csv';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Cookies from 'js-cookie';
import '../reacttable.css';
import { useQueryClient, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { isSameDay, subDays } from 'date-fns';
import { InputMultiAutocomplete, AdvanceSearchInput } from '../../Components/Common';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {CustomInputField, CustomFormGroup} from "../../Components/Common";
import { Alert } from "@mui/material";
import { Country, State, City } from '../../Data/CountryStateCity';
import Select, { components } from "react-select";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ResidentialPropertyCategory, CommercialPropertyCategory } from '../../Data/Data';
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ImageIcon from "@mui/icons-material/Image";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import ReactWhatsapp from 'react-whatsapp';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { API_URL } from '../../../DesktopApp';

// 
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  searchCount
}) {
  
  const today_date = dayjs().format('YYYY-MM-DD') 
  const yesterday =  dayjs(subDays(new Date(), 1)).format('YYYY-MM-DD');
  const [controlledPageIndex, setControlledPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [leadId, setLeadId] = React.useState([]);
  const [search, setSearch] = React.useState('false');
  const [searchData, setSearchData] = React.useState('');
  const [createdtfrom, setcreatedtfrom] = React.useState();
  const [createdtto, setcreatedtto] = React.useState();
  const [followupdtfrom, setfollowupdtfrom] = React.useState();
  const [followupdtto, setfollowupdtto] = React.useState();

  // 
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [ptype, setPtype] = React.useState();
  const [file, setFile] = React.useState(null);
  const [importOpen, setImportOpen] = React.useState(false);

  // check all 
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [list, setList] = React.useState([]);
  
  // reset
  const [clearEmpty, setClearEmpty] = React.useState(false);
  const formRef = React.useRef(null);
  
  const [reload, setReload] = React.useState(false);
  
  // 
  const [importTabs, setImportTabs] = React.useState('1');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: localStorage.getItem('tablePageIndex') !== 'null' ? Number(localStorage.getItem('tablePageIndex')) : controlledPageIndex, pageSize: 30}, 
      manualPagination: true,
      autoResetPage: false,
      pageCount: searchData ? searchCount : controlledPageCount
    },
    useSortBy,
    usePagination
  )

  const getdropdown = useQuery("dropdown", () => {
    return getDropdown()
  },{})

  React.useEffect(() => {
    if(search === 'true'){
      fetchData({ pageIndex, pageSize, searchData, createdtfrom, createdtto, followupdtfrom, followupdtto })
    }else{
      fetchData({ pageIndex, pageSize })
    }
    setReload(false);
  }, [fetchData, pageIndex, pageSize, search, searchData, createdtfrom, createdtto, followupdtfrom, followupdtto, reload])

  React.useEffect(() => {
    setList(page);  
  }, [page, list, leadId]);

  if(getdropdown.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  if(getdropdown.isError){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget);
    setSearch('true') 
    setSearchData(data)  
  }

  const handleChangePtype = (event) => {
    setPtype(event.value);
  };

  const handleClickOpen = (lid) => {
    setOpen(true);
    setLeadId(lid);
  };
  
  const handleClose = () => {
    setOpen(false);
    setLeadId([])
  };

  // const handleRemove = (valueToRemove) => {
  //   const index = leadId.indexOf(valueToRemove);
  //   if (index !== -1) {
  //     const newArray = leadId.slice(0, index).concat(leadId.slice(index + 1));
  //     setLeadId(newArray);
  //   }
  // };

  const deleteLead = async(lids) => {
    unSetLead(lids)
    .then((response) => {
      if(response.data === 'lead deleted'){
        handleClose();
        alert("Leads Deleted Successfully");
        setReload(true);
      }
      console.log(response)
    })
    .catch((err) => {
      console.log(err);
    });
  };
  
  const next = () => {
    nextPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.ceil(pageIndex+1));
  }

  const prev = () => {
    previousPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.ceil(pageIndex-1));
  }

  const handleClickedNewLead = (leadid) => {
    // if(clicked === 0){
      setClick(leadid);
   // }
  }

  function clearField(){
    setClearEmpty(true);
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity(null);
    setSearch('false');
    setSearchData('');
    setcreatedtfrom();
    setcreatedtto();
    setfollowupdtfrom();
    setfollowupdtto();
    setTimeout(() => {
      setClearEmpty(false);
    }, 100);
  }

  // 
  function filterCity(selectedState, City){ 
    let myCity = City.filter(City => selectedState.countryCode.indexOf(City.countryCode) !== -1);
    return myCity.filter(citynew => selectedState.isoCode.indexOf(citynew.stateCode) !== -1);
  }

  // 
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );
  };
  
  const handleMasterFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.type;
      if (fileType === "text/csv" || fileType === "application/vnd.ms-excel") {
        setFile(uploadedFile);
        Papa.parse(uploadedFile, {
          header: true,
          complete: (results) => {
            const dataResult = results.data; 
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(dataResult.splice(0, dataResult.length - 1)),
              // body: JSON.stringify(dataResult.splice(0, dataResult.length)),
            }; 
            fetch(`${API_URL}/desktop/leads/upload-csv-master`, requestOptions)
              .then((response) => response.json())
              .then((data) => 
                data.code === 'ER_BAD_NULL_ERROR' ?
                  setFile(data.code) 
                : 
                  handleImportClose('successed')
              );
              // setCSVData(dataResult.splice(0, dataResult.length - 1));
          },
        });
        // setFile(null);
      } else {
        alert("Please upload a CSV or Excel file.");
      }
    }
  };

  const handleUserFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.type;
      if (fileType === "text/csv" || fileType === "application/vnd.ms-excel") {
        setFile(uploadedFile);
        Papa.parse(uploadedFile, {
          header: true,
          complete: (results) => {
            const dataResult = results.data; 
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(dataResult.splice(0, dataResult.length - 1)),
              // body: JSON.stringify(dataResult.splice(0, dataResult.length)),
            }; 
            fetch(`${API_URL}/desktop/leads/upload-csv-user`, requestOptions)
              .then((response) => response.json())
              .then((data) => 
                data.code === 'ER_BAD_NULL_ERROR' ?
                  setFile(data.code) 
                : 
                  handleImportClose('successed')
              );
              // setCSVData(dataResult.splice(0, dataResult.length - 1));
          },
        });
        // setFile(null);
      } else {
        alert("Please upload a CSV or Excel file.");
      }
    }
  };

  // import tabs
  const handleTabChange = (event, newValue) => {
    setImportTabs(newValue);
  };
  const handleImportOpen = () => {
    setImportOpen(true); 
  };
  const handleImportClose = (status) => {
    setImportOpen(false); 
    if(status === 'successed'){
      setFile('successed'); 
      setTimeout(() => {  
        window.location.reload();
      }, 1600)
    }else{
      setFile(null);
    } 
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setLeadId(list.map(id => id.original.l_id))
    if (isCheckAll) {
      setLeadId([]);
    }
  };
  
  const handleSelect = (e) => {
    const { value, checked } = e.target; 
    setLeadId([...leadId, parseInt(value)])
    if (!checked) {
      setLeadId(leadId.filter(item => item !== parseInt(value)));
    } 
  };

  // 
  const CSVDownload = (role) => { 
    if(role === 'master'){
      fetch(MasterCSV).then(response => {
        response.blob().then(blob => {
          // Creating new object of CSV file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'masterCSV.csv';
          alink.click();
        })
      })
    }else if(role === 'users'){
      fetch(UsersCSV).then(response => {
        response.blob().then(blob => {
          // Creating new object of CSV file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'usersCSV.csv';
          alink.click();
        })
      })
    } 
  }

  return (
    <>
    {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' || Cookies.get('role') === 'Team Leader' || Cookies.get('role') === 'Sales Manager' || Cookies.get('role') === 'Branch Admin'  ? 
     <Breadcrumb 
        PageName="Total Leads" 
        BackButton={[Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? true : false, 'Add', '/add-lead', <AddIcon />]} 
        AssignButton={[leadId.length !== 0 ? true : false, 'Assign Leads', `/assign-leads-to/${leadId}/mypage=${pageIndex}/direct`, <AddIcon />]}
        DeleteAll={[leadId.length !== 0 && (Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin') ? true : false, 'Delete', () => handleClickOpen(leadId), <DeleteOutlineOutlinedIcon />]}
        ImportLeads={[Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? true : false, 'Import', () => handleImportOpen(), <FileDownloadOutlinedIcon />]}
      />  
      :
      <Breadcrumb 
        PageName="Total Leads" 
        BackButton={[true, 'Add', '/add-lead', <AddIcon />]}  
      /> 
      }
      
      {/* {leadId.length !== 0 ? <Button
              variant="outlined"
              sx={{ mx: 1, justifyContent: 'left', position: 'absolute',
              right: '1rem',
              top: '5.5rem',
              zIndex: 1000 }}
              startIcon={<DeleteIcon />}
              onClick={() => handleClickOpen(leadId)}
      >Delete All</Button>  : ''} */}

      {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ?
        <CSVLink
          data={data}
          filename="total-leads.csv" 
          style={{position: 'absolute', right: '2rem', top: '5.5rem'}}
        >
          <Button
            variant="outlined" 
            startIcon={<FileUploadOutlinedIcon />} 
          >
            Export
          </Button>
        </CSVLink>
      : null}

        <Box component="form" onSubmit={handleSubmit} ref={formRef}>
            <Accordion style={{margin: '0%'}} className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{paddingLeft: '24px', paddingRight: '24px'}}
            >
              <Typography variant="h6" component='h6' m={0} sx={{fontSize: '16px', fontWeight: 600}}>Advance Search</Typography> 
            </AccordionSummary>
            <Divider />
            <AccordionDetails sx={{px: '0'}}> 
              <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
                <Grid item md={2} >
                  <AdvanceSearchInput 
                    inputLable="Source"
                    inputname="source"
                    resetField={clearEmpty}
                    options={getdropdown.data.source.data !== 'No Data Found' ? getdropdown.data.source.data.map((source)=>({title:source.source})) : []}
                  />
                </Grid>
                <Grid item md={2} >
                  <AdvanceSearchInput 
                    inputLable="Service Type"
                    inputname="service_type"
                    resetField={clearEmpty}
                    options={[
                      { title: 'New Project'}, 
                      { title: 'Rent'},
                      { title: 'Resale'}, 
                    ]}
                  />
                </Grid>
                <Grid item md={2} > 
                  <AdvanceSearchInput 
                    inputLable="Project Name"
                    inputname="pname"
                    resetField={clearEmpty}
                    options={getdropdown.data.project !== 'No Data Found' ? getdropdown.data.project.data.map((project)=>({title:project.pname})) : []}
                  />
                </Grid>
                {/* <Grid item md={2} >
                  <InputMultiAutocomplete
                    inputLable="Lead Quality"
                    options={[
                      { title: 'Good'}, 
                      { title: 'Average'},
                      { title: 'Poor'}, 
                    ]}
                    inputname=""
                  />
                </Grid> */}
                <Grid item md={2} > 
                  <Select
                    name = "country"
                    isClearable={true}
                    isSearchable={true}
                    options={Country}
                    placeholder="Country"
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedCountry}
                    onChange={
                      (item) => {
                        setSelectedCountry(item);
                        setSelectedState([]);
                        setSelectedCity([]);
                      }
                    } 
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      option: (styles, { data, isDisabled, isFocused, isSelected }) => { 
                        return {
                          ...styles,
                          backgroundColor: isFocused ? "rgba(0, 0, 0, 0.04)" : isSelected ? "rgba(25, 118, 210, 0.12)" : null,
                          color: "#333333",
                          fontSize: '14px'
                        };
                      },
                      placeholder: base => ({
                        ...base,
                        fontSize: '14px'
                      }),
                      control: (base) => ({
                        ...base,
                        fontSize: '14px'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: "rgba(0, 0, 0, 0.54)"
                      })
                    }}
                    components={{ DropdownIndicator }}
                  />
                </Grid> 
                <Grid item md={2} > 
                  <Select
                    name = "state" 
                    // isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="State"
                    options={selectedCountry !== null ? State.filter(State => selectedCountry.isoCode.indexOf(State.countryCode) !== -1) : []}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedState}
                    onChange={(item) => {
                      setSelectedState(item);
                      setSelectedCity([]);
                    }}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      option: (styles, { data, isDisabled, isFocused, isSelected }) => { 
                        return {
                          ...styles,
                          backgroundColor: isFocused ? "rgba(0, 0, 0, 0.04)" : isSelected ? "rgba(25, 118, 210, 0.12)" : null,
                          color: "#333333",
                          fontSize: '14px'
                        };
                      },
                      placeholder: base => ({
                        ...base,
                        fontSize: '14px'
                      }),
                      control: (base) => ({
                        ...base,
                        fontSize: '14px'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: "rgba(0, 0, 0, 0.54)"
                      })
                    }}
                    components={{ DropdownIndicator }}
                  />
                </Grid>
              </Grid>

              <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
                <Grid item md={2} > 
                  <Select
                    name = "city" 
                    isClearable={true}
                    isSearchable={true}
                    placeholder="City"
                    options={selectedState !== null && selectedState.length !== 0 ? 
                      filterCity(selectedState, City)
                    : []}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedCity}
                    onChange={(item) => {
                      setSelectedCity(item);
                    }}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      option: (styles, { data, isDisabled, isFocused, isSelected }) => { 
                        return {
                          ...styles,
                          backgroundColor: isFocused ? "rgba(0, 0, 0, 0.04)" : isSelected ? "rgba(25, 118, 210, 0.12)" : null,
                          color: "#333333",
                          fontSize: '14px'
                        };
                      },
                      placeholder: base => ({
                        ...base,
                        fontSize: '14px'
                      }),
                      control: (base) => ({
                        ...base,
                        fontSize: '14px'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: "rgba(0, 0, 0, 0.54)"
                      })
                    }}
                    components={{ DropdownIndicator }}
                  />
                </Grid>
                <Grid item md={2} > 
                  <AdvanceSearchInput 
                    inputLable="Locality"
                    inputname="locality"
                    resetField={clearEmpty}
                    options={getdropdown.data.locality.data !== 'No Data Found' ? getdropdown.data.locality.data.map((locality)=>({title:locality.locality})) : []}
                  />
                </Grid>
                <Grid item md={2} > 
                  <AdvanceSearchInput 
                    inputLable="Property Type"
                    inputname="ptype"
                    resetField={clearEmpty}
                    options={[
                      { title: 'Residential'}, 
                      { title: 'Commercial'}, 
                    ]}
                  />
                </Grid>
                <Grid item md={2} > 
                  <AdvanceSearchInput 
                    inputLable="Property Category"
                    inputname="pcategory"
                    resetField={clearEmpty}
                    options={[
                      { title: 'Apartment'}, 
                      { title: 'Bunglow'}, 
                      { title: 'Duplex'}, 
                      { title: 'Farm House'},
                      { title: 'Independent House'}, 
                      { title: 'Pent House'}, 
                      { title: 'Service/Studio Apartments'}, 
                      { title: 'Villa'},
                      { title: 'Commercial Office'}, 
                      { title: 'Commercial Shops'}, 
                      { title: 'Commercial Showrooms'}, 
                      { title: 'Commercial Space'},
                      { title: 'Hotels/Resorts'}, 
                      { title: 'Office Space In It/Buisness Park'}, 
                      { title: 'Commercial Warehouse'}, 
                      { title: 'Godowns and Plots'},
                      { title: 'Factories'}, 
                      { title: 'Plant and Machinery'},
                    ]}
                  />
                </Grid> 
                <Grid item md={2} > 
                  <AdvanceSearchInput 
                    inputLable="Configuration"
                    inputname="configuration"
                    resetField={clearEmpty}
                    options={getdropdown.data.configuration.data !== 'No Data Found' ? getdropdown.data.configuration.data.map((config)=>({title:config.configuration})) : []}
                  />
                </Grid>
              </Grid>

              <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
                
                <Grid item md={2} > 
                  <AdvanceSearchInput 
                    inputLable="Lead Status"
                    inputname="status"
                    resetField={clearEmpty}
                    options={getdropdown.data.status.data !== 'No Data Found' ? getdropdown.data.status.data.map((status)=>({title:status.status})) : []}
                  />
                </Grid>
                {getdropdown.data.users.hasOwnProperty('Branch Admin') ? 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Branch Admin"
                      inputname="branch_admin"
                      inputRole="advanceSearch"
                      resetField={clearEmpty}
                      options={getdropdown.data.users['Branch Admin'].map((ba)=>({title:ba.label, value:ba.value}))}
                    />
                  </Grid> 
                : 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Branch Admin"
                      inputname="branch_admin" 
                      resetField={clearEmpty}
                      options={[]}
                      disabled={true}
                    />
                  </Grid>
                }

                {getdropdown.data.users.hasOwnProperty('Team Leader') ? 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Team Leader"
                      inputname="team_leader"
                      inputRole="advanceSearch"
                      resetField={clearEmpty}
                      options={getdropdown.data.users['Team Leader'].map((tl)=>({'title':tl.label, 'value':tl.value}))}
                    />
                  </Grid> 
                : 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Team Leader"
                      inputname="team_leader" 
                      resetField={clearEmpty}
                      options={[]}
                      disabled={true}
                    />
                  </Grid>
                }

                {getdropdown.data.users.hasOwnProperty('Sales Manager') ? 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Sales Manager"
                      inputname="sales_manager"
                      inputRole="advanceSearch"
                      resetField={clearEmpty}
                      options={getdropdown.data.users['Sales Manager'].map((sm)=>({'title':sm.label, 'value':sm.value}))}
                    />
                  </Grid> 
                : 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Sales Manager"
                      inputname="sales_manager" 
                      resetField={clearEmpty}
                      options={[]}
                      disabled={true}
                    />
                  </Grid>
                }

                {getdropdown.data.users.hasOwnProperty('Tele Caller') ? 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Tele Caller"
                      inputname="tele_caller"
                      inputRole="advanceSearch"
                      resetField={clearEmpty}
                      options={getdropdown.data.users['Tele Caller'].map((tc)=>({'title':tc.label, 'value':tc.value}))}
                    />
                  </Grid> 
                : 
                  <Grid item md={2} > 
                    <AdvanceSearchInput 
                      inputLable="Tele Caller"
                      inputname="tele_caller" 
                      resetField={clearEmpty}
                      options={[]}
                      disabled={true}
                    />
                  </Grid>
                } 
              </Grid>

              {/* <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' > */}
                {/* <Grid item md={2} >
                  <InputMultiAutocomplete
                    inputLable="VDNB Leads"
                    options={[
                      { title: ''}, 
                      { title: ''}, 
                      { title: ''}, 
                    ]}
                    inputname=""
                  />
                </Grid>   */}
                {/* <Grid item md={2} >
                  <InputMultiAutocomplete
                    inputLable="Fresh Leads"
                    options={[
                      { title: ''}, 
                      { title: ''}, 
                      { title: ''}, 
                    ]}
                    inputname=""
                  />
                </Grid>   */}
              {/* </Grid> */}
                
              <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item md={2} >
                    <MobileDatePicker 
                      className="mobiledatepick"
                      label="Lead Date From"
                      inputFormat="MM/DD/YYYY"
                      value={createdtfrom}
                      onChange={(value)=>{setcreatedtfrom(dayjs(value).format('YYYY-MM-DD'))}}
                      // onAccept={}
                      renderInput={(params) => <TextField {...params} />}
                      // disablePast
                    /> 
                  </Grid>
                  <Grid item md={2} >
                    <MobileDatePicker 
                      className="mobiledatepick"
                      label="Lead Date To"
                      inputFormat="MM/DD/YYYY"
                      value={createdtto}
                      onChange={(value)=>{setcreatedtto(dayjs(value).format('YYYY-MM-DD'))}}
                      // onAccept={}
                      renderInput={(params) => <TextField {...params} />}
                      //disablePast
                    /> 
                  </Grid>
                  <Grid item md={2} >
                    <MobileDatePicker 
                      className="mobiledatepick"
                      label="Followup Date From"
                      inputFormat="MM/DD/YYYY"
                      value={followupdtfrom}
                      onChange={(value)=>{setfollowupdtfrom(dayjs(value).format('YYYY-MM-DD'))}}
                      // onAccept={}
                      renderInput={(params) => <TextField {...params} />}
                      //disablePast
                    />  
                  </Grid>
                  <Grid item md={2} >
                    <MobileDatePicker 
                      className="mobiledatepick"
                      label="Followup Date To"
                      inputFormat="MM/DD/YYYY"
                      value={followupdtto}
                      onChange={(value)=>{setfollowupdtto(dayjs(value).format('YYYY-MM-DD'))}}
                      // onAccept={}
                      renderInput={(params) => <TextField {...params} />}
                      //disablePast
                    />  
                  </Grid> 
                  
                </LocalizationProvider>
                {/* <Grid item md={12}>
                    
                  <CustomFormGroup
                    formlabel="Search Any Text"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="anytext"
                        placeholder="Enter Text"
                        resetField={clearEmpty}
                      />
                    }
                  />
                  </Grid> */}
              </Grid>

              <Grid container>
                <Grid item md={12} justifyContent='left' textAlign='left'>
                  <Divider sx={{my: 2}} />
                  <div style={{position: 'absolute', left: '1.8rem', width: '95%'}}>
                    <CustomFormGroup
                      // formlabel="Custom text: "
                      FormField={
                        <CustomInputField
                          type="text"
                          name="anytext"
                          placeholder="Enter custom text.,"
                          resetField={clearEmpty}
                        />
                      }
                    />
                  </div>
                  <Button type="reset" style={{float: 'right', right: '2rem'}} variant="outlined" size="medium" startIcon={<RestartAltIcon />} sx={{textTransform: 'capitalize'}} onClick={() => clearField()}>
                  Reset Search Result
                  </Button>
                  <Button type="submit" style={{float: 'right', right: '3rem'}} variant="outlined" size="medium" startIcon={<SearchIcon />} sx={{textTransform: 'capitalize'}}>
                    Search Leads
                  </Button>
                </Grid>  
              </Grid> 
            </AccordionDetails>
        </Accordion>
      </Box>

      <br />

      <div className='table'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              <th ><input 
                  type="checkbox" 
                  id='selectAll'
                  name='selectAll'
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                /></th> 
              <th >Action</th>
              <th >Sr. no</th>
              {headerGroup.headers.map((column, index) => (
                <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())} style={{position: 'relative'}}>
                  {column.render('Header')}
                  <span style={{color: '#e9e9e9', position: 'absolute', top: '1rem', right: '0.3rem'}}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FilterAltIcon />
                      ) : (
                        <FilterAltOffIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => { 
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()} style={row.original.clicked === 0 ? {background: '#1976'} : {}}>
                <td >
                  <input 
                      type="checkbox" 
                      name={'checkbox'+i} 
                      id={'checkbox'+i} 
                      value={row.original.l_id} 
                      // onChange={(e)=>{e.target.checked ? setLeadId(current => [...current, row.original.l_id]) : handleRemove(row.original.l_id)}}  
                      onChange={handleSelect}
                      checked={leadId.includes(row.original.l_id)}
                    />
                </td> 
                <td >  
                  <Tooltip
                    title="Quick Edit"
                    component={Link}
                    to={`/quick-edit/${row.original.l_id}/mypage=${pageIndex}`} 
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    onClick={row.original.clicked === 0 ? ()=>handleClickedNewLead(row.original.l_id) : ()=>{}}
                  >
                    <IconButton ><AutoFixHighIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Edit"
                    component={Link}
                    to={`/edit-lead/${row.original.l_id}/mypage=${pageIndex}`}
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    onClick={row.original.clicked === 0 ? ()=>handleClickedNewLead(row.original.l_id) : ()=>{}}
                  >
                    <IconButton><EditIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  <Tooltip
                    title="View"
                    component={Link}
                    to={`/view-lead/${row.original.l_id}/mypage=${pageIndex}`}
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    onClick={row.original.clicked === 0 ? ()=>handleClickedNewLead(row.original.l_id) : ()=>{}}
                  >
                    <IconButton><VisibilityIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  {/* {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin'  ?
                    <Tooltip
                      title="Delete"
                      component="label"
                      onClick={() => handleClickOpen(row.original.l_id)} 
                      sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    ><IconButton><DeleteIcon sx={{ fontSize: "18px" }} /></IconButton>
                    </Tooltip>
                  : ''} */}
                  <hr style={{border: '#ffffff'}} />
                  <Tooltip
                    title="Call" 
                    sx={{py: 0, px: '5px', borderRadius: '5px'}} 
                    target='_blank'
                    href={'tel:+'+row.original.p_ccode+row.original.p_mob}
                  >
                    <IconButton><LocalPhoneIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip>
                  
                  <ReactWhatsapp 
                    number={'+'+row.original.p_ccode+row.original.p_mob} 
                    message="Hello,"  
                    style={{outline: 'none', border: 'none', background: 'transparent', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)', paddingLeft: '5px', paddingRight: '5px'}}
                  >
                    <Tooltip
                      title="Whatsapp" 
                      sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    >
                      <WhatsAppIcon sx={{ fontSize: "18px", position: 'relative', top: '5px' }} />
                    </Tooltip>
                  </ReactWhatsapp> 
                  
                  <Tooltip
                    title="Email" 
                    sx={{py: 0, px: '5px', borderRadius: '5px'}}
                    target='_blank'
                    href={'mailto:'+row.original.p_email}
                  >
                    <IconButton><MarkunreadIcon sx={{ fontSize: "18px" }} /></IconButton>
                  </Tooltip> 
                </td>
                <td>{i}</td>
                {row.cells.map((cell, i) => (
                  <>
                    {/* {cell.column.Header === 'Status' && row.cells[6].value ? 
                      <td key={i} style={{background: '#000000'}} {...cell.getCellProps()}>{cell.render('Cell')}</td> : cell.column.Header === 'Follow Up Date' && row.original.followup === 'Yes' ? <td>{dayjs(row.cells[1].value).format('YYYY-MM-DD') === today_date ? 'Today' : ''}</td> : <td key={i} {...cell.getCellProps()}>{cell.render('Cell')}</td>} */}
                    
                      {cell.column.Header === 'Lead Status' && row.cells[7].value ?               
                        <td key={i} {...cell.getCellProps()} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <Tooltip
                            title={cell.render('Cell')}
                            sx={{py: 0}}
                          > 
                            <span style={{backgroundColor: row.original.color, padding: '5px 8px', color: '#ffffff', overflowY: 'hidden', overflowX: 'scroll'}}>{cell.render('Cell')}</span> 
                          </Tooltip>
                        </td> 
                        // : 
                        // cell.column.Header === 'Follow Up Date' && row.original.followup === 'Yes'
                        // ? 
                        // <td>{dayjs(row.cells[1].value).format('YYYY-MM-DD') === today_date ? 'Today' : ''}</td> 
                      : 
                        <td key={i} {...cell.getCellProps()}>
                          {cell.column.Header === 'Lead Date' ? 
                            dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('YYYY-MM-DD') === today_date ? 
                              <Tooltip
                                title={'Today At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('hh:mm A')}
                                sx={{py: 0}}
                              > 
                                <span>{'Today At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('hh:mm A')}</span>
                              </Tooltip>
                            : dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('YYYY-MM-DD') === yesterday ? 
                              <Tooltip
                                title={'Yesterday At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('hh:mm A')}
                                sx={{py: 0}}
                              > 
                                <span>{'Yesterday At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('hh:mm A')}</span>
                              </Tooltip>
                            : 
                              <Tooltip
                                title={dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('MMM, DD YYYY - hh:mm A')}
                                sx={{py: 0}}
                              >   
                                <span>{dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[1].value : row.cells[2].value).format('MMM, DD YYYY - hh:mm A')}</span> 
                              </Tooltip>
                            : cell.column.Header === 'Follow Up Date' ? 
                            dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('YYYY-MM-DD') === today_date ? 
                            <Tooltip
                              title={'Today At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('hh:mm A')}
                              sx={{py: 0}}
                            > 
                              <span>{'Today At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('hh:mm A')}</span>
                            </Tooltip>
                          : dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('YYYY-MM-DD') ===     yesterday ? 
                            <Tooltip
                              title={'Yesterday At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('hh:mm A')}
                              sx={{py: 0}}
                            > 
                              <span>{'Yesterday At '+dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('hh:mm A')}</span>
                            </Tooltip>
                          : 
                            <Tooltip
                              title={dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('MMM, DD YYYY - hh:mm A')}
                              sx={{py: 0}}
                            >   
                              <span>{dayjs(Cookies.get('role') === 'Sales Manager' ? row.cells[7].value : row.cells[8].value).format('MMM, DD YYYY - hh:mm A')}</span> 
                            </Tooltip>
                          :   
                            <Tooltip
                              title={cell.render('Cell')}
                              sx={{py: 0}}
                            >
                              <span>{cell.render('Cell')}</span>
                            </Tooltip>
                          }
                        </td>
                      }
                  </>
                ))}
              </tr> 
            )
          })}

          <tr style={{backgroundColor:'#ffffff'}}>
            {loading ? (
              <td style={{border: 'none'}}>Loading...</td>
            ) : (
              <td style={{border: 'none'}}>
                Showing {page.length} of {searchData ? searchCount : controlledPageCount}{' '}
                results
              </td>
            )}

            <td className="pagination" style={{border: 'none'}}>
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => prev()} disabled={!canPreviousPage}>
                {'<'}
              </Button>{' '}

              <span>
                &nbsp;Page{' '}
                <strong>
                  {/* {Math.ceil(pageIndex + 1)} of {Math.ceil(searchData ? searchCount : controlledPageCount/pageSize)} */}
                  {Math.ceil(pageIndex + 1)} of {searchData ? (Math.ceil(searchCount/pageSize) ) : (Math.ceil(controlledPageCount/pageSize))}
                </strong>{' '}&nbsp;
              </span>

              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => next()} disabled={!canNextPage}>
                {'>'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(searchData ? Math.ceil(searchCount/pageSize - 1) : Math.ceil(controlledPageCount/pageSize - 1))} disabled={!canNextPage}>
                {'>>'}
              </Button>  
              
              {/* <span>
                | Go to page:{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px' }}
                />
              </span>{' '} */}
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                style={{fontSize: '13px', border: 'none'}}
              >
                {[30, 50, 100, 200].map((pageSize, i) => (
                  <option key={i} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
             
            </td>
          </tr>
        </tbody>
       </table>
      </div>

      {/* delete confirm */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => deleteLead(leadId)} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>

      {/* Import leads */} 
      <Dialog 
        open={importOpen}
        // onClose={handleImportClose} 
      >
        <DialogTitle>Import Leads</DialogTitle>
        <Button onClick={handleImportClose} style={{position: 'absolute', top: '1.2rem', right: '1.2rem', background: 'transparent', border: 'none', padding: '6px 0', textTransform: 'capitalize'}}>Cancel</Button> 

        <DialogContent style={{paddingBottom: '0.5rem', paddingTop: '0rem'}}>

          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          
          <TabContext value={importTabs}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} >
                <Tab style={{textTransform: 'capitalize'}} label="Master CSV" value="1" />
                <Tab style={{textTransform: 'capitalize'}} label="Users CSV" value="2" />
              </TabList>
            </Box>

            <TabPanel value="1" style={{position: 'relative', paddingLeft: '0%', paddingRight: '0%', paddingTop: '0.5rem'}}>
              <Button onClick={() => CSVDownload('master')} style={{color: '#8bc34a', position: 'absolute', top: '0.8rem', right: '1rem', background: 'transparent', border: 'none', padding: '6px 0', textTransform: 'capitalize', zIndex: '1000'}} startIcon={<CloudDownloadIcon />}>Master CSV</Button>
              <Button
                variant="text"
                component="label"
                sx={{ border: "2px dashed #DDDDDE", borderRadius: 2, display: "flex" }}
              >
                <Box
                  sx={{
                    width: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: 4,
                    color: "#5E6175",
                  }}
                >
                  <ImageIcon sx={{ height: "35px", width: "35px", mb: 2 }} />
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlign: "center", textTransform: 'lowercase'}}
                    gutterBottom
                  >
                    Select a file from your computer <br />  
                    accept's: ms-excel or .csv file <br />
                    (working with approx 2,000 leads CSV)
                  </Typography>
                  <input
                    type="file"
                    hidden
                    accept=".csv, application/vnd.ms-excel"
                    onChange={handleMasterFileUpload}
                  />
                  {file && (
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 2,
                        textTransform: "lowercase",
                        display: "flex",
                        placeItems: "center",
                      }}
                    >
                      {file.name}
                    </Typography>
                  )} 
                </Box>
              </Button>
            </TabPanel>

            <TabPanel value="2" style={{position: 'relative', paddingLeft: '0%', paddingRight: '0%', paddingTop: '0.5rem'}}>
              <Button onClick={() => CSVDownload('users')} style={{color: '#8bc34a', position: 'absolute', top: '0.8rem', right: '1rem', background: 'transparent', border: 'none', padding: '6px 0', textTransform: 'capitalize', zIndex: '1000'}} startIcon={<CloudDownloadIcon />}>Users CSV</Button>
              <Button
                variant="text"
                component="label"
                sx={{ border: "2px dashed #DDDDDE", borderRadius: 2, display: "flex" }}
              >
                <Box
                  sx={{
                    width: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: 4,
                    color: "#5E6175",
                  }}
                >
                  <ImageIcon sx={{ height: "35px", width: "35px", mb: 2 }} />
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlign: "center", textTransform: 'lowercase'}}
                    gutterBottom
                  >
                    Select a file from your computer <br />  
                    accept's: ms-excel or .csv file <br />
                    assignto_id : SM id in which you want to assign leads <br />
                    assignby_id : fixed master id for all leads <br />
                    (working with approx 2,000 leads CSV)
                  </Typography>
                  <input
                    type="file"
                    hidden
                    accept=".csv, application/vnd.ms-excel"
                    onChange={handleUserFileUpload}
                  />
                  {file && (
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 2,
                        textTransform: "lowercase",
                        display: "flex",
                        placeItems: "center",
                      }}
                    >
                      {file.name}
                    </Typography>
                  )} 
                </Box>
              </Button>            
            </TabPanel> 
          </TabContext>

        </DialogContent>

        <DialogActions> 
          {file === 'ER_BAD_NULL_ERROR' ?
            <small style={{position: 'absolute', left: '1.8rem', bottom: '1rem'}}><CloudSyncOutlinedIcon sx={{color: '#333', position: 'relative', top: '0.5rem', width: '18px'}} />&nbsp;{file}</small> 
          : file === null ?
              null
          : file === 'successed' ?
            <small style={{position: 'absolute', left: '1.8rem', bottom: '1rem'}}><CloudDoneOutlinedIcon sx={{color: '#333', position: 'relative', top: '0.5rem', width: '18px'}} />&nbsp;file imported successfully</small>
          : null
          } 
        </DialogActions>
      </Dialog>
    </>
  )
}

function TotalLeads() {

  const columns = React.useMemo(
    () => Cookies.get('role') === 'Sales Manager' ?
      [
        {
          Header: 'Lead Id',
          accessor: 'assignlead_id',
        },
        {
          Header: 'Lead Date',
          accessor: 'create_dt',
        },
        {
          Header: 'Lead Name',
          accessor: 'lname',
        },
        {
          Header: 'Mobile Number',
          accessor: 'mobile',
        },
        {
          Header: 'Email Id',
          accessor: 'p_email',
        },
        {
          Header: 'Project Name',
          accessor: 'pname',
        },
        {
          Header: 'Lead Status',
          accessor: 'status',
        },
        {
          Header: 'Follow Up Date',
          accessor: 'followup_dt',
        }, 
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Comments',
          accessor: 'comments',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'Locality',
          accessor: 'locality',
        },
        {
          Header: 'Sub Locality',
          accessor: 'sub_locality',
        },
      ]
      :
      [
        {
          Header: 'Lead Id',
          accessor: 'l_id',
        },
        {
          Header: 'Assigned Lead Id',
          accessor: 'assignlead_id',
        },
        {
          Header: 'Lead Date',
          accessor: 'create_dt',
        },
        {
          Header: 'Lead Name',
          accessor: 'lname',
        },
        {
          Header: 'Mobile Number',
          accessor: 'mobile',
        },
        {
          Header: 'Email Id',
          accessor: 'p_email',
        },
        {
          Header: 'Project Name',
          accessor: 'pname',
        },
        {
          Header: 'Lead Status',
          accessor: 'status',
        },
        {
          Header: 'Follow Up Date',
          accessor: 'followup_dt',
        }, 
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Comments',
          accessor: 'comments',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'Locality',
          accessor: 'locality',
        },
        {
          Header: 'Sub Locality',
          accessor: 'sub_locality',
        },
        {
          Header: 'Assigned User',
          accessor: 'assign_users',
        }, 
      ],
    []
  )

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const fetchIdRef = React.useRef(0);
  const queryClient = useQueryClient();
  const [totalleadcount, settotalleadcount] = React.useState(queryClient.getQueryData('leadscount'));
  const [searchCount, setSearchCount] = React.useState(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex, searchData, createdtfrom, createdtto, followupdtfrom, followupdtto })  => {

    if(searchData){
      console.log('search')
      const startRow = pageSize * pageIndex
      getSearchResult(pageSize, Math.ceil(startRow),searchData,createdtfrom, createdtto, followupdtfrom, followupdtto)
        .then((response) => {
          console.log(response)
          setData(response.data !== 'No Data Found' ? response.data : [])
        })
        .catch((err) => {
          console.log(err);
        });  
      getSearchResultCount(searchData,createdtfrom, createdtto, followupdtfrom, followupdtto) 
        .then((response) => {
          setSearchCount(response.data[0].total_count)
        //setData(response.data !== 'No Data Found' ? response.data : [])
      })
      .catch((err) => {
        console.log(err);
      }); 
    }else{
      const fetchId = ++fetchIdRef.current
      // setLoading(true)
      // setTimeout(() => {
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex
          const endRow = startRow + pageSize
          getLeads(pageSize, Math.ceil(startRow))
          .then((response) => {
            console.log(response)
            setData(response.data !== 'No Data Found' ? response.data : [])
          })
          .catch((err) => {
            console.log(err);
          });      
          setLoading(false)
        }
      // }, 1000)
    }
  }, [])
 
  const memoizedData = React.useMemo(() => data, [data]);

  return (
    <>
      <Table
        columns={columns}
        data={memoizedData}
        fetchData={fetchData}
        loading={loading}
        pageCount={Number(totalleadcount)}
        searchCount={Number(searchCount)}
      />
    </>
  )
}

export default TotalLeads;