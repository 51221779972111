import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../../../DesktopApp';

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllProjectNameData = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/desktop/projectname/getallprojectnamedata`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const setAddProjectName = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/projectname/addprojectname`, {
            create_dt: date,
            update_dt: date,
            projectname: data.get('project_name'),
            buildername: data.get('builder_name'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setDeleteProjectName = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/projectname/deleteprojectname`, {
            prj_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

const getEditProjectName = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/projectname/editprojectname`, {
            prj_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}


const setUpdateProjectName = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/projectname/updateprojectname`, {
            update_dt: date,    
            prj_id: data.get('prj_id'),
            projectname: data.get('project_name'),
            buildername: data.get('builder_name'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setImportProjectName = async() =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/projectname/importprojectname`, {
        });

        return response;

    }catch(err){

      return err;

    }
}

export {getAllProjectNameData, setAddProjectName, setDeleteProjectName, getEditProjectName, setUpdateProjectName, setImportProjectName}