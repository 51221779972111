import React, { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Grid, Typography } from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import dayjs from "dayjs";
import 'react-phone-input-2/lib/style.css';
import "./Common.css";

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { 
  Box,
  Paper, 
  FormControlLabel,
  Divider
} from "@mui/material";

const animatedComponents = makeAnimated();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CustomInputField = (props) => {
  const [val, setVal] = React.useState(props.type === 'datetime-local' ? dayjs().format("YYYY-MM-DD HH:mm:ss") : '');
  
  useEffect(() => {
    if(props.defaultVal && props.defaultVal !== 'undefined'){
      setVal(props.defaultVal);
    }
    if(props.resetField && props.resetField === true){
      setVal('');
    }
  }, [props])
  
  return (
    <input
      type={props.type}
      name={props.name}
      placeholder={props.placeholder} 
      value={val}
      required={props.required}
      accept={props.accept} 
      className="custom-input"
      onChange={e => setVal(e.target.value)}
      autoComplete="off"
      disabled={props.disabled}
      style={{
        backgroundColor: props.disabled === true ? '#f2f2f2' : null, 
        cursor: props.disabled === true ? 'not-allowed' : null
      }}
    />
  );
};

export const CustomMultiSelectFiled = (props) => {
  const [val, setVal] = React.useState([]); 
  const onChange = (data) => {
    setVal(data)
  }
  
  useEffect(() => {
    if(props.defaultValue && props.defaultValue !== 'undefined'){
      setVal([{ value: props.defaultValue, label: props.defaultValue }])
    }
  }, [props])
  
  return (
    <>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents} 
        value={val}
        isMulti
        options={props.options} 
        onChange={onChange}
      />
      <input type="hidden" name={props.name} value={val?.map((key) => (key.value))} />
    </>
  );
};

export const CustomSelectFiled = (props) => {
  const [val, setVal] = React.useState();
  const [newVal, setNewVal] = React.useState();

  const handleChange = (value) => {
    setNewVal(value);
    return props.onChange(value)
  }
  
  useEffect(() => { 
    if(props.defaultVal && props.defaultVal !== 'undefined'){ 
      setNewVal([{ label: props.defaultVal, value: props.defaultVal }])  
    }
  }, [props])

  return (
    <Select
      isClearable
      name={props.name}
      options={props.options}
      value={
        newVal ? newVal : val
        // props.inputrole === 'Quick Edit' || props.inputrole === 'Edit Lead' || props.inputrole === 'assign user'
        // ? val
        // : newVal
      }
      required={props.required}
      // defaultValue={[{ label: 'null', value: 'null' }]} 
      isOptionDisabled={props.optionDisbaled} 
      placeholder={props.placeholder}
      isDisabled={props.disabled}
      onChange={
        handleChange
        // props.inputrole === 'Quick Edit' || props.inputrole === 'Edit Lead' || props.inputrole === 'assign user'
        // ? props.onChange
        // : handleChange
      }
    />
  );
};

export const CustomTextareaField = (props) => { 
  const [val, setVal] = React.useState('');
  useEffect(() => {
    if(props.defaultVal && props.defaultVal !== 'undefined'){ 
      setVal(props.defaultVal) 
    }
  }, [props])
  return (
    <textarea
      value={val}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
      className="custom-textarea"
      rows={4}
      onChange={e => setVal(e.target.value)}
    ></textarea>
  );
};

export const CustomFormGroup = (props) => {
  return (
    <>
      <Grid item xs={6} md={2} className="custom-from-group" sx={{marginTop:props.mtprop}}>
        <Typography variant="h6" className="custom-form-label">
          {props.formlabel} <span className="required-label">{props.star}</span>
        </Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        {props.FormField}
      </Grid>
    </>
  );
};

export const CustomMobileFiled = (props) => {
  const [code, setCode] = React.useState(0);
  const [number, setNumber] = React.useState(0);  

  useEffect(() => {
    if(props.defaultVal && props.defaultVal !== 'undefined'){
      let data = props.defaultVal.split(' ');
      setCode(data[0]);
      setNumber(data[1]);
    }
  }, [props]);
  
  return (
    <>
      <Grid item xs={6} md={2} className="custom-from-group">
        <Typography variant="h6" className="custom-form-label">
          {props.formlabel} <span className="required-label">{props.star}</span>
        </Typography>
      </Grid>
      <Grid item xs={6} md={4}>
      <PhoneInput 
        autoFormat
        enableLongNumbers={true}
        enableSearch
        country="in"
        placeholder="0000"
        preferredCountries={['in','ae','gb','us']}
        inputProps={{ 
          required: props.InputRequired,
          autoFocus: true
        }}
        // containerClass=""
        // inputClass=""
        // buttonClass=""
        // dropdownClass=""
        // searchClass=""
        containerStyle={{width: '100%'}}
        inputStyle={{width: '100%', background: props.disabled === true ? '#f2f2f2' : null}}
        // buttonStyle={{width: '100%'}}
        // dropdownStyle={{width: '100%'}}
        // searchStyle={{width: '100%'}}
        value={number ? code+number : '+91'}
        onChange={(value, data, event, formattedValue) => 
          setCode(data.dialCode)+setNumber(value.slice(data.dialCode.length))
        }
        disabled={props.disabled}
      />

      <input type="hidden" name={props.Inputname[0]} value={code} />
      <input type="hidden" name={props.Inputname[1]} value={number} />

        {/* <Select
          className="ccode-select"
          isClearable
          options={props.options}
          value={props.Value}
          name={props.ccode_name}
          required={props.required}
          onChange={props.onChange}
          defaultValue={props.defaultvalue}
        /> */}
      </Grid>
      {/* <Grid item xs={6} md={3}>
        <input
          maxLength={15}
          type={props.type}
          name={props.mob_name}
          placeholder={props.placeholder}
          required={props.mrequired}
          className="custom-input"
        />
      </Grid> */}

    </>
  );
};

export const CustomFormLabel = (props) => {
  return (
    <>
      <Typography variant="h6" className="custom-form-label">
        {props.formlabel} <span className="required-label">{props.star}</span>
      </Typography>
    </>
  );
};

export const InputMultiAutocomplete = (props) => {
  const [value, setValue] = React.useState([]);
  
  useEffect(() => {
    if(props.resetField && props.resetField === true){
      setValue([]); 
    }    
  }, [props]);
  
  return (
    <React.Fragment>
      <Autocomplete
        multiple
        limitTags={2}
        size="small"
        id="checkboxes-tags-demo" 
        options={props.options}
        value={value}
        disableCloseOnSelect
        disabled={props.disabled}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{fontSize: '14px'}}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )} 
        renderInput={(params) => (
          <TextField {...params} 
            label={props.inputLable}  
            className="InputMultiAutocomplete" 
            placeholder={props.inputLable} 
          />
        )}   
        onChange={(event, newValue) => { 
          setValue(newValue)
        }}
      />
      <input type='hidden' name={props.inputname} value={value?.map((key) => 
        props.inputRole ? (key.value) : (key.title)
      )} />
    </React.Fragment>
  );
};

// advance search 
export const AdvanceSearchInput = (props) => {
  const [columns, setColumns] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setColumns([...props.options]);
      else setColumns([]);
      return !prev;
    });
  };
  
  useEffect(() => {
    if(props.resetField && props.resetField === true){
      setColumns([]); 
    }    
  }, [props]);

  return (
    <React.Fragment>
      <Autocomplete
        id={"checkboxes-tags-demo"}
        size="small"
        limitTags={2}
        multiple
        loading={true}
        options={props.options}
        fullWidth
        disableCloseOnSelect
        filterSelectedOptions
        freeSolo={false}
        value={columns}
        disabled={props.disabled}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.title === value.title}  
        renderInput={(params) => (
          <TextField className="InputMultiAutocomplete" {...params} label={props.inputLable} />
        )} 
        onChange={(_e, value, reason) => {
          if (reason === "clear" || reason === "removeOption")
            setSelectAll(false);
          if (reason === "selectOption" && value.length === props.options.length)
            setSelectAll(true);
          setColumns(value);
        }}
        PaperComponent={(paperProps) => {
          const { children, ...restPaperProps } = paperProps;
          return (
            <Paper {...restPaperProps}>
              <Box
                onMouseDown={(e) => e.preventDefault()} // prevent blur 
              >
                <FormControlLabel
                  onClick={(e) => {
                    e.preventDefault(); // prevent blur
                    handleToggleSelectAll();
                  }}
                  label="Select all" 
                  control={
                    <Checkbox id="select-all-checkbox" checked={selectAll} />
                  }
                  style={{paddingLeft: '1rem'}}
                />
              </Box>
              <Divider />
              {children}
            </Paper>
          );
        }}
      />
      <input type='hidden' name={props.inputname} value={columns?.map((key) => 
        props.inputRole ? (key.value) : (key.title)
      )} />
    </React.Fragment>
  );
};