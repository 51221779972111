import React from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./PageHeader.css";
import Fade from '@mui/material/Fade';

export const PageHeader = (props) => {
  return (
    <>
      <Fade in timeout={800}>
        <div className="top-header">
          <h3>{props.pagename}</h3>
        </div>
      </Fade>
      <Divider variant="inset" sx={{ ml: 0 }} />
    </>
  );
};

export const InnerPageHeader = (props) => {
  return (
    <>
      <Fade in timeout={800}>
        <div className="inner-top-header">
          <Button
            size="small"
            className="close-btn"
            component={Link}
            to={props.backto}
          >
            <KeyboardBackspaceIcon />
          </Button>
          <h3>{props.pagename}</h3>
        </div>
      </Fade>
      <Divider variant="inset" sx={{ ml: 0 }} />
    </>
  );
};