import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import Fade from '@mui/material/Fade';
import "./FollowupBar.css";

const FollowupBar = (props) => {
  return (
    <Fade in timeout={800}>
      <ListItem
        button
        className="followup-bar"
        component={Link}
        to={props.redirect} 
      >
        <div className="followup-bar-icon" style={{color: props.followbar_color}}>{props.followbar_icon}</div>
        <div className="followup-bar-name">
          <h3 style={{color: props.followbar_color}}>{props.followbar_title}</h3>
        </div>
        <div className="right-side">
          <h3 className="followup-bar-count" style={{color: props.followbar_color}}>{props.followbar_count}</h3>
          <div className="followup-bar-righticon" style={{color: props.followbar_color}}>{props.followbar_righticon}</div>
        </div>
      </ListItem>
    </Fade>
  );
};

export default FollowupBar;