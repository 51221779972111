
import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';
import dayjs from 'dayjs';

const getUsers = async() => {
  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
       const key = obj[property];
       if (!acc[key]) {
          acc[key] = [];
       }
       acc[key].push(obj);
       return acc;
    }, {});
}  
    try{
      const response = await Axios.post(`${API_URL}/users/getUserList`);
      return groupBy(response.data.map((users)=>({label: users.username, value: users.u_id, urole:users.urole})), 'urole');
    }catch(err){
        return err;
    }
  };

  const getRoleWiseUsers = async(role,limit,page) => {
      try{
        const response = await Axios.post(`${API_URL}/users/getRoleWiseUsers`,{role:role, limit:limit, page:page});
        return response
      }catch(err){
        return err;
      }
  };

  const getUserData = async(uid) => {
    try{
      const response = await Axios.post(`${API_URL}/users/getUserData`,{uid:uid});
      return response
    }catch(err){
      return err;
    }
  };

  const setUserData = async(uid, data) => {
    const date = dayjs().format('YYYY-MM-DD HH:mm:ss');
    try{
      const response = await Axios.post(`${API_URL}/users/setUserData`,{date:date, uid:uid, username: data.get('username'), password: data.get('password')});
      return response
    }catch(err){
      return err;
    }
  };

export{getUsers, getRoleWiseUsers, getUserData, setUserData}