import React, { useState }  from 'react';
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import LeadCard from '../../Components/LeadCard/LeadCard';
import { InnerPageHeader } from "../../Components/PageHeader/PageHeader";
import "./SomedayLead.css";
import { getLeads } from "./ApiCalls";

const SomedayLead = () => {

  const[somedayLeads, setSomedayLeads] = useState([]);

  React.useEffect( () => {
    getLeads().then(response => {
      console.log(response)
      if(response.data !== 'No Data Found'){
        setSomedayLeads(response.data)
        console.log(response)
      } 
    }).catch(err => {
      console.log(err);
    });
 }, []);

  return (
    <>
      <InnerPageHeader pagename="Someday Leads" backto="/followup" />
      <Divider variant="span" sx={{ mt: 2 }} />
      <List sx={{ width: "100%", p: 0, mb: 0, bgcolor: "background.paper" }}>
      {
            somedayLeads !== '' ? somedayLeads?.map((lead)=>(
              <LeadCard  key={lead.l_id}
              lead_id = {lead.l_id}
              lead_name = {lead.lname}
              lead_pname = {lead.pname}
              lead_source = {lead.source}
              lead_create_dt = {lead.create_dt}
              />
            )) : <h4>No upcoming lead found</h4>
          }
      </List>
    </>
  );
};

export default SomedayLead;