export const CurrencyLists = [
	{"id":1,"name":"Afghan Afghani","symbol":"؋","code":"AFA"},
	{"id":2,"name":"Albanian Lek","symbol":"Lek","code":"ALL"},
	{"id":3,"name":"Algerian Dinar","symbol":"دج","code":"DZD"},
	{"id":4,"name":"Angolan Kwanza","symbol":"Kz","code":"AOA"},
	{"id":5,"name":"Argentine Peso","symbol":"$","code":"ARS"},
	{"id":6,"name":"Armenian Dram","symbol":"֏","code":"AMD"},
	{"id":7,"name":"Aruban Florin","symbol":"ƒ","code":"AWG"},
	{"id":8,"name":"Australian Dollar","symbol":"$","code":"AUD"},
	{"id":9,"name":"Azerbaijani Manat","symbol":"m","code":"AZN"},
	{"id":10,"name":"Bahamian Dollar","symbol":"B$","code":"BSD"},
	{"id":11,"name":"Bahraini Dinar","symbol":".د.ب","code":"BHD"},
	{"id":12,"name":"Bangladeshi Taka","symbol":"৳","code":"BDT","locale":"en-BD"},
	{"id":13,"name":"Barbadian Dollar","symbol":"Bds$","code":"BBD"},
	{"id":14,"name":"Belarusian Ruble","symbol":"Br","code":"BYR"},
	{"id":15,"name":"Belgian Franc","symbol":"fr","code":"BEF"},
	{"id":16,"name":"Belize Dollar","symbol":"$","code":"BZD"},
	{"id":17,"name":"Bermudan Dollar","symbol":"$","code":"BMD"},
	{"id":18,"name":"Bhutanese Ngultrum","symbol":"Nu.","code":"BTN"},
	{"id":19,"name":"Bitcoin","symbol":"฿","code":"BTC"},
	{"id":20,"name":"Bolivian Boliviano","symbol":"Bs.","code":"BOB"},
	{"id":21,"name":"Bosnia-Herzegovina Convertible Mark","symbol":"KM","code":"BAM"},
	{"id":22,"name":"Botswanan Pula","symbol":"P","code":"BWP"},
	{"id":23,"name":"Brazilian Real","symbol":"R$","code":"BRL","locale":"pt-BR"},
	{"id":24,"name":"British Pound Sterling","symbol":"£","code":"GBP","locale":"en-GB"},
	{"id":25,"name":"Brunei Dollar","symbol":"B$","code":"BND"},
	{"id":26,"name":"Bulgarian Lev","symbol":"Лв.","code":"BGN"},
	{"id":27,"name":"Burundian Franc","symbol":"FBu","code":"BIF"},
	{"id":28,"name":"Cambodian Riel","symbol":"KHR","code":"KHR"},
	{"id":29,"name":"Canadian Dollar","symbol":"$","code":"CAD"},
	{"id":30,"name":"Cape Verdean Escudo","symbol":"$","code":"CVE"},
	{"id":31,"name":"Cayman Islands Dollar","symbol":"$","code":"KYD"},
	{"id":32,"name":"CFA Franc BCEAO","symbol":"CFA","code":"XOF"},
	{"id":33,"name":"CFA Franc BEAC","symbol":"FCFA","code":"XAF"},
	{"id":34,"name":"CFP Franc","symbol":"₣","code":"XPF"},
	{"id":35,"name":"Chilean Peso","symbol":"$","code":"CLP"},
	{"id":36,"name":"Chilean Unit of Account","symbol":"CLF","code":"CLF"},
	{"id":37,"name":"Chinese Yuan","symbol":"¥","code":"CNY"},
	{"id":38,"name":"Colombian Peso","symbol":"$","code":"COP"},
	{"id":39,"name":"Comorian Franc","symbol":"CF","code":"KMF"},
	{"id":40,"name":"Congolese Franc","symbol":"FC","code":"CDF"},
	{"id":41,"name":"Costa Rican Colón","symbol":"₡","code":"CRC"},
	{"id":42,"name":"Croatian Kuna","symbol":"kn","code":"HRK"},
	{"id":43,"name":"Cuban Convertible Peso","symbol":"$, CUC","code":"CUC"},
	{"id":44,"name":"Czech Republic Koruna","symbol":"Kč","code":"CZK"},
	{"id":45,"name":"Danish Krone","symbol":"Kr.","code":"DKK"},
	{"id":46,"name":"Djiboutian Franc","symbol":"Fdj","code":"DJF"},
	{"id":47,"name":"Dominican Peso","symbol":"$","code":"DOP"},
	{"id":48,"name":"East Caribbean Dollar","symbol":"$","code":"XCD"},
	{"id":49,"name":"Egyptian Pound","symbol":"ج.م","code":"EGP"},
	{"id":50,"name":"Eritrean Nakfa","symbol":"Nfk","code":"ERN"},
	{"id":51,"name":"Estonian Kroon","symbol":"kr","code":"EEK"},
	{"id":52,"name":"Ethiopian Birr","symbol":"Nkf","code":"ETB"},
	{"id":53,"name":"Euro","symbol":"€","code":"EUR"},
	{"id":54,"name":"Falkland Islands Pound","symbol":"£","code":"FKP"},
	{"id":55,"name":"Fijian Dollar","symbol":"FJ$","code":"FJD"},
	{"id":56,"name":"Gambian Dalasi","symbol":"D","code":"GMD"},
	{"id":57,"name":"Georgian Lari","symbol":"ლ","code":"GEL"},
	{"id":58,"name":"German Mark","symbol":"DM","code":"DEM"},
	{"id":59,"name":"Ghanaian Cedi","symbol":"GH₵","code":"GHS","locale":"en-GH"},
	{"id":60,"name":"Gibraltar Pound","symbol":"£","code":"GIP"},
	{"id":61,"name":"Greek Drachma","symbol":"₯, Δρχ, Δρ","code":"GRD"},
	{"id":62,"name":"Guatemalan Quetzal","symbol":"Q","code":"GTQ"},
	{"id":63,"name":"Guinean Franc","symbol":"FG","code":"GNF"},
	{"id":64,"name":"Guyanaese Dollar","symbol":"$","code":"GYD"},
	{"id":65,"name":"Haitian Gourde","symbol":"G","code":"HTG"},
	{"id":66,"name":"Honduran Lempira","symbol":"L","code":"HNL"},
	{"id":67,"name":"Hong Kong Dollar","symbol":"$","code":"HKD"},
	{"id":68,"name":"Hungarian Forint","symbol":"Ft","code":"HUF"},
	{"id":69,"name":"Icelandic Króna","symbol":"kr","code":"ISK"},
	{"id":70,"name":"Indian Rupee","symbol":"₹","code":"INR","locale":"en-IN"},
	{"id":71,"name":"Indonesian Rupiah","symbol":"Rp","code":"IDR"},
	{"id":72,"name":"Iranian Rial","symbol":"﷼","code":"IRR","locale":"fa-IR"},
	{"id":73,"name":"Iraqi Dinar","symbol":"د.ع","code":"IQD"},
	{"id":74,"name":"Israeli New Sheqel","symbol":"₪","code":"ILS"},
	{"id":75,"name":"Italian Lira","symbol":"L,£","code":"ITL"},
	{"id":76,"name":"Jamaican Dollar","symbol":"J$","code":"JMD"},
	{"id":77,"name":"Japanese Yen","symbol":"¥","code":"JPY"},
	{"id":78,"name":"Jordanian Dinar","symbol":"ا.د","code":"JOD"},
	{"id":79,"name":"Kazakhstani Tenge","symbol":"лв","code":"KZT"},
	{"id":80,"name":"Kenyan Shilling","symbol":"KSh","code":"KES"},
	{"id":81,"name":"Kuwaiti Dinar","symbol":"ك.د","code":"KWD"},
	{"id":82,"name":"Kyrgystani Som","symbol":"лв","code":"KGS"},
	{"id":83,"name":"Laotian Kip","symbol":"₭","code":"LAK"},
	{"id":84,"name":"Latvian Lats","symbol":"Ls","code":"LVL"},
	{"id":85,"name":"Lebanese Pound","symbol":"£","code":"LBP"},
	{"id":86,"name":"Lesotho Loti","symbol":"L","code":"LSL"},
	{"id":87,"name":"Liberian Dollar","symbol":"$","code":"LRD"},
	{"id":88,"name":"Libyan Dinar","symbol":"د.ل","code":"LYD"},
	{"id":89,"name":"Litecoin","symbol":"Ł","code":"LTC"},
	{"id":90,"name":"Lithuanian Litas","symbol":"Lt","code":"LTL"},
	{"id":91,"name":"Macanese Pataca","symbol":"$","code":"MOP"},
	{"id":92,"name":"Macedonian Denar","symbol":"ден","code":"MKD"},
	{"id":93,"name":"Malagasy Ariary","symbol":"Ar","code":"MGA"},
	{"id":94,"name":"Malawian Kwacha","symbol":"MK","code":"MWK"},
	{"id":95,"name":"Malaysian Ringgit","symbol":"RM","code":"MYR"},
	{"id":96,"name":"Maldivian Rufiyaa","symbol":"Rf","code":"MVR"},
	{"id":97,"name":"Mauritanian Ouguiya","symbol":"MRU","code":"MRO"},
	{"id":98,"name":"Mauritian Rupee","symbol":"₨","code":"MUR","locale":"en-MU"},
	{"id":99,"name":"Mexican Peso","symbol":"$","code":"MXN"},
	{"id":100,"name":"Moldovan Leu","symbol":"L","code":"MDL"},
	{"id":101,"name":"Mongolian Tugrik","symbol":"₮","code":"MNT"},
	{"id":102,"name":"Moroccan Dirham","symbol":"MAD","code":"MAD"},
	{"id":103,"name":"Mozambican Metical","symbol":"MT","code":"MZM"},
	{"id":104,"name":"Myanmar Kyat","symbol":"K","code":"MMK","locale":"en-MM"},
	{"id":105,"name":"Namibian Dollar","symbol":"$","code":"NAD"},
	{"id":106,"name":"Nepalese Rupee","symbol":"₨","code":"NPR","locale":"en-NP"},
	{"id":107,"name":"Netherlands Antillean Guilder","symbol":"ƒ","code":"ANG"},
	{"id":108,"name":"New Taiwan Dollar","symbol":"$","code":"TWD"},
	{"id":109,"name":"New Zealand Dollar","symbol":"$","code":"NZD"},
	{"id":110,"name":"Nicaraguan Córdoba","symbol":"C$","code":"NIO"},
	{"id":111,"name":"Nigerian Naira","symbol":"₦","code":"NGN","locale":"en-NG"},
	{"id":112,"name":"North Korean Won","symbol":"₩","code":"KPW"},
	{"id":113,"name":"Norwegian Krone","symbol":"kr","code":"NOK"},
	{"id":114,"name":"Omani Rial","symbol":".ع.ر","code":"OMR"},
	{"id":115,"name":"Pakistani Rupee","symbol":"₨","code":"PKR"},
	{"id":116,"name":"Panamanian Balboa","symbol":"B/.","code":"PAB"},
	{"id":117,"name":"Papua New Guinean Kina","symbol":"K","code":"PGK"},
	{"id":118,"name":"Paraguayan Guarani","symbol":"₲","code":"PYG"},
	{"id":119,"name":"Peruvian Nuevo Sol","symbol":"S/.","code":"PEN"},
	{"id":120,"name":"Philippine Peso","symbol":"₱","code":"PHP"},
	{"id":121,"name":"Polish Zloty","symbol":"zł","code":"PLN"},
	{"id":122,"name":"Qatari Rial","symbol":"ق.ر","code":"QAR"},
	{"id":123,"name":"Romanian Leu","symbol":"lei","code":"RON"},
	{"id":124,"name":"Russian Ruble","symbol":"₽","code":"RUB"},
	{"id":125,"name":"Rwandan Franc","symbol":"FRw","code":"RWF"},
	{"id":126,"name":"Salvadoran Colón","symbol":"₡","code":"SVC"},
	{"id":127,"name":"Samoan Tala","symbol":"SAT","code":"WST"},
	{"id":128,"name":"São Tomé and Príncipe Dobra","symbol":"Db","code":"STD"},
	{"id":129,"name":"Saudi Riyal","symbol":"﷼","code":"SAR"},
	{"id":130,"name":"Serbian Dinar","symbol":"din","code":"RSD"},
	{"id":131,"name":"Seychellois Rupee","symbol":"SRe","code":"SCR"},
	{"id":132,"name":"Sierra Leonean Leone","symbol":"Le","code":"SLL"},
	{"id":133,"name":"Singapore Dollar","symbol":"$","code":"SGD"},
	{"id":134,"name":"Slovak Koruna","symbol":"Sk","code":"SKK"},
	{"id":135,"name":"Solomon Islands Dollar","symbol":"Si$","code":"SBD"},
	{"id":136,"name":"Somali Shilling","symbol":"Sh.so.","code":"SOS"},
	{"id":137,"name":"South African Rand","symbol":"R","code":"ZAR"},
	{"id":138,"name":"South Korean Won","symbol":"₩","code":"KRW"},
	{"id":139,"name":"South Sudanese Pound","symbol":"£","code":"SSP"},
	{"id":140,"name":"Special Drawing Rights","symbol":"SDR","code":"XDR"},
	{"id":141,"name":"Sri Lankan Rupee","symbol":"Rs","code":"LKR"},
	{"id":142,"name":"St. Helena Pound","symbol":"£","code":"SHP"},
	{"id":143,"name":"Sudanese Pound","symbol":".س.ج","code":"SDG"},
	{"id":144,"name":"Surinamese Dollar","symbol":"$","code":"SRD","locale":"nl-SR"},
	{"id":145,"name":"Swazi Lilangeni","symbol":"E","code":"SZL"},
	{"id":146,"name":"Swedish Krona","symbol":"kr","code":"SEK"},
	{"id":147,"name":"Swiss Franc","symbol":"CHf","code":"CHF","locale":"fr-FR"},
	{"id":148,"name":"Syrian Pound","symbol":"LS","code":"SYP"},
	{"id":149,"name":"Tajikistani Somoni","symbol":"SM","code":"TJS"},
	{"id":150,"name":"Tanzanian Shilling","symbol":"TSh","code":"TZS"},
	{"id":151,"name":"Thai Baht","symbol":"฿","code":"THB"},
	{"id":152,"name":"Tongan Pa'anga","symbol":"$","code":"TOP"},
	{"id":153,"name":"Trinidad & Tobago Dollar","symbol":"$","code":"TTD"},
	{"id":154,"name":"Tunisian Dinar","symbol":"ت.د","code":"TND"},
	{"id":155,"name":"Turkish Lira","symbol":"₺","code":"TRY","locale":"tr-TR"},
	{"id":156,"name":"Turkmenistani Manat","symbol":"T","code":"TMT"},
	{"id":157,"name":"Ugandan Shilling","symbol":"USh","code":"UGX"},
	{"id":158,"name":"Ukrainian Hryvnia","symbol":"₴","code":"UAH"},
	{"id":159,"name":"United Arab Emirates Dirham","symbol":"إ.د","code":"AED","locale":"en-AE"},
	{"id":160,"name":"Uruguayan Peso","symbol":"$","code":"UYU"},
	{"id":161,"name":"US Dollar","symbol":"$","code":"USD","locale":"en-US"},
	{"id":162,"name":"Uzbekistan Som","symbol":"лв","code":"UZS"},
	{"id":163,"name":"Vanuatu Vatu","symbol":"VT","code":"VUV"},
	{"id":164,"name":"Venezuelan BolÃvar","symbol":"Bs","code":"VEF"},
	{"id":165,"name":"Vietnamese Dong","symbol":"₫","code":"VND"},
	{"id":166,"name":"Yemeni Rial","symbol":"﷼","code":"YER"},
	{"id":167,"name":"Zambian Kwacha","symbol":"ZK","code":"ZMK"},
	{"id":168,"name":"Zimbabwean dollar","symbol":"$","code":"ZWL"}
];

export const CurrencyListLocales = [
	{"id":0,"name":"Indian Rupee","symbol":"₹","code":"INR","locale":"en-IN"},
	{"id":1,"name":"United Arab Emirates Dirham","symbol":"إ.د","code":"AED","locale":"en-AE"},
	{"id":2,"name":"Nepalese Rupee","symbol":"₨","code":"NPR","locale":"en-NP"}, 
	{"id":3,"name":"US Dollar","symbol":"$","code":"USD","locale":"en-US"},
	{"id":4,"name":"Bangladeshi Taka","symbol":"৳","code":"BDT","locale":"en-BD"},
	{"id":5,"name":"Brazilian Real","symbol":"R$","code":"BRL","locale":"pt-BR"},
	{"id":6,"name":"British Pound Sterling","symbol":"£","code":"GBP","locale":"en-GB"},
	{"id":7,"name":"Ghanaian Cedi","symbol":"GH₵","code":"GHS","locale":"en-GH"},
	{"id":8,"name":"Mauritian Rupee","symbol":"₨","code":"MUR","locale":"en-MU"},
	{"id":9,"name":"Myanmar Kyat","symbol":"K","code":"MMK","locale":"en-MM"},
	{"id":10,"name":"Nigerian Naira","symbol":"₦","code":"NGN","locale":"en-NG"},
	{"id":11,"name":"Surinamese Dollar","symbol":"$","code":"SRD","locale":"nl-SR"}, 
	{"id":12,"name":"Swiss Franc","symbol":"CHf","code":"CHF","locale":"fr-FR"},
	{"id":13,"name":"Turkish Lira","symbol":"₺","code":"TRY","locale":"tr-TR"},
	// {"id":,"name":"Iranian Rial","symbol":"﷼","code":"IRR","locale":"fa-IR"},
];