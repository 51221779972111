import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonIcon from '@mui/icons-material/Person';
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogTitle from "@mui/material/DialogTitle";

const MultiSelectMenu = (props) => {

    const [state, setState] = React.useState({bottom: false});
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        } 
        setState({ ...state, [anchor]: open });
    };
    const send = (assign_user_id) => {
        props.sendDataToParent(assign_user_id);
    };
    const list = (anchor) => (
        <Box>
            <CloseOutlinedIcon 
                onClick={toggleDrawer(anchor, false)}
                className={props.mutlicloseclass} 
            />
            <List sx={{p: 0}} >
                <ListItem 
                    sx={{ 
                        background: "#e5e7eb", 
                        p: 0, 
                        width: '100%'
                    }}
                >
                    <ListItemButton>
                        <ListItemText
                            sx={{ color: "#333", fontWeight: "500", textTransform: 'capitalize' }}
                            primary={"Assigned to"}
                        />
                    </ListItemButton>  
                </ListItem>

                {props.mlist?.map((text, index) => (
                    <ListItem
                        key={index}
                        sx={{ borderBottom: "1px solid #e5e7eb", padding: "0" }} 
                        onClick={() => send(text.value)}
                    > 
                        <ListItemButton sx={{ padding: "15px 20px" }} >
                            <ListItemIcon sx={{ minWidth: "35px", color: "#10aabe" }}>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={text.key}
                                sx={{ color: "#333", fontWeight: "400!important" }}
                            />
                        </ListItemButton> 
                    </ListItem>
                ))}  
            </List>
            {/* <Divider /> */} 
        </Box>
    );

    return (
        <>
            {['bottom']?.map((anchor) => (
                <React.Fragment key={anchor}>
                    <div onClick={toggleDrawer(anchor, true)}>{props.mlabel ? props.mlabel : props.micon}</div>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </>
    )
}

export default MultiSelectMenu;