import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { FollowUp } from '../../Data/Data';
import {
  CustomSelectFiled,
  CustomInputField,
  CustomFormGroup,
  CustomTextareaField,
} from '../../Components/Common';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { getAllLeadStatusList, setLead, getLead, setQuickEditLead } from '../EditLead/ApiCalls';
import dayjs from "dayjs"; 
import './QuickEdit.css'

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from '@mui/icons-material/Send';

import { useQueryClient } from 'react-query';

const QuickEdit = () => {

  const navigate = useNavigate();
  const lead_id = useParams(); 
  const lastlocation = useParams(); 
  const [leadsDetails, setLeadsDetails] = useState('');
 
  const [follow_up, setFollowUp] = useState(); 
  const [leadStatus, setLeadStaus] = useState();
  const [leadstatuslist, setAllLeadStatusList] = React.useState([]); 
  const [comments, setComments] = React.useState([]);

  const queryClient = useQueryClient();

  useEffect(() => {
   
    getAllLeadStatusList().then(response => {
      if (response.data === "No Data Found") {
        setAllLeadStatusList(response.data.data)
      } else {
        setAllLeadStatusList(response.data.map((leadstatuslist) => ({ value: leadstatuslist.status, label: leadstatuslist.status })))
      }
    }).catch(err => {
      console.log(err);
    });
   
    getLead(lead_id)
    .then((response) => { 
      setLeadsDetails(response.data[0]);
      setLeadStaus(response.data[0].status);
      setFollowUp(response.data[0].followup);
      if (response.data[0].comments){
      setComments(response.data[0].comments.split('~'))
      }
    })
    .catch((err) => {
      console.log(err);
    }); 
    
  }, [lead_id]);

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
});
    
  const handleChangeStatus = (event) => {
    if(event !== null){
      setLeadStaus(event.value);
    }
  };
 
  const handleEditLead = async (event) => {
    event.preventDefault(); 
    const data = new FormData(event.currentTarget); 
    // comments.push(data.get('comments'))
    // let implode = "";
    //   if (comments !== null) {
    //     implode = comments?.map((item) => item).join("~");
    //   } else {
    //     implode = comments;
    //   }

      // const usercomments = dayjs().format("MMM DD,YYYY h:mm A") + "= " + data.get("comments");
      const usercomments = "By "+Cookies.get('name')+", "+dayjs().format("MMM DD,YYYY h:mm A") + "= " + data.get("comments");

      setComments(arr => [...arr, usercomments]);
      comments.push(usercomments);
      let implode = "";
      if (comments !== null) {
        implode = comments?.map((item) => item).join("~");
      } else {
        implode = comments;
      }
    if(data.get("lead_status") === ''){
      alert('Please fill out required fields.')
    }else{
      setQuickEditLead(data, leadsDetails.l_id, implode, leadsDetails.lname, leadsDetails.p_ccode+leadsDetails.p_mob, leadsDetails.pname, data.get("comments") ? usercomments : '')
      .then((response) => { 
        notify("Lead is Updated.")
        setTimeout(() => {
          lastlocation.lastlocation === 'upcomingleads' ?
            navigate('/upcomingleads') 
          :
          lastlocation.lastlocation === 'missedleads' ?
            navigate('/missedleads') 
          :
          lastlocation.lastlocation === 'todaysleads' ?
            navigate('/todaysleads') 
          :
          lastlocation.lastlocation === 'assignleads' ?
            navigate('/assignleads') 
          :
          lastlocation.lastlocation === 'nonassignleads' ?
            navigate('/nonassignleads') 
          :
          lastlocation.lastlocation ?
            navigate('/leads-by-status/'+lastlocation.lastlocation+'/')
          :
            navigate("/total-leads/mypage="+lead_id.pageId.split('=')[1]);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    }
    await queryClient.invalidateQueries('presentcount');
    await queryClient.fetchQuery('presentcount');
    await queryClient.invalidateQueries('missedcount');
    await queryClient.fetchQuery('missedcount');
    await queryClient.invalidateQueries('upcomingcount');
    await queryClient.fetchQuery('upcomingcount');
    await queryClient.invalidateQueries('bellleads');
    await queryClient.fetchQuery('bellleads');
  };

  return (
    <>
      <Breadcrumb PageName="Quick Edit" BackButton={[true, 'Back', 
        lastlocation.lastlocation === 'upcomingleads' ? '/upcomingleads' :
        lastlocation.lastlocation === 'missedleads' ? '/missedleads' :
        lastlocation.lastlocation === 'todaysleads' ? '/todaysleads' :
        lastlocation.lastlocation === 'assignleads' ? '/assignleads' :
        lastlocation.lastlocation === 'nonassignleads' ? '/nonassignleads' :
        lastlocation.lastlocation ? '/leads-by-status/'+lastlocation.lastlocation+'/' 
        : '/total-leads/mypage='+lead_id.pageId.split('=')[1], 
      <ArrowBackIosOutlinedIcon />]} />

      <Box component="form" autoComplete="off" onSubmit={handleEditLead} method="post">
      <Box className="custom-card" mt={0}>

          <Typography variant="h4" className="custom-card-head">
            Client Details :-
          </Typography>
          <List style={{display: 'flex', paddingLeft: '1rem', paddingRight: '1rem'}}>
            <ListItemButton>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary="Name" secondary={leadsDetails.lname} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary="Number" secondary={leadsDetails.p_ccode+leadsDetails.p_mob} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary="Project" secondary={leadsDetails.pname} />
            </ListItemButton>
          </List>

          <Typography variant="h4" className="custom-card-head">
            Client Status :-
          </Typography>
          
          <Box p={2}>
            <Grid container columnSpacing={2} mb={2}>
              <CustomFormGroup
                formlabel="Lead Status"
                star="*"
                FormField={
                  <CustomSelectFiled 
                    name="lead_status" 
                    required={true} 
                    options={leadstatuslist}  
                    inputrole="Quick Edit"
                    defaultVal={leadStatus}
                    onChange={handleChangeStatus} 
                  />
                }
              /> 
            </Grid>

            <Grid container columnSpacing={2} mb={2}>
              <CustomFormGroup
                formlabel="Follow Up"
                star="*"
                FormField={
                  <CustomSelectFiled
                    name="followup" 
                    required={true}
                    options={FollowUp}
                    inputrole="Quick Edit"
                    defaultVal={follow_up}
                    onChange={(event) => setFollowUp(event.value)}
                  />
                }
              />
            </Grid>

            {follow_up === 'Yes' ? (
              <Grid container columnSpacing={2} mb={2}>
                <CustomFormGroup
                  formlabel="Follow Up Date"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="datetime-local"
                      name="followup_dt"
                      required={true}
                      defaultVal={leadsDetails.followup_dt}
                    />
                  }
                />
                </Grid>
              ) : null}  

            {/* <Grid container spacing={2} mb={2}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Previous Comments <span className="required-label"></span>
                </Typography>
              </Grid>
              
            </Grid> */}
            
            <Grid container spacing={2} >
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Add Comment <span className="required-label"></span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomTextareaField
                  name="comments"
                  placeholder="Enter Comment ...."
                  // defaultVal={leadsDetails.comments}
                />
              </Grid>
              <Grid item xs={6} md={1} />
              <Grid item xs={6} md={5} sx={{position: 'relative'}}>
                <Box sx={{
                  position: 'absolute', 
                  height: follow_up === 'Yes' ? '265px' : '235px', 
                  overflowY: 'auto', 
                  top: follow_up === 'Yes' ? '-8.8rem' : '-5.3rem'
                }}> 
                  <Typography variant="h6" className="custom-form-label">
                    Previous Comments
                  </Typography>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                      margin: '0%', 
                    }}
                  >
                  {leadsDetails.comments?.split("~").map((comment, i) => (
                    <TimelineItem key={i}>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{fontSize: '14px'}}>{comment}</TimelineContent>
                    </TimelineItem>
                  ))}
                  </Timeline>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} my={.5}>
              <Grid item xs={6} md={2}></Grid>
              <Grid item xs={6} md={10}>
                <Button variant="contained" type="submit" >
                  Save Lead
                </Button>
              </Grid>
            </Grid>
            
          </Box>
        </Box>

      </Box>

      <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
    </>
  );
};

export default QuickEdit;