import * as React from 'react';
import { Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';  
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { getSearch } from './ApiCalls';
import LeadCard from '../../Components/LeadCard/LeadCard';
import Cookies from 'js-cookie';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit', '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function SearchLead(){

  const queryParams = new URLSearchParams(window.location.search);
  const[searchValue, setSearchValue] = React.useState("");
  const[searchResult, setSearchResult] = React.useState([]);
  const navigate = useNavigate();
  // const[lastElement, setLastElement] = React.useState([]);
  const limit = 100;
  // const hasNextPage = false;

  const handleScroll = React.useCallback (()=> {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    console.log('hi')
    // const last_array = searchResult.data.pop()
    // console.log(last_array.lreq_id);
    // getSearch(searchValue, limit, last_array.lreq_id).then(response => {
    //   console.log(response)
    // }).catch(err => {
    //   console.log(err);
    // });
  }, []);

  React.useEffect(() => {      
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);
    
  const handleClick = ()=>{
    console.log(searchValue)
    getSearch(searchValue, limit, '0').then(response => {
      setSearchResult(response.response)
    }).catch(err => {
      console.log(err);
    });
  }
    
  return (
    <>
      <Box sx={{ flexGrow: 1 }}> 
        <AppBar position="static" sx={{pt: '4px'}}>
          <Grid container> 
            <Grid item xs={2}>
              <Slide in direction='right' timeout={800}>
                <Button
                  variant="text"
                  component={Link} 
                  to={queryParams.has('multiselect') ? "/multiselect" : "/leads"}
                  className="backbutton"
                  sx={{padding: '10px 8px'}}
                >
                  <KeyboardBackspaceIcon sx={{ color: "#333", fontSize: "26px", py: 0.5 }} />
                </Button>
              </Slide> 
            </Grid>
            <Grid item xs={10}>
              <Fade in timeout={800}>
                <Toolbar sx={{pl: 0}}>
                  <Search sx={{mr:0}}>
                    <SearchIconWrapper sx={{px:1}} >
                      <SearchIcon sx={{ zIndex:'2', color:'#333'}} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      sx={{width: '100%', zIndex:'1'}}
                      placeholder="Search.."
                      inputProps={{ 'aria-label': 'search' }}
                      name="search"
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                    />
                    <Button sx={{ textTransform: 'capitalize', position: 'absolute', right: '0px', zIndex: 10000, top: '1px', fontSize: '12px', padding: '8px'}} onClick={()=>handleClick()}>search</Button>
                  </Search>
                </Toolbar>
              </Fade>
            </Grid> 
          </Grid>
        </AppBar>  
      </Box>
      <div onScroll={handleScroll} style={{marginTop: "4.3rem"}}>
        <Box>  
          <Fade in timeout={800}>
            <List sx={{ width: '100%', p: 0, mb: 8, bgcolor: 'background.paper' }}>
              {searchValue !== "" && searchResult.length !== 0 && searchResult.data !== 'No Data Found' ?
                searchResult.data?.map((lead) => (
                  <LeadCard key={lead.l_id}
                    lead_id = {lead.l_id}
                    lead_name = {lead.lname}
                    lead_pname = {lead.pname}
                    lead_source = {lead.source}
                    lead_color = {lead.color}
                    lead_status={lead.status}
                    lead_create_dt = {lead.create_dt}
                  />
                ))
                : <Alert severity="info">No leads found!</Alert>
              }
            </List>
          </Fade>
        </Box>
      </div>  
    </>
  );
}