import Axios from 'axios';
import { API_URL } from '../../App';

const getSearch = async(search, LIMIT, page) =>{
    try{
        const response = await Axios.post(`${API_URL}/leads/getSearch`,{search: search, limit: LIMIT, page: page});
        const responseCount = await Axios.post(`${API_URL}/leads/getSearchCount`,{search: search});
        return {response, totalPages: responseCount.data[0].searchcount }
    }catch(err){
        return err;
    }
}

export {getSearch}