import React from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Button, Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { getRoleWiseUsers } from './ApiCalls';
import AddIcon from "@mui/icons-material/Add";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import '../reacttable.css';
import { useQueryClient, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { isSameDay, subDays } from 'date-fns';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
}) {
  const today_date = dayjs().format('YYYY-MM-DD') 
  const yesterday =  dayjs(subDays(new Date(), 1)).format('YYYY-MM-DD')
  const [controlledPageIndex, setControlledPage] = React.useState(0)
  const [open, setOpen] = React.useState(false);
  const [leadId, setLeadId] = React.useState([]);
  const role = useParams(); 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: localStorage.getItem('tablePageIndex') !== 'null' ? Number(localStorage.getItem('tablePageIndex')) : controlledPageIndex, pageSize: 30}, 
      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount
    },
    useSortBy,
    usePagination
  )

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  const handleClickOpen = (lid) => {
    setOpen(true);
    setLeadId(lid);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = (valueToRemove) => {
    const index = leadId.indexOf(valueToRemove);
    if (index !== -1) {
      const newArray = leadId.slice(0, index).concat(leadId.slice(index + 1));
      setLeadId(newArray);
    }
  };

  // const deleteLead = async(lids) => {
    // unSetLead(lids)
    // .then((response) => {
    //   if(response.data === 'lead deleted'){
    //     handleClose();
    //     alert("Leads Deleted Successfully");
    //   }
    //   console.log(response)
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  // };
  
  const next = () =>{
    nextPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.ceil(pageIndex+1));
  }

  const prev = () =>{
    previousPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.ceil(pageIndex-1));
  }
  
  return (
    <>
    {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' || Cookies.get('role') === 'Team Leader' || Cookies.get('role') === 'Sales Manager' || Cookies.get('role') === 'Branch Admin'  ? 
     <Breadcrumb 
        PageName={role.role} 
        BackButton={[true, 'Back', '/all-users', <ArrowBackIosOutlinedIcon />]} 
        // AssignButton={[leadId.length !== 0 ? true : false, 'Assign Leads', `/assign-leads-to/${leadId}/mypage=${pageIndex}`, <AddIcon />]}
      />  
      :
      <Breadcrumb 
        PageName="Total Leads" 
        BackButton={[true, 'Back', '/all-users', <ArrowBackIosOutlinedIcon />]}  
      /> 
      }
      {/* {leadId.length !== 0 ? <IconButton aria-label="delete" onClick={() => deleteLead(leadId)}>
      <DeleteIcon />
      </IconButton> : ''} */}

      <div className='table'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {/* <th ><input  type="checkbox"  /></th> */}
              <th></th>
              <th >Action</th>
              <th >Sr. No</th>
              {headerGroup.headers.map((column, index) => (
                <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())} style={{position: 'relative'}}>
                  {column.render('Header')}
                  <span style={{color: '#e9e9e9', position: 'absolute', top: '1rem', right: '0.3rem'}}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                      <FilterAltIcon />
                      ) : (
                      <FilterAltOffIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={i}>
                <td></td>
                {/* <td >
                  <input type="checkbox" value={row.cells[0].value} onChange={(e)=>{e.target.checked ? setLeadId(current => [...current, row.cells[0].value]) : handleRemove(row.cells[0].value)}} />
                </td> */}
                <td >  
                  {/* <Tooltip
                    title="Quick Edit"
                    component={Link}
                    to={''} 
                    >
                    <IconButton><AutoFixHighIcon sx={{ fontSize: "20px" }} /></IconButton>
                  </Tooltip> */}
                  <Tooltip
                    title="Edit Profile"
                    component={Link}
                    to={`/edit-user/${row.cells[0].value}/${role.role}`}
                  >
                    <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                  </Tooltip>
                  {/* <Tooltip
                    title="View Profile"
                    component={Link}
                    to={''}
                  >
                    <IconButton><VisibilityIcon sx={{ fontSize: "20px" }} /></IconButton>
                  </Tooltip>
                  <Tooltip
                      title="Delete"
                      component="label"
                      onClick={() => handleClickOpen(row.cells[0].value)} 
                  >
                    <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                  </Tooltip> */}
                </td>
                <td>{i}</td>
                {row.cells.map((cell, i) => {
                  return <>
                    <td key={i} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  </>
                })}
              </tr> 
              )
            })}

          <tr style={{backgroundColor:'#ffffff'}}>
            {loading ? (
              <td>Loading...</td>
            ) : (
              <td>
                Showing {page.length} of {controlledPageCount}{' '}
                results
              </td>
            )}

            <td className="pagination">
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => prev()} disabled={!canPreviousPage}>
                {'<'}
              </Button>{' '}

              <span>
                &nbsp;Page{' '}
                <strong>
                  {Math.ceil(pageIndex + 1)} of {Math.ceil(controlledPageCount/pageSize)}
                </strong>{' '}&nbsp;
              </span>

              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => next()} disabled={!canNextPage}>
                {'>'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(Math.ceil(controlledPageCount/pageSize - 1))} disabled={!canNextPage}>
                {'>>'}
              </Button> 

              {/* <span>
                | Go to page:{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px' }}
                />
              </span>{' '} */}

              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                style={{fontSize: '13px', border: 'none'}}
              >
                {[30, 50, 100, 200].map((pageSize, i) => (
                  <option key={i} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
       </table>
      </div>

      {/* delete confirm */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          {/* <Button onClick={() => deleteLead(leadId)} autoFocus>Yes</Button> */}
        </DialogActions>
      </Dialog>
    </>
  )
}

function UserTable() {

  const columns = React.useMemo(
    () => [
        {
          Header: 'User Id',
          accessor: 'u_id',
        },
        {
          Header: 'Created Date',
          accessor: 'create_dt',
        },
        {
          Header: 'Username',
          accessor: 'username',
        },
        {
          Header: 'Type',
          accessor: 'utype',
        },
        {
          Header: 'Role',
          accessor: 'urole',
        }
      ],
    []
  )
      
  const role = useParams(); 
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const fetchIdRef = React.useRef(0);
  const queryClient = useQueryClient();
  const [totalusercount, settotalusercount] = React.useState(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex, data })  => {
    const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        getRoleWiseUsers(role.role,pageSize, Math.ceil(startRow))
        .then((response) => {
            console.log(response)
            settotalusercount(response.data[0].total_count)
            setData(response.data !== 'No Data Found' ? response.data : [])
        })
        .catch((err) => {
          console.log(err);
        });      
        setLoading(false)
      } 
  }, [])

  const memoizedData = React.useMemo(() => data, [data]);

  return (
    <Table
      columns={columns}
      data={memoizedData}
      fetchData={fetchData}
      loading={loading}
      pageCount={Number(totalusercount)}
    />
  )
}

export default UserTable;