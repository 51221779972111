import React from "react";
import Grid from "@mui/material/Grid";
import Google from "../../Assets/images/source/google.png";
import Facebook from "../../Assets/images/source/facebook.png";
import Housing from "../../Assets/images/source/housing.png";
import MagicBricks from "../../Assets/images/source/magicbricks.png";
import Acres from "../../Assets/images/source/acres.png";
import Olx from "../../Assets/images/source/olx.png";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import SourceCountCard from "../../Components/SourceCountCard/SourceCountCard";
import Charts from './Charts/Charts';
import Donut from './Donut/Donut';
import ProjectWise from "./ProjectWise/ProjectWise";
import CrmDetail from './CrmDetail/CrmDetail';
import LeadStatus from "./LeadStatus/LeadStatus";
import Activity from "./Activity/Activity";
import Attendance from "./Attendance/Attendance";
import { useQuery } from "react-query";
import { getSourceCount, getAllLeadStatusList, getAllUsersList } from "./ApiCalls";
import { Alert } from "@mui/material";

import Box from '@mui/material/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import TimelineDot from "@mui/lab/TimelineDot";
import Chip from "@mui/material/Chip";

import Cookies from 'js-cookie';

function createsData(simg, sname, scount, spercent, sdesc) {
  return {
    simg,
    sname,
    scount,
    spercent,
    sdesc,
  };
}
 
const DashboardPage = () => {
 
  const data = useQuery('dashboard', ()=>{
    return getSourceCount()
  });
   
  const leadStatus = useQuery("LeadStatusdashboard", () => {
    return getAllLeadStatusList();
  });

  const usersTotal = useQuery("TotalUsersdashboard", () => {
    return getAllUsersList();
  });

  if(data.isLoading){
    return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
  }
  if(data.isError){
    return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
  }

  const lsrccard = [  
    createsData(Facebook, "Facebook", data.data.facebookCount.data[0].leadcount, "+55.2%", "than yesterday"),
    createsData(Google, "Google", data.data.googleCount.data[0].leadcount, "+10%", "than lask week"),
    createsData(Housing, "Housing", data.data.housingCount.data[0].leadcount, "+34.5%", "than last month"),
    createsData(MagicBricks, "MagicBricks", data.data.magicbricksCount.data[0].leadcount, "+20.7%", "Just updated"),
    createsData(Acres, "99 Acres", data.data.acresCount.data[0].leadcount, "+30.7%", "Just updated")
  ]; 
  
  var Chartdata = [
    {
      name: "Facebook",
      Leads: data.data.facebookCount.data[0].leadcount,
      amt: data.data.facebookCount.data[0].leadcount
    },
    {
      name: "Google",
      Leads: data.data.googleCount.data[0].leadcount,
      amt: data.data.googleCount.data[0].leadcount
    },
    {
      name: "Housing",
      Leads: data.data.housingCount.data[0].leadcount,
      amt: data.data.housingCount.data[0].leadcount
    },
    {
      name: "MagicBricks",
      Leads: data.data.magicbricksCount.data[0].leadcount,
      amt: data.data.magicbricksCount.data[0].leadcount
    },
    {
      name: "99 Acres",
      Leads: data.data.acresCount.data[0].leadcount,
      amt: data.data.acresCount.data[0].leadcount
    },
  ]

  return (
    <>
      <Breadcrumb PageName="Dashboard" PageDesc="Welcome To Admin Dashboard" />
      <Grid container pt={2.5} spacing={4}>
        {lsrccard.map((row, index) => {
          return (
            <Grid item xs={12} md={3} key={index}>
              <SourceCountCard
                SourceImg={row.simg}
                SourceName={row.sname}
                LeadCount={row.scount}
                Percent={row.spercent}
                Description={row.sdesc}
              />
            </Grid>
          )
        })}
      </Grid>
      <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Charts data={Chartdata} />
        </Grid> 
        <Grid item xs={12} md={4}>
          <LeadStatus data={leadStatus.data.data} /> {/* <Donut /> */}
        </Grid>   
      </Grid>
      {/* <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <ProjectWise />
        </Grid> 
        <Grid item xs={12} md={4}>
          <LeadStatus />
        </Grid>   
      </Grid> */}
    
      <Grid item md={12} sx={{mt:4, mb: 4}}></Grid>
    {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? 
      <Grid container spacing={4}>
        {usersTotal.isSuccess ? (
          <Grid item xs={12} md={4}>
            <Box className="cstbox">
              <h5 className="ltitle" style={{ marginBottom: "10px" }}>
                Todays Activity
              </h5>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  height: "360px",
                  overflowY: "scroll",
                }}
              >
                {usersTotal.data.data !== 'No Data Found' ? 
                  usersTotal.data.data.map((row, index) => {
                   return (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot color={row.session_id === 'yes' ? "success" : "error"} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>{row.username}</TimelineContent>
                      <h6 style={{ float: "right" }}>
                        <Chip
                          // color="success"
                          color={row.session_id === 'yes' ? "success" : "error"}
                          // icon={<TaskAltIcon />}
                          icon={row.session_id === 'yes' ? <TaskAltIcon /> : <CancelIcon />}
                          // label="Online"
                          label={row.session_id === 'yes' ? "Login" : "Logout"}
                          size="small"
                        />
                      </h6>
                    </TimelineItem>
                  );
                })
              :
                null
              }
              </Timeline>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    : null
    }

      {/* <Grid container spacing={4}> */}
        {/* <Grid item xs={12} md={4}>
          <Activity />
        </Grid>  */}
        {/* <Grid item xs={12} md={4}>
           <Attendance />
        </Grid> */}
        {/* <Grid item xs={12} md={4}> */}
          {/* <CrmDetail /> */}
        {/* </Grid>    */}
      {/* </Grid> */}
    </>
  );
};

export default DashboardPage;