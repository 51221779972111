import React, { useState, useEffect } from "react";
import { Link, Outlet, Navigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import LoopIcon from '@mui/icons-material/Loop';
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import Slide from '@mui/material/Slide';

function refreshPage() {
  window.location.reload(false);
}

export default function Footer() { 
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    if(window.location.pathname === '/leads'){
      setValue('leads');
    }else if(window.location.pathname === '/followup'){
      setValue('followup');
    }else if(window.location.pathname === '/account'){
      setValue('account');
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  return (
    window.location.pathname === '/' || window.location.pathname === '' ? 
      <Navigate to="/login" component={Link} replace={true} />
    : <>
      <Slide in direction='up' timeout={800}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 , zIndex: '1000'}}
          elevation={5}
        >
          <Box sx={{ px: 1 }}>
            <BottomNavigation value={value} onChange={handleChange} className="footermenu">
              <BottomNavigationAction
                component={Link}
                to="/leads"
                label="Leads"
                value="leads"
                icon={
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        bottom: 4,
                        borderRadius: 1,
                        width: "28px",
                        height: "20px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <GroupsRoundedIcon sx={{ fontSize: "28px" }} />
                  )
                }
                sx={{ lineHeight: 1 }}
              />
              <BottomNavigationAction
                component={Link}
                to="/followup"
                label="Follow Ups"
                value="followup"
                icon={
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        bottom: 4,
                        borderRadius: 1,
                        width: "28px",
                        height: "20px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <EventAvailableOutlinedIcon sx={{ fontSize: "28px" }} />
                  )
                }
              />
              <BottomNavigationAction
                component={Link}
                to="/account"
                label="Account"
                value="account"
                icon={
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        bottom: 4,
                        borderRadius: 1,
                        width: "28px",
                        height: "20px",
                      }}
                      animation="wave"
                    />
                  ) : (
                    <ManageAccountsRoundedIcon sx={{ fontSize: "28px" }} />
                  )
                }
              />
              <BottomNavigationAction
                label="Refresh"
                value="refresh"
                icon={<LoopIcon sx={{fontSize: '28px'}} />}
                onClick={refreshPage}
              />
            </BottomNavigation>
          </Box>
        </Paper>
      </Slide>
      <Outlet />
    </>
  );
}