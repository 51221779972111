import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../../../DesktopApp'; 

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllGeolocationData = async() =>{
    try{

        const response = await Axios.post(`${API_URL}/desktop/geolocation/getallgeolocationdata`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const getAllCountryList = async() =>{
    try{

        const response = await Axios.post(`${API_URL}/desktop/geolocation/getallcountrylist`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const getAllStateList = async() =>{
    try{

        const response = await Axios.post(`${API_URL}/desktop/geolocation/getallstatelist`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
}

const getAllCityList = async() =>{
    try{

        const response = await Axios.post(`${API_URL}/desktop/geolocation/getallcitylist`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const getAllLocalityList = async() =>{
    try{

        const response = await Axios.post(`${API_URL}/desktop/geolocation/getalllocalitylist`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const setAddCountry = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/addcountry`, {
            create_dt: date,
            update_dt: date,
            country_code: data.get('country_code'),
            country: data.get('country'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setAddState = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/addstate`, {
            create_dt: date,
            update_dt: date,
            country_id: data.get('country_id'),
            state: data.get('state'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setAddCity = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/addcity`, {
            create_dt: date,
            update_dt: date,
            country_id: data.get('country_id'),
            state_id: data.get('state_id'),
            city: data.get('city'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setAddLocality = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/addlocality`, {
            create_dt: date,
            update_dt: date,
            country_id: data.get('country_id'),
            state_id: data.get('state_id'),
            city_id: data.get('city_id'),
            locality: data.get('locality'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setAddSubLocality = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/addsublocality`, {
            create_dt: date,
            update_dt: date,
            country_id: data.get('country_id'),
            state_id: data.get('state_id'),
            city_id: data.get('city_id'),
            locality_id: data.get('locality_id'),
            sub_locality: data.get('sub_locality'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setDeleteSubLocality = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/deletesublocality`, {
            sublocality_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

const getEditSubLocality = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/editsublocality`, {
            sublocality_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}


const setUpdateSubLocality = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/updatesublocality`, {
            update_dt: date,
            sublocality_id: data.get('sublocality_id'),    
            country_id: data.get('country_id'),
            state_id: data.get('state_id'),
            city_id: data.get('city_id'),
            locality_id: data.get('locality_id'),
            sub_locality: data.get('sub_locality'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const getEditLocality = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/editlocality`, {
            locality_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

const setUpdateLocality = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/geolocation/updatelocality`, {
            update_dt: date,
            locality_id: data.get('locality_id'),    
            country_id: data.get('country_id'),
            state_id: data.get('state_id'),
            city_id: data.get('city_id'),
            locality: data.get('locality'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setImportConfiguration = async() =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/configuration/importconfiguration`, {
        });

        return response;

    }catch(err){

      return err;

    }
}

export {getAllGeolocationData, getAllCountryList, getAllStateList, getAllCityList, getAllLocalityList, setAddCountry, setAddState, setAddCity, setAddLocality, setAddSubLocality, setDeleteSubLocality, getEditSubLocality, getEditLocality, setUpdateSubLocality, setUpdateLocality, setImportConfiguration}