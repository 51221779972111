import React, { useState, useEffect }  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LeadCard from '../../Components/LeadCard/LeadCard';
// import { styled, alpha } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar'; 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography'; 
import Button from '@mui/material/Button'; 
import List from '@mui/material/List'; 
import Skeleton from '@mui/material/Skeleton'; 
import ListItem from '@mui/material/ListItem'; 
import ListItemAvatar from '@mui/material/ListItemAvatar'; 
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";  
import Checkbox from '@mui/material/Checkbox'; 
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction"; 
import HowToRegIcon from '@mui/icons-material/HowToReg'; 
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'; 
import Paper from "@mui/material/Paper";
import MultiSelectMenu from '../../Components/MultiSelectMenu/MultiSelectMenu';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle"; 
// pre-catching
import { useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller"; 
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { getLeads } from '../Leads/ApiCalls';
import { unSetLead, assignLead, getAssignUser } from './ApiCalls';
import { ToastContainer, toast } from 'react-toastify';
import ReactWhatsapp from 'react-whatsapp'; 
// import PropTypes from 'prop-types';
import '../Leads/Leads.css'; 
import '../ViewLead/Viewlead.css';
import Cookies from 'js-cookie';

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//   },
// }));
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit', '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));

const MultiSelect = () => {
  
  const[usersList, setUsersList] = useState([]);
  const[selectedAssignUser, setSelectedAssignUser] = useState('');

  // infinite scroll
  const LIMIT = 10;
  const {
    data, 
    // isLoading, 
    // isError, 
    isSuccess, 
    hasNextPage, 
    fetchNextPage, 
    // isFetching, 
    // isFetchingNextPage
  } = useInfiniteQuery(
    'repos', 
    ({pageParam = 0}) => getLeads(LIMIT,pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage < lastPage.totalPages) return lastPage.nextPage;
        return undefined;
      }
    }
  )
  
  // 
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [lids, setLids] = useState([]);
  const urllid = queryParams.get('lids');
  const uniqueLids = lids.filter((val,id,array) => array.indexOf(val) === id);
  
  const handleLid = (event) => { 
    if (event.target.checked) {
      setLids(arr => [...arr, event.target.value]);
      lids.push(event.target.value); 
    } else {
      removeLid(event);
    } 
  };
  const removeLid = (e) => { 
    setLids([...lids.filter((ids) => ids !== e.target.value)]) 
  } 
   
  // menu
  const [fvalue, setFvalue] = useState();
  const handleFchange = (event, newValue) => {
    setFvalue(newValue);
    if(newValue === 'delete'){
      openConfirmBox();
      setConfirmBoxTitle('delete');
    }else if(newValue === 'share'){
      <ReactWhatsapp 
        number='' 
        message="Hello," 
        style={{marginTop: '5px', backgroundColor: 'transparent', border: 'none'}}
      />
    }
  };

  // confirm box
  const [confirmbox, setConfirmBox] = useState(false);
  const [confirmboxtitle, setConfirmBoxTitle] = useState('');
  const openConfirmBox = () => {
    setConfirmBox(true);
  };
  const closeConfirmBox = () => {
    setConfirmBox(false);
  };

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const redirect = () => {
    navigate("/leads");
  }

  const deleteLead = () => async (event) => {
    let uniqueids = lids.filter((val,id,array) => array.indexOf(val) === id);
    unSetLead(uniqueids)
    .then((response) => {
      if(response.data === 'lead deleted'){
        closeConfirmBox()   
        notify("Leads Deleted Successfully")
        setTimeout(() => redirect(), 3000);
      }
      console.log(response)
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const assignLeads = () => async (event) => {
    let uniqueids = lids.filter((val,id,array) => array.indexOf(val) === id);
    assignLead(uniqueids, selectedAssignUser)
    .then((response) => {
      closeConfirmBox()   
      notify("Leads Assigned Successfully")
      setTimeout(() => redirect(), 3000);
    })
    .catch((err) => {
      console.log(err);
    }); 
  };

  const handleCallback = (childData) => {
    openConfirmBox()
    setConfirmBoxTitle('assign')
    setSelectedAssignUser(childData)
  };
  
  const goBack = () => {
    setLids([]);
    navigate("/leads");
  }
  
  useEffect(() => {
    getAssignUser().then((response) => { 
      setUsersList(response.data?.map((users)=>({key: users.username, value: users.u_id})))
    }).catch((err)=>{
      console.log(err);
    })
    if(queryParams.has('lids') || lids.length !== 0){  
      setLids(arr => [...arr, urllid]);
      lids.push(urllid);
    } 
  }, []);
  
  return (
    <>
      <Slide in direction='down' timeout={800}>
        <AppBar position="static" sx={{pt: '4px'}}>
          <Toolbar>
            <Grid container spacing={0} sx={{display: { md: 'none', lg: 'none', xl: 'none'}}}>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  // component={Link} 
                  // to={"/leads"}
                  onClick={goBack}
                  className="backbutton"
                  sx={{p: 0, mr: 2}}
                >
                  <KeyboardBackspaceIcon sx={{ color: "#333", fontSize: "26px", py: 0.5 }}  />
                </Button>
                <Typography
                  variant="span"
                  noWrap
                  component="span"
                  sx={{color: '#333', fontWeight: '500'}}
                > 
                  {uniqueLids.length}&nbsp;selected
                </Typography> 
                {/* <FormControlLabel 
                  control={<Checkbox size='small' sx={{p: 0, mr: 1}} />} 
                  label="Select All"
                  sx={{ml: 0, mr: 0, pt: 0.5,color: '#333', fontWeight: '400', float: 'right'}}
                />  */}
              </Grid>
              {/* <Grid item xs={12}>
                <Search 
                  sx={{mr:0, mt: 1, mb: 1}} 
                  onClick={()=>navigate("/search?multiselect")} 
                >
                  <SearchIconWrapper sx={{px:1}} >
                    <SearchIcon sx={{ zIndex:'2', color:'#333'}} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    sx={{width: '100%', zIndex:'1'}}
                    placeholder="Search.."
                    inputProps={{ 'aria-label': 'search' }}
                  /> */}
                  {/* <Button sx={{ textTransform: 'capitalize', position: 'absolute', right: '0px', zIndex: 10000, top: '1px', fontSize: '12px', padding: '8px'}} >search</Button> */}
                {/* </Search> 
              </Grid> */}
            </Grid> 
          </Toolbar>
        </AppBar>
      </Slide>
      <Box sx={{ width: '100%', pt: '4.3rem'}}> {/* pt: '6.3rem' */}
        <Fade in timeout={1000}>
          <List sx={{ width: '100%', p: 0, mb: 8, bgcolor: 'background.paper' }}>
            {!isSuccess ? Array.from({ length: 15 }, (value, key) => 
              <ListItem key={key}>  
                <ListItemAvatar>
                  <Skeleton
                    variant="rectangular"
                    sx={{p:'7px 8px', width: '40px', height: '40px'}}
                    animation="wave"
                  />
                </ListItemAvatar>
                <Skeleton
                  variant="rectangular"
                  sx={{ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px'}}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  className="leadbutton"
                  sx={{width: '50px', height: '15px', mt: -3.5}}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  className="leaddate"
                  sx={{width: '50px', height: '15px'}}
                  animation="wave"
                />
              </ListItem>)
            : (
              <InfiniteScroll loader={<div className="loader" key={0}>Loading ...</div>} useWindow={true} threshold={50} hasMore={hasNextPage} loadMore={fetchNextPage}>
                <List sx={{ width: '100%', p: 0, mb: 8, bgcolor: 'background.paper' }}> 
                  {data.pages?.map((page) => (
                    page.response.data?.map((lead)=>(
                      <FormGroup key={lead.l_id} >
                        <FormControlLabel
                          sx={{width: '100%', display: 'block', ml: 0, mr: 0}} 
                          label={
                            <LeadCard
                              key={lead.l_id}
                              lead_id = {lead.l_id}
                              lead_name = {lead.lname}
                              lead_pname = {lead.pname}
                              lead_source = {lead.source}
                              lead_color = {lead.color}
                              lead_status={lead.status}
                              lead_create_dt = {lead.create_dt}
                              lead_clicked = {lead.clicked}
                            />    
                          }
                          control={
                            <Checkbox 
                              inputProps={{ 'aria-label': 'controlled' }}
                              size="small"
                              sx={{position: 'absolute', p: 0, mt: '6px', right: '0.6rem'}}
                              value={lead.l_id}
                              onChange={handleLid}
                              // defaultChecked={parseInt(urllid) === lead.l_id ? true : false}
                              defaultChecked={urllid && urllid !== null && parseInt(urllid) === lead.l_id ? true : false}
                            /> 
                          }
                          className="multichecklabel"
                        /> 
                      </FormGroup> 
                    ))
                  ))}
                </List>
              </InfiniteScroll>
            )} 
          </List>    
        </Fade>
      </Box> 
      <Slide in={lids.length !== 0 ? true : false} direction='up' timeout={800}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 , zIndex: '1000'}}
          elevation={5}
        >
          <Box sx={{ px: 1 }}>
            {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin'  ?
            <BottomNavigation showLabels value={fvalue} onChange={handleFchange} className="footermenu"> 
              <BottomNavigationAction
                label={
                  <MultiSelectMenu 
                    role="multiassign" 
                    mlabel="Assign"
                    mlist={usersList}
                    mutlicloseclass="closeicon" 
                    sendDataToParent={handleCallback}
                  />
                } 
                icon={
                  <MultiSelectMenu 
                    role="multiassign" 
                    mlist={usersList}
                    micon={<HowToRegIcon sx={{fontSize: '28px'}} />} 
                    mutlicloseclass="closeicon" 
                    sendDataToParent={handleCallback}
                  />
                }
                value="assign"
              />
              <BottomNavigationAction
                label="Delete"
                value="delete"
                icon={<DeleteForeverRoundedIcon sx={{fontSize: '28px', pb: '2.5px'}} />} 
              />
            </BottomNavigation> 
            : Cookies.get('role') === 'Team Leader' || Cookies.get('role') === 'Sales Manager' ? 
            <BottomNavigation showLabels value={fvalue} onChange={handleFchange} className="footermenu"> 
              <BottomNavigationAction
                label={
                  <MultiSelectMenu 
                    role="multiassign" 
                    mlabel="Assign"
                    mlist={usersList}
                    mutlicloseclass="closeicon" 
                    sendDataToParent={handleCallback}
                  />
                } 
                icon={
                  <MultiSelectMenu 
                    role="multiassign" 
                    mlist={usersList}
                    micon={<HowToRegIcon sx={{fontSize: '28px'}} />} 
                    mutlicloseclass="closeicon" 
                    sendDataToParent={handleCallback}
                  />
                }
                value="assign"
              />
            </BottomNavigation> 
            : ''}
          </Box>
        </Paper> 
      </Slide>
      {/* confirm box */}
        <Dialog
          open={confirmbox}
          onClose={closeConfirmBox}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to {confirmboxtitle} selected lead's ?
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Lead will be deleted from your record.
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <Button onClick={closeConfirmBox}>No</Button>
            {/* <Button autoFocus>Yes</Button> */}
            <Button onClick={confirmboxtitle === 'assign' ? assignLeads(): deleteLead()}>Yes</Button>
          </DialogActions>
        </Dialog>

        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      {/* ends */}
    </>
  )
}

export default MultiSelect;