import Axios from 'axios';
import { API_URL } from '../../App';

const getStatus = async() =>{
    try{
      const response = await Axios.post(`${API_URL}/status/getAllStatus`);
        return response;
    }catch(err){
        return err;
    }
}

export {getStatus}