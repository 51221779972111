import React, { useEffect, useState } from "react";
import { Country, State, City } from "../../Data/CountryStateCity"
// import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  ServiceType,
  Locality,
  SubLocality,
  PropertyArea,
  PropertyPrice,
  PropertyType,
  ResidentialPropertyCategory,
  CommercialPropertyCategory,
  FollowUp,
  Ccode,
  Blank,
} from '../../Data/Data';
import {
  CustomSelectFiled,
  CustomInputField,
  CustomFormLabel,
  CustomFormGroup,
  CustomTextareaField,
  CustomMobileFiled,
  CustomMultiSelectFiled
} from "../../Components/Common";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { getAllSourceList, getAllBrokerList, getAllLeadStatusList, getAllProjectList, getAllConfigurationList, setLead } from "./ApiCalls";
import { ToastContainer, toast } from 'react-toastify';

const AddLead = () => {

  const navigate = useNavigate();
  const lastlocation = useParams(); 
  const [source_type, setSourceType] = useState("Direct");
  const [follow_up, setFollowUp] = useState();
  const [ptype, setPtype] = useState();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [sourcelist, setAllSourceList] = React.useState([]);
  const [brokerlist, setAllBrokerList] = React.useState([]);
  const [leadstatuslist, setAllLeadStatusList] = React.useState([]);
  const [projectlist, setAllProjectList] = React.useState([]);
  const [configurationlist, setAllConfigurationList] = React.useState([]);
  const [configuration, setAllConfiguration] = React.useState([]);

  useEffect(() => {

    getAllSourceList().then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllSourceList(response.data.data)
      } else {
        setAllSourceList(response.data.map((sourcelist) => ({ value: sourcelist.source, label: sourcelist.source })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllBrokerList().then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllBrokerList(response.data.data)
      } else {
        setAllBrokerList(response.data.map((brokerlist) => ({ value: brokerlist.brk_id, label: brokerlist.name })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllLeadStatusList().then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllLeadStatusList(response.data.data)
      } else {
        setAllLeadStatusList(response.data.map((leadstatuslist) => ({ value: leadstatuslist.status, label: leadstatuslist.status })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllProjectList().then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllProjectList(response.data.data)
      } else {
        setAllProjectList(response.data.map((projectlist) => ({ value: projectlist.pname, label: projectlist.pname })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllConfigurationList(ptype).then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllConfigurationList(response.data.data)
      } else {
        setAllConfigurationList(response.data.map((configurationlist) => ({ value: configurationlist.configuration, label: configurationlist.configuration })))
      }
    }).catch(err => {
      console.log(err);
    });

  }, [ptype]);

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
});

// 
function filterCity(selectedState){
  let myCity = City.filter(City => selectedState.countryCode.indexOf(City.countryCode) !== -1);
  return myCity.filter(citynew => selectedState.isoCode.indexOf(citynew.stateCode) !== -1);
}
  
  const handleChangePtype = (event) => {
    setPtype(event.value);
    getAllConfigurationList(event.value).then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllConfigurationList(response.data.data)
      } else {
        setAllConfigurationList(response.data.map((configurationlist) => ({ value: configurationlist.configuration, label: configurationlist.configuration })))
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const handleAddLead = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget); 
    if(parseInt(data.get("lmobile")) === 0){
      alert('Please fill out required fields.')
    }else{
      setLead(data)
      .then((response) => {
        notify("Lead is added.")
        setTimeout(() => {
          lastlocation.lastlocation === 'upcomingleads' ? 
            navigate("/upcomingleads")
          :
          lastlocation.lastlocation === 'missedleads' ? 
            navigate("/total-leads")
          :
          lastlocation.lastlocation === 'todaysleads' ? 
            navigate("/total-leads")
          :
          lastlocation.lastlocation === 'assignleads' ? 
            navigate("/total-leads")
          :
          lastlocation.lastlocation ?
            navigate("/nonassignleads")
          :
            navigate("/total-leads");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <>
      <Breadcrumb PageName="Add Lead" BackButton={[true, 'Back',
        lastlocation.lastlocation === 'upcomingleads' ? '/upcomingleads' :
        lastlocation.lastlocation === 'missedleads' ? '/missedleads' :
        lastlocation.lastlocation === 'todaysleads' ? '/todaysleads' :
        lastlocation.lastlocation === 'assignleads' ? '/assignleads' :
        lastlocation.lastlocation ? '/nonassignleads' : '/total-leads', 
      <ArrowBackIosOutlinedIcon />]} />

      <Box component="form" autoComplete="off" onSubmit={handleAddLead} method="post">
        
        <Box className="custom-card">
          <Typography variant="h4" className="custom-card-head">
            Client Personal Details :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <CustomFormLabel formlabel="Lead Type" star="*" />
              </Grid>
              <Grid item xs={6} md={10}>
                <RadioGroup
                  row
                  name="source_type"
                  value={source_type}
                  onChange={(event) => setSourceType(event.target.value)}
                >
                  <FormControlLabel
                    value="Direct"
                    control={<Radio />}
                    label="Direct"
                  />
                  <FormControlLabel
                    value="Broker"
                    control={<Radio />}
                    label="Broker"
                  />
                  <FormControlLabel
                    value="Reference"
                    control={<Radio />}
                    label="Reference"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>

              {source_type === "Direct" ? (
                <CustomFormGroup
                  formlabel="Source"
                  star="*"
                  FormField={
                    <CustomSelectFiled 
                      name="source" 
                      required={true}
                      defaultValue = ""
                      options={sourcelist} 
                    />
                  }
                />
              ) : null}

              {source_type === "Broker" ? (
                <CustomFormGroup
                  formlabel="Broker Name"
                  star="*"
                  FormField={
                    <CustomSelectFiled
                      name = "brk_id"
                      required = {true} 
                      options={brokerlist} 
                    />
                  }
                />
              ) : null}

              {source_type === "Reference" ? (
                <>
                  <CustomFormGroup
                    formlabel="Reference Name"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="ref_name"
                        required={true}
                        placeholder="Enter Reference Name"
                      />
                    }
                  />
                  <CustomMobileFiled
                    formlabel="Ref. Mobile No"
                    star="*"
                    Inputname={['ref_ccode', 'ref_number']}
                    InputRequired={true}
                  />
                  <CustomFormGroup
                    formlabel="Reference Email"
                    FormField={
                      <CustomInputField
                        type="email"
                        name="ref_email"
                        placeholder="Enter Reference Email"
                      />
                    }
                  />
                </>
              ) : null}
             

              <CustomFormGroup
                formlabel="Lead Name"
                star="*"
                FormField={
                  <CustomInputField
                    type="text"
                    name="lname"
                    placeholder="Enter Lead Name"
                    required={true}
                  />
                }
              />

              <CustomMobileFiled
                formlabel="Mobile No."
                star="*"
                type="text"
                placeholder="Mobile No."
                Inputname={['lccode', 'lmobile']}
                options={Ccode}
                defaultvalue={Ccode[0]}
              />
              {/* <CustomMobileFiled
                formlabel="Alternate Mobile No."
                type="number"
                placeholder="Alternate Mobile No."
                Inputname="s_mob"
                options={Ccode}
                defaultvalue={Ccode[0]}
              /> */}
              <CustomFormGroup
                formlabel="Email Id."
                star="*"
                FormField={
                  <CustomInputField
                    type="email"
                    name="lemail"
                    placeholder="Enter Email Id."
                    required={true}
                  />
                }
              />
              {/* <CustomFormGroup
                formlabel="Alternate Email Id."
                FormField={
                  <CustomInputField
                    type="email"
                    name="s_email"
                    placeholder="Enter Alternate Email Id."
                  />
                }
              /> */}
            </Grid>
          </Box>
        </Box>

        {/* <Box className="custom-card" mt={3}>
          <Typography variant="h4" className="custom-card-head">
            Client Status :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}> */}
              {/* <CustomFormGroup
                formlabel="Lead Status"
                star="*"
                FormField={
                  <CustomSelectFiled 
                    name="lead_status" 
                    required={true}
                    defaultValue = ""
                    options={leadstatuslist} 
                  />
                }
              /> */}

              {/* <CustomFormGroup
                formlabel="Follow Up"
                star="*"
                FormField={
                  <CustomSelectFiled
                    name="followup" 
                    required={true}
                    options={FollowUp}
                    onChange={(event) => setFollowUp(event.value)}
                  />
                }
              /> */}
              {/* {follow_up === "Yes" ? (
                <>
                  <CustomFormGroup
                    formlabel="Follow Up Date"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="datetime-local"
                        name="followup_dt"
                        required={true}
                      />
                    }
              />
                </>
              ) : null} */}

              {/* <CustomFormGroup
                formlabel="Project Name"
                FormField={
                  <CustomMultiSelectFiled
                    name="pname" 
                    defaultValue = ""
                    options={projectlist} 
                  />
                }
              />
            </Grid> */}

            {/* <Grid container spacing={2} my={.5}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Add Comment <span className="required-label"></span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <CustomTextareaField
                  name="comments"
                  placeholder="Enter Comments ...."
                />
              </Grid>
            </Grid> */}
          {/* </Box>
        </Box> */}

        <Box className="custom-card" mt={3}>
          <Typography variant="h4" className="custom-card-head">
            Client Requirement Details :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}>

            <CustomFormGroup
                formlabel="Project Name"
                FormField={
                  <CustomMultiSelectFiled
                    name="pname" 
                    defaultValue = ""
                    options={projectlist} 
                  />
                }
              />

              <CustomFormGroup
                formlabel="Service Type"
                FormField={
                  <CustomMultiSelectFiled 
                    name="service_type"
                    options={ServiceType} 
                  />
                }
              />

              <CustomFormGroup
                formlabel="Property Type"
                FormField={
                  <CustomSelectFiled
                    name="ptype"
                    options={PropertyType}
                    onChange={handleChangePtype}
                  />
                }
              />
              <CustomFormGroup
                formlabel="Property Category"
                FormField={
                  <CustomSelectFiled 
                    name="pcategory"
                    options={ptype === "Residential" ? ResidentialPropertyCategory : ptype === "Commercial" ? CommercialPropertyCategory : Blank} 
                  />
                }
              />
              <CustomFormGroup
                formlabel="Configuration"
                FormField={
                  <CustomMultiSelectFiled
                    name="pconfiguration"
                    options={configurationlist} 
                  />
                }
              />
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2} className="custom-from-group">
                    <CustomFormLabel formlabel="Property Area" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="min_area"
                      placeholder="Enter Minimum Area"
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="max_area"
                      placeholder="Enter Maximum Area"
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CustomSelectFiled 
                      name = "units_type"
                      options={PropertyArea} 
                      placeholder='Select Unit' 
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2} className="custom-from-group">
                    <CustomFormLabel formlabel="Property Price" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="min_price"
                      placeholder="Enter Minimum Price"
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="max_price"
                      placeholder="Enter Maximum Price"
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CustomSelectFiled
                      name="currency_type"
                      options={PropertyPrice}
                      placeholder='Select Currency' 
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Country
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Select
                  name = "country"
                  options={Country}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={
                    (item) => {
                      setSelectedCountry(item);
                      setSelectedState([]);
                      setSelectedCity([]);
                    }
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  State
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Select
                  name = "state"
                  options={selectedCountry !== null ? State.filter(State => selectedCountry.isoCode.indexOf(State.countryCode) !== -1) : []}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                    setSelectedCity([]);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  City
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Select
                    name = "city"
                    options={selectedState !== null && selectedState.length !== 0 ? 
                      filterCity(selectedState)
                    : []}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
              </Grid>
              
              <CustomFormGroup
                formlabel="Locality" 
                FormField={
                  <CustomInputField
                    type="text"
                    name="locality"
                    placeholder="Enter Locality"  
                  />
                }
              />

              {/* <CustomFormGroup
                formlabel="Locality"
                FormField={
                  <CustomMultiSelectFiled 
                    name = "locality"
                    options={Locality} 
                  />
                }
              /> */}

              {/* <CustomFormGroup
                formlabel="sub Locality"
                FormField={
                  <CustomMultiSelectFiled
                    name = "sub_locality"
                    options={SubLocality} 
                  />
                }
              /> */}
            </Grid>
            <Grid container spacing={2} my={1}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Other Details <span className="required-label"></span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <CustomTextareaField
                  name="other_details"
                  placeholder="Enter Other Details ...."
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} my={1}>
              <Grid item xs={6} md={2}></Grid>
              <Grid item xs={6} md={10}>
                <Button variant="contained" type="submit" >
                  Add Lead
                </Button>
              </Grid>
            </Grid>  
        
          </Box>
        </Box>

      </Box>

      <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

    </>
  );
};

export default AddLead;