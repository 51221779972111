import Axios from 'axios';
import { API_URL } from '../../../App';

const getStatusCount = async() => {
    try{
        const response = await Axios.post(`${API_URL}/status/getAllStatus`);
        // const response = await Axios.post('http://localhost:3004/status/getAllStatus');
        return response;
    }catch(err){
        return err;
    }
}

const getNewLeadCount = async() =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/status/getNewLeadCount`);
        return {newleadcount:responseCount.data[0].newlead_count}
    }catch(err){
        return err;
    }
}

const getUndefinedLeadCount = async() =>{
    try{
        const undefinedCount = await Axios.post(`${API_URL}/status/getUndefinedCount`);
        return {undefinedcount:undefinedCount.data[0].statuslead_count}
    }catch(err){
        return err;
    }
}

export {getStatusCount, getNewLeadCount, getUndefinedLeadCount}