import Axios from 'axios';
import { API_URL } from '../../App';

const getLeadData = async(lead_id) => {
    try{
      const response = await Axios.post(`${API_URL}/leads/leadDetail`, {
            lead_id: lead_id,
          });
        // const response = await Axios.post('http://localhost:3004/leads/leadDetail', {
        //     lead_id: lead_id,
        //   });
        return response;
    }catch(err){
        return err;
    }
}

const getStatus = async() => {
    try{
      const response = await Axios.post(`${API_URL}/status/getAllStatus`);
        // const response = await Axios.post('http://localhost:3004/status/getAllStatus');
        return response;
    }catch(err){
        return err;
    }
}

const setStatus = async(status, lead_id, lname, p_mob, pname, followup, followup_dt) => {
  try{
    const response = await Axios.post(`${API_URL}/status/updateStatus`, {
      status: status,
      lid: lead_id,
    });
    const history = await Axios.post(`${API_URL}/leads/createLeadHistory`, {
      status: status,
      followup: followup,
      followupdt: followup_dt,
      comments: '',
      lid: lead_id,
      name: lname,
      number: p_mob,
      pname: pname
    });
    return response;
  }catch(err){
      return err;
  }
};

const setFollowupDate = async(followup_dt, lead_id, current_status, followupstatus) => {
  let followup = '';
  if(followupstatus === true){
    followup = 'Yes'
  }else{
    followup = 'No'
  }
    try{
      const response = await Axios.post(`${API_URL}/leads/editleaddate`, {
        followup_dt: followup_dt,
        lid: lead_id,
        current_status : current_status,
        followup: followup
      });
      return response;
    }catch(err){
        return err;
    }
};

const setFollowupDateNo = async(lead_id, current_status, followupstatus) => {
  let followup = '';
  if(followupstatus === true){
    followup = 'Yes'
  }else{
    followup = 'No'
  }
    try{
      const response = await Axios.post(`${API_URL}/leads/editfollowupno`, {
        lid: lead_id,
        current_status : current_status,
        followup: followup
      });
      return response;
    }catch(err){
        return err;
    }
};

const setOtherDetails = async(other_details,lead_id) => {
    try{
      const response = await Axios.post(`${API_URL}/leads/editotherdetails`, {
        other_details: other_details,
        lid: lead_id,
      });
      // const response = await Axios.post('http://localhost:3004/leads/editotherdetails', {
      //   other_details: other_details,
      //   lid: lead_id,
      // });
      return response;
    }catch(err){
        return err;
    }
};

const setComment = async(comment, lead_id, lname, p_mob, pname, followup, followup_dt, status, usercomments) => {
  try{
    // const response = await Axios.post('http://localhost:3004/leads/editcomment', {
    //   comments: comment,
    //   lid: lead_id,
    // });
    const response = await Axios.post(`${API_URL}/leads/editcomment`, {
      comments: comment,
      lid: lead_id,
    });
    const history = await Axios.post(`${API_URL}/leads/createLeadHistory`, {
      status: status,
      followup: followup,
      followupdt: followup_dt,
      comments: usercomments,
      lid: lead_id,
      name: lname,
      number: p_mob,
      pname: pname
    });
    return response;
  }catch(err){
      return err;
  }
};

const unSetLead = async(lead_id) => {
    try{
      // const response = await Axios.post('http://localhost:3004/leads/deletelead', {
      //   lid: lead_id,
      // });
      const response = await Axios.post(`${API_URL}/leads/deletelead`, {
        lid: [lead_id.leadId],
      });
      return response;
    }catch(err){
        return err;
    }
};

export {getLeadData, getStatus, setStatus, setFollowupDate, setOtherDetails, setComment, unSetLead, setFollowupDateNo}