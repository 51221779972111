import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import Skeleton from "@mui/material/Skeleton";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import "./StatusList.css";

export const NewLeadStatus = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  });

  return (
    <ListItem button className="status-card" component={Link} to="/newlead">
      {loading ? (
        <Skeleton
          variant="rectangular"
          style={{
            width: "1.25rem",
            height: "1.25rem",
            margin: "auto 0.75rem",
          }}
          animation="wave"
        />
      ) : (
        <AutoAwesomeOutlinedIcon className="new-lead-icon" />
      )}
      {loading ? (
        <Skeleton
          variant="text"
          width="100px"
          animation="wave"
          style={{ margin: "1rem 0", borderRadius: "0" }}
        />
      ) : (
        <div className="status-name">
          <h3>New Lead</h3>
        </div>
      )}
      <div className="right-side">
        {loading ? (
          <Skeleton
            variant="circular"
            sx={{
              width: "17px",
              height: "17px",
              top: "0",
              right: "8px",
            }}
            animation="wave"
          />
        ) : (
          <h3 className="status-lead-count">{props.count}</h3>
        )}
        {loading ? (
          <Skeleton
            variant="circular"
            sx={{
              width: "17px",
              height: "17px",
              top: "0",
              right: "5px",
            }}
            animation="wave"
          />
        ) : (
          <ChevronRightIcon className="status-icon" />
        )}
      </div>
    </ListItem>
  );
};

export const UndefinedStatus = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  });

  return (
    <ListItem button className="status-card" component={Link} to="/undefinedlead">
      {loading ? (
        <Skeleton
          variant="rectangular"
          style={{
            width: "1.25rem",
            height: "1.25rem",
            margin: "auto 0.75rem",
          }}
          animation="wave"
        />
      ) : (
        <ErrorOutlineIcon className="new-lead-icon"/>
      )}
      {loading ? (
        <Skeleton
          variant="text"
          width="100px"
          animation="wave"
          style={{ margin: "1rem 0", borderRadius: "0" }}
        />
      ) : (
        <div className="status-name">
          <h3>Undefined Lead</h3>
        </div>
      )}
      <div className="right-side">
        {loading ? (
          <Skeleton
            variant="circular"
            sx={{
              width: "17px",
              height: "17px",
              top: "0",
              right: "8px",
            }}
            animation="wave"
          />
        ) : (
          <h3 className="status-lead-count">{props.count}</h3>
        )}
        {loading ? (
          <Skeleton
            variant="circular"
            sx={{
              width: "17px",
              height: "17px",
              top: "0",
              right: "5px",
            }}
            animation="wave"
          />
        ) : (
          <ChevronRightIcon className="status-icon" />
        )}
      </div>
    </ListItem>
  );
};

export const StatusList = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  });

  return (
    <>
      <ListItem button className="status-card" component={Link} to={`/statuslead/${props.lead_status}`}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              width: "1.25rem",
              height: "1.25rem",
              margin: "auto 0.75rem",
            }}
          />
        ) : (
          <div
            className="status-color"
            style={{ background: props.lead_color }}
          ></div>
        )}
        {loading ? (
          <Skeleton
            variant="text"
            width="100px"
            animation="wave"
            style={{ margin: "1rem 0", borderRadius: "0" }}
          />
        ) : (
          <div className="status-name">
            <h3>{props.lead_status}</h3>
          </div>
        )}
        <div className="right-side">
          {loading ? (
            <Skeleton
              variant="circular"
              sx={{
                width: "17px",
                height: "17px",
                top: "0",
                right: "8px",
              }}
              animation="wave"
            />
          ) : (
            <h3 className="status-lead-count">{props.lead_status_count}</h3>
          )}
          {loading ? (
            <Skeleton
              variant="circular"
              sx={{
                width: "17px",
                height: "17px",
                top: "0",
                right: "5px",
              }}
              animation="wave"
            />
          ) : (
            <ChevronRightIcon className="status-icon" />
          )}
        </div>
      </ListItem>
      <Divider variant="inset" sx={{ ml: 0 }} />
    </>
  );
};