import Axios from 'axios';
import { API_URL } from '../../DesktopApp';

const logout = async() =>{
    try{
        const response = await Axios.get(`${API_URL}/logout`);
        return response;
    }catch(err){
        return err;
    }
}

export { logout }