import Axios from 'axios';
import { API_URL } from '../../App';

const unSetLead = async(lead_id) => {
    try{
      const response = await Axios.post(`${API_URL}/leads/deletelead`, {
        lid: lead_id,
      });
      return response;
    }catch(err){
        return err;
    }
};

const assignLead = async(lead_id, user) => {
    try{
      const response = await Axios.post(`${API_URL}/assign/assignlead`, {
        lid: lead_id,
        assign_uid: [user]
      });
      return response;
    }catch(err){
        return err;
    }
};

const getAssignUser = async() => {
  try{
    const response = await Axios.post(`${API_URL}/users/getUserList`);
    return response;
  }catch(err){
      return err;
  }
};

export{unSetLead, assignLead, getAssignUser}