import Axios from 'axios';
import dayjs from 'dayjs';
import { API_URL } from '../../App';
import { encode } from 'html-entities';

const setLead = async (data) => {
  const date = dayjs().format('YYYY-MM-DD HH:mm:ss');
  try{
    let newcurrency;
    if(data.get('currency_type') && data.get('currency_type') !== null){
      let oldcurrency = data.get('currency_type').split('~');
      let symbole = encode(oldcurrency[1], {mode: 'extensive', level: 'html5'});
      newcurrency = [oldcurrency[0], symbole, oldcurrency[2], oldcurrency[3]]; 
    }
    const response = await Axios.post(`${API_URL}/leads/addlead`, {
      // const response = await Axios.post('http://localhost:3004/leads/addlead', {
      u_id: '130',
      create_dt: date,
      update_dt: date,
      lname: data.get('lname'),
      p_ccode: data.get('lccode'),
      p_mob: data.get('lmobile'),
      p_email: data.get('lemail'),
      service_type: data.get('service_type'),
      source_type: data.get('source_type'),
      source: data.get('source'),
      brk_id: '',
      ref_name: data.get('ref_name'),
      ref_ccode: data.get('ref_ccode'),
      ref_mob: data.get('ref_number'),
      ref_email: data.get('ref_email'),
      other_details: data.get('other_details'),
      ptype: data.get('ptype'),
      pcategory: data.get('pcategory'),
      pconfiguration: data.get('pconfiguration'),
      area_unit: data.get('units_type') !== null ? data.get('units_type') : 'null',
      min_area: data.get('min_area') !== null ? data.get('min_area').replace(/[^0-9.]/g, '') : 'null',
      max_area: data.get('max_area') !== null ? data.get('max_area').replace(/[^0-9.]/g, '') : 'null',
      price_unit: newcurrency ? newcurrency.join('~') : 'null',
      min_price: data.get('min_price') !== null ? data.get('min_price').replace(/[^0-9.]/g, '') : 'null',
      max_price: data.get('max_price') !== null ? data.get('max_price').replace(/[^0-9.]/g, '') : 'null',
      pname: data.get('pname'),
      country: data.get('country') !== null ? data.get('country') : 'null',
      state: data.get('state') !== null ? data.get('state') : 'null',
      city: data.get('city'),
      locality: data.get('locality'),
      sub_locality: data.get('sub_locality'),
      status: '',
      followup_dt: '',
    });
    return response;
  }catch(err){
    return err;
  }
}

const getAllSourceList = async(data) =>{
  
  try{
      const response = await Axios.post(`${API_URL}/dynamicFields/getSource`);
      return response;

  }catch(err){

    return err;

  }
}

const getAllBrokerList = async(data) =>{

  try{
      const response = await Axios.post(`${API_URL}/dynamicFields/getBroker`);
      return response;

  }catch(err){

    return err;

  }
}

const getAllLeadStatusList = async(data) =>{

try{
    const response = await Axios.post(`${API_URL}/dynamicFields/getLeadStatus`);
    return response;

}catch(err){

  return err;

}
}

const getAllProjectList = async(data) =>{

try{
    const response = await Axios.post(`${API_URL}/dynamicFields/getProject`);
    return response;

}catch(err){

  return err;

}
}

const getAllConfigurationList = async(ptype) =>{
 console.log(ptype);
try{
    const response = await Axios.post(`${API_URL}/dynamicFields/getConfiguration`, {
      configuration_type: ptype,
    });
    return response;

}catch(err){

  return err;

}
}

export {setLead, getAllSourceList, getAllBrokerList, getAllLeadStatusList, getAllProjectList, getAllConfigurationList}