import Axios from 'axios';
import { API_URL } from '../../App';

const getProfile = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/profile/getProfile`);
        return response;
    }catch(err){
        return err;
    }
}

const logout = async() =>{
    try{
        const response = await Axios.get(`${API_URL}/logout`);
        return response;
    }catch(err){
        return err;
    }
}

const setToken = async(token) =>{
    try{
        const response = await Axios.post(`${API_URL}/profile/setToken`, {token});
        return response;
    }catch(err){
        return err;
    }
}

export {getProfile, logout, setToken}