import React from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
 
const Warning = () => {
  return (
    <React.Fragment>
        <Grid container mt={5} spacing={2}>
            <Grid item p={0} m={0} xs={1} md={1}></Grid>
            <Grid item m={0} p={0} xs={10} md={10}> 
                <Alert severity="error" sx={{boxShadow: '0 0 20px 5px #e4e4e4', position: 'relative'}}>
                    <AlertTitle>Error</AlertTitle>
                    This device is uncompitible, kindly contact system administrator.
                    {/* <AlertTitle pt={1} > 
                        <small style={{position: 'absolute', right: '1rem'}}>contact us</small> 
                    </AlertTitle> */}
                    {/* <br /> */}
                </Alert>
            </Grid>
        </Grid>
    </React.Fragment>
  )
}

export default Warning;