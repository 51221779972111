import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/leads/getAllLeadsCount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getNonAssignLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/desktop/assignnonassign/getallnonassignleadscount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getAssignLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/desktop/assignnonassign/getallassignleadscount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getMissedLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/followups/getMissedLeadCount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getPresentLeadsCount = async (LIMIT, page) =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/followups/getTodayLeadsCount`);
        return responseCount.data[0].leadcount;
    }catch(err){
        return err;
    }
}

const getStatusCount = async() => {
    try{
        const response = await Axios.post(`${API_URL}/status/getAllStatus`);
        return response;
    }catch(err){
        return err;
    }
}

const getNewLeadCount = async() =>{
    try{
        const responseCount = await Axios.post(`${API_URL}/status/getNewLeadCount`);
        return {newleadcount:responseCount.data[0].newlead_count}
    }catch(err){
        return err;
    }
}

const getUndefinedLeadCount = async() =>{
    try{
        const undefinedCount = await Axios.post(`${API_URL}/status/getUndefinedCount`);
        return {undefinedcount:undefinedCount.data[0].statuslead_count}
    }catch(err){
        return err;
    }
}

const getUpcomingLeadCount = async() => {
    try{
        const upcomingCount = await Axios.post(`${API_URL}/followups/getUpcomingLeadCount`);
        return upcomingCount.data[0].leadcount
    }catch(err){
        return err;
    }
}

const getBellLeads = async() => {
    try{ 
        const response = await Axios.post(`${API_URL}/desktop/leads/getDesktopTodayLeads` , {limit: 4, page: 0});
        return response;
    }catch(err){
        return err;
    }
}

const UpdateUserAttendance = async (url, status) => {
    const ip = "";
    if (status !== "In Time") {
        try {
            const response = await Axios.post(`${API_URL}/desktop/userattendance/IntimeAttendance`, {
                UserImage: url,
                system_ip: ip
            });
            return response
        } catch (err) {
            return err;
        }
    } else {
        try {
            const response = await Axios.post(`${API_URL}/desktop/userattendance/OuttimeAttendance`, {
                UserImage: url,
                system_ip: ip
            });
            return response
        } catch (err) {
            return err;
        }
    }
}

const getAttendanceStatus = async (url) => {
    const ip = "";
    try {
        const response = await Axios.post(`${API_URL}/desktop/userattendance/AttendanceStatus`, {
            UserImage: url,
            system_ip: ip
        });
        return response
    } catch (err) {
        return err;
    }
}

export {getLeadsCount, getNonAssignLeadsCount, getAssignLeadsCount, getMissedLeadsCount, getPresentLeadsCount, getStatusCount, getNewLeadCount, getUndefinedLeadCount, getUpcomingLeadCount, getBellLeads, UpdateUserAttendance, getAttendanceStatus}