import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../../../DesktopApp';

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllConfigurationData = async() =>{
    try{

        const response = await Axios.post(`${API_URL}/desktop/configuration/getallconfigurationdata`);
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const setAddConfiguration = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/configuration/addconfiguration`, {
            create_dt: date,
            update_dt: date,
            configuration_type: data.get('configuration_type'),
            configuration: data.get('configuration'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setDeleteConfiguration = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/configuration/deleteconfiguration`, {
            confi_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

const getEditConfiguration = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/configuration/editconfiguration`, {
            confi_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}


const setUpdateConfiguration = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/configuration/updateconfiguration`, {
            update_dt: date,    
            confi_id: data.get('confi_id'),
            configuration_type: data.get('configuration_type'),
            configuration: data.get('configuration'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setImportConfiguration = async() =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/configuration/importconfiguration`, {
        });

        return response;

    }catch(err){

      return err;

    }
}

export {getAllConfigurationData, setAddConfiguration, setDeleteConfiguration, getEditConfiguration, setUpdateConfiguration, setImportConfiguration}