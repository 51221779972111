import Axios from 'axios';
import dayjs from 'dayjs';
import { API_URL } from '../../../DesktopApp';
import { encode } from 'html-entities';

// const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllSourceList = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/dynamicFields/getSource`);
        return response;

    }catch(err){

      return err;

    }
}

const getAllBrokerList = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/dynamicFields/getBroker`);
        return response;

    }catch(err){

      return err;

    }
}

const getAllLeadStatusList = async(data) =>{
  
  try{
      const response = await Axios.post(`${API_URL}/dynamicFields/getLeadStatus`);
      return response;

  }catch(err){

    return err;

  }
}

const getAllProjectList = async(data) =>{
  
  try{
      const response = await Axios.post(`${API_URL}/dynamicFields/getProject`);
      return response;

  }catch(err){

    return err;

  }
}

const getAllConfigurationList = async(ptype) =>{ 
  try{
      const response = await Axios.post(`${API_URL}/dynamicFields/getConfiguration`, {
        configuration_type: ptype,
      });
      return response;

  }catch(err){

    return err;

  }
}

const setLead = async (data, lead_id) => {
  const date = dayjs().format('YYYY-MM-DD HH:mm:ss');
  try {
      let newcurrency;
      if(data.get('currency_type') && data.get('currency_type') !== null){
          let oldcurrency = data.get('currency_type').split('~');
          let symbole = encode(oldcurrency[1], {mode: 'extensive', level: 'html5'});
          newcurrency = [oldcurrency[0], symbole, oldcurrency[2], oldcurrency[3]]; 
      }
      const response = await Axios.post(`${API_URL}/leads/editlead`, {
          // const response = await Axios.post('http://localhost:3004/leads/editlead', {
          edit_user: data.get('user'),
          lid: lead_id.leadId,
          update_dt: date,
          lname: data.get('lname'),
          p_ccode: data.get('lccode'),
          p_mob: data.get('lmobile'),
          p_email: data.get('lemail'),
          source_type: data.get('source_type'),
          // brk_id: '',
          ref_name: data.get('ref_name'),
          ref_ccode: data.get('ref_ccode'),
          ref_mob: data.get('ref_number'),
          ref_email: data.get('ref_email'),
          source: data.get('source'),
          s_ccode: data.get('sccode'),
          s_mob: data.get('smobile'),
          s_email: data.get('semail'),
          status: data.get('lead_status'),
          followup: data.get('followup'),
          followup_dt: data.get('followup_dt'),
          service_type: data.get('service_type'),
          other_details: data.get('other_details'),
          ptype: data.get('ptype'),
          pcategory: data.get('pcategory'),
          pconfiguration: data.get('pconfiguration'),
          area_unit: data.get('units_type') !== null ? data.get('units_type') : 'null',
          min_area: data.get('min_area') !== null ? data.get('min_area').replace(/[^0-9.]/g, '') : 'null',
          max_area: data.get('max_area') !== null ? data.get('max_area').replace(/[^0-9.]/g, '') : 'null',
          price_unit: newcurrency ? newcurrency.join('~') : 'null',
          min_price: data.get('min_price') !== null ? data.get('min_price').replace(/[^0-9.]/g, '') : 'null',
          max_price: data.get('max_price') !== null ? data.get('max_price').replace(/[^0-9.]/g, '') : 'null',
          pname: data.get('pname'),
          country: data.get('country') !== null ? data.get('country') : 'null',
          state: data.get('state') !== null ? data.get('state') : 'null',
          city: data.get('city'),
          locality: data.get('locality'),
          sub_locality: data.get('sub_locality'),
      });
      return response;
  }catch(err){
      return err;
  }
}

const getLead = async (lead_id) => {
  try{
      const response = await Axios.post(`${API_URL}/leads/leadDetail`, {
          lead_id: lead_id,
      });
      return response;
  }catch(err){
      return err;
  }
}  

const setQuickEditLead = async (data, lead_id, comments, name, number, pname, usercomments) => {
  try{
    const response = await Axios.post(`${API_URL}/desktop/leads/quickEditLead`, {
      status: data.get('lead_status'),
      followup: data.get('followup'),
      followupdt: dayjs(data.get('followup_dt')).format('YYYY-MM-DD HH:mm:ss'),
      comments: comments,
      lid: lead_id, 
    });
    const history = await Axios.post(`${API_URL}/leads/createLeadHistory`, {
      status: data.get('lead_status'),
      followup: data.get('followup'),
      followupdt: dayjs(data.get('followup_dt')).format('YYYY-MM-DD HH:mm:ss'),
      comments: usercomments,
      lid: lead_id, 
      name: name,
      number: number,
      pname: pname
    });
    return response;
  }catch(err){
    return err;
  }
}

const getLeadAssignUserList = async (lead_id) => {
  try{
      const response = await Axios.post(`${API_URL}/desktop/leads/getLeadAssignUserList`, {
          lid: lead_id.leadId, 
      });
      return response;
  }catch(err){
      return err;
  }
}

const getLeadAssignUserData = async (uid, lead_id) => {
  try{
      const response = await Axios.post(`${API_URL}/desktop/leads/getLeadAssignUserData`, {
          uid: uid, 
          lid: lead_id.leadId
      });
      return response;
  }catch(err){
      return err;
  }
}

export { getAllSourceList, getAllBrokerList, getAllLeadStatusList, getAllProjectList, getAllConfigurationList, setLead, getLead, setQuickEditLead, getLeadAssignUserList, getLeadAssignUserData }