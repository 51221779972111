import Axios from 'axios';
import { API_URL } from '../../App';

const getLeads = async(LIMIT,page,status) => {
    try{
        const response = await Axios.post(`${API_URL}/status/getStatusWiseLeads`, {limit: LIMIT, page: page, status: status.leadStatus});
        // const response = await Axios.post('http://localhost:3004/status/getStatusWiseLeads', {status: status.leadStatus});
        return {response, nextPage: page + 10 };
    }catch(err){
        return err;
    }
}

const getStatusCount = async(status) => {
    try{
        const responseCount = await Axios.post(`${API_URL}/status/getStatusCount`,{status: status.leadStatus});
        return responseCount.data[0].statuslead_count
    }catch(err){
        return err;
    }
}

export {getLeads, getStatusCount}