import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getLeads = async(limit, page) => {
    try{
      const response = await Axios.post(`${API_URL}/desktop/assignnonassign/getallnonassignleads`, {limit: limit, page: page});
      return response
    }catch(err){
        return err;
    }
}

const unSetLead = async(lead_id) => {
  try{
    const response = await Axios.post(`${API_URL}/leads/deletelead`, {
      lid: lead_id,
    });
    return response;
  }catch(err){
      return err;
  }
};

export {getLeads, unSetLead}