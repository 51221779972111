import React from "react";
import { styled, alpha } from "@mui/material/styles";
import {
    Card,
    Grid,
    CardActions,
    CardContent,
    Typography,
    IconButton,
    Tooltip,
    InputBase,
    Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import "./AllStatus.css";
import { NavLink } from "react-router-dom";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SourceCountCard from '../../Components/SourceCountCard/SourceCountCard';
import { useQuery } from "react-query";
import { getStatusCount, getNewLeadCount, getUndefinedLeadCount } from "./ApiCalls";
import Alert from "@mui/material/Alert";

function createsData(avatar, uname, uid, role, dated) {
    return {
      avatar,
      uname,
      uid,
      role,
      dated,
    };
}

const AllStatus = () => {

    const allstatus = useQuery("allStatusCount", () => {
        return getStatusCount()
      },{})

    const newleadcount = useQuery("allnewCount", () => {
        return getNewLeadCount()
      },{})
      
    const undefinedleadcount = useQuery("allundefinedCount", () => {
        return getUndefinedLeadCount()
    },{})
    if(newleadcount.isLoading || allstatus.isLoading || undefinedleadcount.isLoading){
        return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
    }
    if(newleadcount.isError || allstatus.isError || undefinedleadcount.isError){
        return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
    }
 
    return (
        <>
            <Breadcrumb PageName="All Status" />
            <Grid container pt={2.5} spacing={4}>
                {/* <Grid item xs={12} md={3} component={NavLink} to={`/leads-by-status/${'new_leads'}/`}>
                            <SourceCountCard
                                SourceImg={'NL'}
                                SourceName={'New Leads'}
                                LeadCount={newleadcount.data.newleadcount}
                                cardRole='usercard'
                            />
                </Grid> */}
                <Grid item xs={12} md={3} component={NavLink} to={`/leads-by-status/${'undefined'}/`} sx={{textDecoration: 'none'}}>
                            <SourceCountCard
                                SourceImg={'UL'}
                                SourceName={'Undefined Leads'}
                                SourceColor="red"
                                LeadCount={undefinedleadcount.data.undefinedcount}
                                cardRole='usercard'
                            />
                </Grid>
            
                {allstatus.data.data.map((row, index) => {
                    return (
                        <Grid item xs={12} md={3} key={index} component={NavLink} to={`/leads-by-status/${row.status}/`} sx={{textDecoration: 'none'}}>
                            <SourceCountCard
                                SourceImg={row.status.charAt(0)+'L'}
                                SourceName={row.status}
                                SourceColor={row.color}
                                LeadCount={row.status_count}
                                cardRole='usercard'
                            />
                        </Grid>
                    )
                })}

            </Grid>
        </>
    );
};

export default AllStatus;
