import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getLeadData = async(lead_id) => {
    try{
      const response = await Axios.post(`${API_URL}/leads/leadDetail`, {lead_id : lead_id});
      return response;
    }catch(err){
      return err;
    }
}

const getLeadAssignUserList = async (lead_id) => {
  try{
      const response = await Axios.post(`${API_URL}/desktop/leads/getLeadAssignUserList`, {
          lid: lead_id.leadId, 
      });
      return response;
  }catch(err){
      return err;
  }
}

export {getLeadData, getLeadAssignUserList}