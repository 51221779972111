import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem'; 
import ListItemText from '@mui/material/ListItemText'; 
import ListItemAvatar from '@mui/material/ListItemAvatar'; 
import Avatar from '@mui/material/Avatar'; 
import Divider from '@mui/material/Divider'; 
import Button from '@mui/material/Button'; 
import Chip from '@mui/material/Chip'; 
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; 
import { setClick } from '../../Pages/Leads/ApiCalls';
import { useLongPress, LongPressDetectEvents } from "use-long-press";
import dayjs from 'dayjs';
import './LeadCard.css';

const LeadCard = (props) => {

  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  });
  
  const handleClicked = (leadid,clicked) => {
    if(clicked === 0){
      setClick(leadid);
    }
  }

  // LongPress
  // const [leadids, setLeadids] = useState([]);
  const callback = useCallback(() => {
    // alert("Long pressed!");
  }, []);
  const getlids = useLongPress(callback, {
    // onStart: (event, meta) => console.log("Press started", meta),
    onFinish: (event, meta) => {
      // setLeadids(arr => [...arr, meta.context]);
      navigate('/multiselect?lids='+meta.context);
    },
    // onCancel: (event, meta) => console.log("Press cancelled", meta),
    // onMove: () => console.log("Detected mouse or touch movement"),
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 600,
    captureEvent: true,
    cancelOnMovement: true,
    detect: LongPressDetectEvents.BOTH
  }); 
  
  return (
    <>
      <ListItem button className='listitem' 
        component={window.location.pathname === '/multiselect' ? null : Link} 
        to={
          window.location.pathname === '/multiselect' ? null : 
          window.location.pathname === '/search' ? `/viewlead/${props.lead_id}?searched` 
          : `/viewlead/${props.lead_id}`
        } 
        onClick={window.location.pathname === '/multiselect' ? null : () => handleClicked(props.lead_id,props.lead_clicked)}
        {...getlids(props.lead_id)}
      >
        <ListItemAvatar>
          {loading ? (
            null
            // <Skeleton
            //   variant="rectangular"
            //   sx={{p:'7px 8px', width: '40px', height: '40px'}}
            //   animation="wave"
            // />
          ) : (
            props.lead_clicked === 0 ? <Avatar className='leadicon' sx={{backgroundColor: '#f186b8'}} variant="square"><AutoAwesomeIcon /></Avatar> : <Avatar className='leadicon' sx={{backgroundColor: '#4a97e2c4'}} variant="square">{props.lead_name.charAt(0).toUpperCase() }</Avatar>
          )}
        </ListItemAvatar>
        {loading ? (
          null
          // <Skeleton
          //   variant="rectangular"
          //   sx={{ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px'}}
          //   animation="wave"
          // />
        ) : (
          <ListItemText className={props.lead_clicked === 0 ? 'newlead-title' : 'lead-title'} sx={{pl: 1.6, mt: -3.8, mb: 0, color: props.lead_clicked === 0 ? '#73afe8' : '#333'}} primary={props.lead_name} secondary={props.lead_source+" lead via "+props.lead_pname} />
        )}
        {loading ? (
          null
          // <Skeleton
          //   variant="rectangular"
          //   className="leadbutton"
          //   sx={{width: '50px', height: '15px'}}
          //   animation="wave"
          // />
        ) : (
          <>
            <Chip 
              className="leadbutton"
              avatar={<Avatar>{
                props.lead_source === 'Facebook' ? "F" : null || 
                props.lead_source === 'Google' ? "G" : null || 
                props.lead_source === 'Housing' ? "H" : null || 
                props.lead_source === 'MagicBricks' ? "MB" : null ||
                props.lead_source === '99 Acres' ? "99" : null ||
                props.lead_source === 'Olx' ? "O" : null || 
                props.lead_source === 'Quicker' ? "Q" : null  
              }</Avatar>} 
              label={props.lead_source} 
            /> 
            <Button className="leadbutton1" sx={{backgroundColor: props.lead_color, color: 'white'}} size="small">{props.lead_status}</Button>
          </>
        )}
  
        {loading ? (
          null
          // <Skeleton
          //   variant="rectangular"
          //   className="leaddate"
          //   sx={{width: '50px', height: '15px'}}
          //   animation="wave"
          // />
        ) : (
          <small className='leaddate'>
            {
              dayjs().format('MMM-DD') === dayjs(props.lead_create_dt).format('MMM-DD') ? 
              'Today at '+dayjs(props.lead_create_dt).format('hh:mm a') : 
              'Yesterday at '+dayjs(props.lead_create_dt).format('hh:mm a') || 
              dayjs().format('MMM-DD') !== dayjs(props.lead_create_dt).format('MMM-DD') ? 
              dayjs(props.lead_create_dt).format('MMM-DD hh:MM a') : null
              // dayjs(props.lead_create_dt).format('MMM-DD hh:MM a') 
            }
          </small>
        )}
      </ListItem>
      <Divider variant="inset" component="li" sx={{ml:0}} />
    </>
  );
}

export default LeadCard;