import React from "react";
import Button from "@mui/material/Button";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { CustomInputField } from "../../../Components/Common";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import Datatable from "../../../Components/Datatable/Datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllSourceData, setAddSource, setDeleteSource, getEditSource, setUpdateSource, setImportSource } from "./ApiCalls";
// import { useNavigate } from "react-router-dom";

const Source = () => {

    // const navigate = useNavigate();
    const [sourcedata, setAllSourceData] = React.useState([]);
    const [editsource, setEditSource] = React.useState();
    const [editsource_id, setEditSourceID] = React.useState();

    const [myPage, setMyPage] = React.useState(0);

    const notifyAddSuccess = () => toast.success("Source Added Succefully");

    const notifyDeleteSuccess = () => toast.success("Source Deleted Succefully");

    const notifyUpdateSuccess = () => toast.success("Source Updated Succefully");

    // const notifyImportSuccess = () => toast.success("Source CSV Import Succefully");

    const notifyAddFailed = () => toast.error("Failed! Source with same name already exist");

    const notifyDeleteFailed = () => toast.error("Failed! Not Deleted");

    const notifyUpdateFailed = () => toast.error("Failed! Source with same name already exist");

    // const notifyImportFailed = () => toast.error("Failed! Source with same name already exist");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        getAllSourceData().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllSourceData(response.data.data)
            } else {
                setAllSourceData(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleDeleteSource = (source_id) => (event) => {
        event.preventDefault();
        if (window.confirm('Are Your Sure! Delete the Source?')) {
            // handleOpen();
            const data = source_id;
            setDeleteSource(data)
                .then((response) => {
                    console.log(response);
                    // navigate("/source");
                    if (response.data === "Source deleted") {
                        // handleClose();
                        getAllSourceData().then(response => {
                            console.log(response);
                            if (response.data === "No Data Found") {
                                setAllSourceData(response.data.data)
                            } else {
                                setAllSourceData(response.data)
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                        notifyDeleteSuccess();
                    } else {
                        notifyDeleteFailed();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleUpdateSource = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("source"));
        // console.log(data.get("source_id"));
        setUpdateSource(data)
            .then((response) => {
                console.log(response);
                // navigate("/source");
                if (response.data === "Source Updated") {
                    handleClose();
                    getAllSourceData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllSourceData(response.data.data)
                        } else {
                            setAllSourceData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyUpdateSuccess();
                } else {
                    notifyUpdateFailed();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAddSource = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("source"));
        // console.log(data.get("source"));
        setAddSource(data)
            .then((response) => {
                console.log(response);
                // navigate("/source");
                if (response.data === "Source Added") {
                    handleClose();
                    getAllSourceData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllSourceData(response.data.data)
                        } else {
                            setAllSourceData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyAddSuccess();
                } else {
                    notifyAddFailed();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handImportSource = (event) => {
        event.preventDefault();
        // alert('Improt Work');
        // const data = new FormData(event.currentTarget);
        // alert(data.get("source"));
        // console.log(data);
        setImportSource()
            .then((response) => {
                console.log(response);
                // navigate("/source");
                // if (response.data === "Source Added") {
                    // handleClose();
                    // getAllSourceData().then(response => {
                    //     console.log(response);
                    //     if (response.data === "No Data Found") {
                    //         setAllSourceData(response.data.data)
                    //     } else {
                    //         setAllSourceData(response.data)
                    //     }
                    // }).catch(err => {
                    //     console.log(err);
                    // });
                //     notifyImportSuccess();
                // } else {
                //     notifyImportFailed();
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "Editbtn":
                return {
                    SourceLable : "Source Name",
                    ModalTitle: "Edit Source",
                    SubmitHandle: handleUpdateSource,
                    SourceValue: editsource,
                    BtnName: "Update Source",
                }
            case "Addbtn":
                return {
                    SourceLable : "Source Name",
                    ModalTitle: "Add Source",
                    SubmitHandle: handleAddSource,
                    SourceValue: "",
                    BtnName: "Add Source",
                }
            case "OnSourceChange": {
                const newValue = action.payload;
                return {
                    SourceLable : state.SourceLable,
                    ModalTitle: state.ModalTitle,
                    SubmitHandle: state.SubmitHandle,
                    SourceValue: newValue,
                    BtnName: state.BtnName,
                };
            }
            case "Importbtn": {
                return {
                    SourceLable : "Upload CSV",
                    ModalTitle: "Import Source",
                    SubmitHandle: handImportSource,
                    SourceValue: "",
                    BtnName: "Import Source",
                };
            }
            default:
                return state;
        }
    }

    const [state, dispatch] = React.useReducer(reducer,
        { SourceLable: "Source Name", ModalTitle: "Add Source", SubmitHandle: "", SourceValue: "", BtnName: "Add Source" })


    const handleEditSource = (source_id) => (event) => {
        event.preventDefault();
        const data = source_id;
        // alert(data);
        getEditSource(data).then(response => {
            // console.log(response.data[0].source);
            if (response.data === "No Source Found") {
                // setEditSource(response.data.data)
            } else {
                dispatch({ type: "Editbtn" });
                setEditSource(response.data[0].source);
                setEditSourceID(response.data[0].source_id);
                handleOpen();
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const columns = [
        {
            name: " -",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            {/* <Tooltip
                                title="Edit"
                                component="label"
                                onClick={
                                    handleEditSource(sourcedata[dataIndex].source_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Delete"
                                component="label"
                                onClick={
                                    handleDeleteSource(sourcedata[dataIndex].source_id)
                                }>
                                <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip> */}
                            <Tooltip
                                title="Not allowed"
                                component="label"
                            >
                                <IconButton><NotInterestedIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: "source_id",
            label: "Source Id",
        },
        {
            name: "create_dt",
            label: "Posted On",
        },
        {
            name: "update_dt",
            label: "Updated On",
        },
        {
            name: "source",
            label: "Source",
        },
    ];

    const ModalOpenAdd = () => {
        dispatch({ type: "Addbtn" });
        handleOpen();
    }
    const ModalOpenImport = () => {
        dispatch({ type: "Importbtn" });
        handleOpen();
    }
    
    const handleChange = (rdata) => {
        console.log(rdata)
    }
    
    const handleCurrentPage = (currentpage) => {
        setMyPage(currentpage);
    }

    return (
        <>
            <ToastContainer />
            <Breadcrumb PageName="Source" />
            <Box sx={{ mb: 2 }} className='cst-btn'>
                <CustomModal
                    BtnName="Add Source"
                    ImportBtnName = "Import Source"
                    OpenAddModalfun={ModalOpenAdd}
                    OpenImportModalfun={ModalOpenImport}
                    handeleclosefun={handleClose}
                    ModalTitle={state.ModalTitle}
                    open={open}
                    ModalContent={
                        <>
                            <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "600", letterSpacing: ".5px" }} mb={1} >{state.SourceLable}</Typography>
                            <Box component="form" autoComplete="off"
                                onSubmit={state.SubmitHandle} method="post">
                                {state.ModalTitle === "Edit Source" ?
                                    <CustomInputField
                                        type="hidden"
                                        name="source_id"
                                        value={editsource_id}
                                        placeholder="Enter Source Id"
                                        required="required"
                                    />
                                    : null}
                                {state.ModalTitle === "Add Source" || state.ModalTitle === "Edit Source" ?    
                                <CustomInputField
                                    type="text"
                                    name="source"
                                    value={state.SourceValue}
                                    onchange={event =>
                                        dispatch({ type: "OnSourceChange", payload: event.target.value })}
                                    placeholder="Enter Source"
                                    required="required"
                                />
                                : null}
                                {state.ModalTitle === "Import Source" ?
                                    <CustomInputField
                                        type="file"
                                        name="source_csv"
                                        required="required"
                                        accept=".csv"
                                    />
                                    : null}
                                <Button variant="contained" type="submit" startIcon={<AddIcon />} sx={{ mt: 2 }}>
                                    {state.BtnName}
                                </Button>
                            </Box>
                        </>
                    }
                />
            </Box>

            <Datatable sendDataToParent = {handleChange} TabelTitle="Add Source" data={sourcedata?.map(src => {
                return [
                    src,
                    src.source_id,
                    // dayjs(src.create_dt).format('DD-MM-YYYY HH:mm:ss'),
                    dayjs().format('YYYY-MM-DD') === dayjs(src.create_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(src.create_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(src.create_dt).format('hh:MM a'),
                    dayjs().format('YYYY-MM-DD') === dayjs(src.update_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(src.update_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(src.update_dt).format('hh:MM a'),
                    src.source,
                ]
            })} columns={columns} currentPage = {myPage} changeCurrentPage = {handleCurrentPage} />

        </>
    );
};
export default Source;
