import Axios from 'axios';
import { API_URL } from '../../App';

const getLeads = async() => {
    try{
        const response = await Axios.post(`${API_URL}/followups/getSomeDayLeads`);
        // const response = await Axios.post('http://localhost:3004/followups/getSomeDayLeads');
        return response;
    }catch(err){
        return err;
    }
}

export {getLeads}