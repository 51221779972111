import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./Datatable.css";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
});

const Datatable = (props) => {

    const [responsive, setRes0ponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("100");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("100%");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(true);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(false);
    
    const selectedRows = (selectedData) => {
        console.log(selectedData)
    }   

    return (
        <React.Fragment>
            <CacheProvider value={muiCache} className="custom-table">
                <ThemeProvider theme={createTheme()}>
                    <MUIDataTable
                        
                        title={props.TabelTitle}
                        data={props.data}
                        columns={props.columns}
                        options={{
                            // selectableRowsHideCheckboxes: false,
                            selectableRows: false,
                            textLabels: {
                                body: {
                                    noMatch: "No Leads Found",
                                },
                                customToolbarSelect: () => {}
                            },
                            // fixedHeader: true,
                            // fixedSelectColumn: true,
                            // serverSide: true,
                            // search: searchBtn,
                            download: downloadBtn,
                            print: printBtn,
                            viewColumns: viewColumnBtn,
                            filter: filterBtn,
                            filterType: "dropdown",
                            jumpToPage: true,
                            responsive,
                            tableBodyHeight,
                            tableBodyMaxHeight,
                            page: props.currentPage,
                            data: [['Loading Data...']],
                            onChangePage (currentPage) {  
                                props.changeCurrentPage(currentPage)
                            },
                            onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                                console.log(currentRowsSelected)
                                const result = allRowsSelected.map(item => {
                                    return props.data.at(item.index) 
                                });
                                const selectedIds = result.map(item => {
                                    return item.id;
                                }); 
                                props.sendDataToParent(selectedIds)
                                // selectedRows(selectedIds);  
                            }, 
                            onRowsDelete: (rowsDeleted, data) => {
                                props.sendDataToParent('delete')
                                // props.sendDeleteRequest()  
                                return false
                            },
                            onTableChange: (action, tableState) => {
                                // console.log(action, tableState);
                                // if (action === "changePage") { 
                                //   this.changePage(tableState.page);
                                // }
                            },
                            search: false,
                        }}
                        className="custom-datatable"
                    />
                </ThemeProvider>
            </CacheProvider>
        </React.Fragment>
    )
}

export default Datatable;