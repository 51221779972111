import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getLeads = async() => {
    try{
      const response = await Axios.post(`${API_URL}/desktop/leads/getAllLeads`,{limit: 10, page: 0});
      const result = (response.data !== 'No Data Found' ? response.data.map((leads)=>(
        {
          id: leads.l_id,
          name: leads.lname,
          mobile: leads.p_mob,
          email: leads.p_email,
          pname: leads.pname,
          lead_status: leads.color,
          lead_date: leads.create_dt,
          source: leads.source,
          city: leads.city,
          locality: leads.locality,
          assigned_telecaller: "-",
          assigned_sales_manager: "-",
          assigned_team_leader: "Testing",
          assigned_branch_admin: "Tester",
          posted_by: "Master",
        }
        ))  : 'No Data Found');
      return result
    }catch(err){
        return err;
    }
}

const unSetLead = async(lead_id) => {
  try{
    const response = await Axios.post(`${API_URL}/leads/deletelead`, {
      lid: lead_id,
    });
    return response;
  }catch(err){
      return err;
  }
};

const getDropdown = async() => {
  try{
    const source = await Axios.post(`${API_URL}/desktop/searchdropdown/getSource`);
    const project = await Axios.post(`${API_URL}/desktop/searchdropdown/getProject`);
    const configuration = await Axios.post(`${API_URL}/desktop/searchdropdown/getConfiguration`);
    const status = await Axios.post(`${API_URL}/desktop/searchdropdown/getStatus`);
    return {source, project, configuration, status};
  }catch(err){
      return err;
  }
};

export {getLeads, unSetLead, getDropdown}