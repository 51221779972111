import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import DesktopApp from './DesktopApp';
import reportWebVitals from './reportWebVitals';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Warning from './Warning';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    { // mobile view
      isMobile && MobileView ? 
        <App />
      : // desktop view
        isBrowser && BrowserView ?
          <DesktopApp />
      : <Warning />
    }
  </React.StrictMode>
);

reportWebVitals();