import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../../../DesktopApp';

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllSourceData = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/desktop/source/getAllSourceData`);
        // const response = await Axios.post('http://localhost:3004/source/getAllSource');
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const setAddSource = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/source/addsource`, {
            // const response = await Axios.post('http://localhost:3004/source/addsource', {
            create_dt: date,
            update_dt: date,
            source: data.get('source'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setDeleteSource = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/source/deletesource`, {
            // const response = await Axios.post('http://localhost:3004/source/deletesource', {
            source_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

const getEditSource = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/source/editsource`, {
            // const response = await Axios.post('http://localhost:3004/source/editsource', {
            source_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}


const setUpdateSource = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/source/updatesource`, {
            // const response = await Axios.post('http://localhost:3004/source/updatesource', {
            update_dt: date,    
            source_id: data.get('source_id'),
            source: data.get('source'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setImportSource = async() =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/source/importsource`, {
            // const response = await Axios.post('http://localhost:3004/source/importsource', {
        });

        return response;

    }catch(err){

      return err;

    }
}

export {getAllSourceData, setAddSource, setDeleteSource, getEditSource, setUpdateSource, setImportSource}