import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button
} from "@mui/material";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { ToastContainer, toast } from 'react-toastify';
import {getUserData, setUserData} from './ApiCalls';
import {
  CustomInputField,
  CustomFormGroup,
} from '../../Components/Common';

const EditUser = () => {

  const navigate = useNavigate();
  const user_id = useParams(); 
  const lastlocation = useParams(); 
  const [username, setUsername] = useState('');

  useEffect(() => {
    getUserData(user_id.user)
      .then((response) => {
        // notify("Lead is added.")
        // setTimeout(() => {
        //   navigate("/total-leads");
        // }, 1000);
        setUsername(response.data[0].username)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
   
  const handleUpdateUser = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget); 
    if(parseInt(data.get("username")) === ''){
      alert('Please fill out required fields.')
    }else{
      setUserData(user_id.user,data)
      .then((response) => {
        notify("updated")
        setTimeout(() => {
          navigate('/users/'+lastlocation.lastlocation);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <>
      <Breadcrumb PageName="Update User Details" BackButton={[true, 'Back', `${'/users/'+lastlocation.lastlocation}`, <ArrowBackIosOutlinedIcon />]} />

      <Box component="form" autoComplete="off" onSubmit={handleUpdateUser} method="post">
        
        <Box className="custom-card">
          <Typography variant="h4" className="custom-card-head">
            Update User Details :-
          </Typography>
          <Box p={2}>
  
            <Grid container spacing={2} mb={2}>
              <CustomFormGroup
                formlabel="Username"
                FormField={
                  <CustomInputField
                    type="text"
                    name="username"
                    placeholder="Enter Username"
                    defaultVal={username}
                    required={true}
                  />
                }
              />

              <CustomFormGroup
                formlabel="Password"
                FormField={
                  <CustomInputField
                    type="text"
                    name="password"
                    placeholder="Enter New Password"
                    required={true}
                  />
                }
              />
            </Grid>

            <Grid container spacing={2} my={1}>
              <Grid item xs={6} md={2}></Grid>
              <Grid item xs={6} md={10}>
                <Button variant="contained" type="submit" >
                  Update Data
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

    </>
  );
};

export default EditUser;