import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home'; 
import "./Breadcrumb.css";
import Fade from '@mui/material/Fade';

const Breadcrumb = (props) => {
  return (
    <Grid container sx={{pb: 1}} >
      <Grid item xs={2}>
        <Typography variant="h6" className="hometext">
          <HomeIcon />
          <span className="separator">/</span>
          {props.PageName}
        </Typography>
        <Typography variant="h2" className="page-title">
          {props.PageName}
        </Typography>
        {/* <Typography variant="h5" className="page-subtitle">
          {props.PageDesc}
        </Typography> */}
      </Grid>
      <Grid item xs={10} sx={{textAlign: 'left'}}>
        {props.BackButton && props.BackButton[0] === true ?
          <Fade in direction='up' timeout={800}>
            <Button
              variant="outlined"
              sx={{ mx: 1, justifyContent: 'left' }}
              startIcon={props.BackButton[3]}
              component={Link}
              to={props.BackButton[2]}
            >
              {props.BackButton[1]}
            </Button>
          </Fade>
        : null}
        {props.AssignButton && props.AssignButton[0] === true ?
          <Fade in direction='up' timeout={800}>
            <Button
              variant="outlined"
              sx={{ mx: 1, justifyContent: 'left' }}
              startIcon={props.AssignButton[3]}
              component={Link}
              to={props.AssignButton[2]}
            >
              {props.AssignButton[1]}
            </Button>  
          </Fade> 
        : null}
          {/* {props.DeleteAll && props.DeleteAll[0] === true ?
          <Fade in direction='up' timeout={800}>
            <Button
              variant="outlined"
              sx={{ mx: 1, justifyContent: 'left' }}
              startIcon={props.DeleteAll[3]}
              // component={Link}
              // to={}
              onClick={() => props.DeleteAll[2]}
            >
              {props.DeleteAll[1]}
            </Button>  
          </Fade> 
        : null} */}
        {props.DeleteAll && props.DeleteAll[0] === true ?
          <Fade in direction='up' timeout={800}>
            <Button
              variant="outlined"
              sx={{ mx: 1, justifyContent: 'left' }}
              startIcon={props.DeleteAll[3]}
              // component={Link}
              // to={}
              onClick={props.DeleteAll[2]}
            >
              {props.DeleteAll[1]}
            </Button>  
          </Fade> 
        : null}

        {props.ExportLeads && props.ExportLeads[0] === true ?
          <Fade in direction='up' timeout={800}>
            <Button
              variant="outlined"
              sx={{ mx: 1, justifyContent: 'left', float: 'right'}}
              startIcon={props.ExportLeads[3]}
              // component={Link}
              // to={}
              // onClick={props.ImportLeads[2]}
            >
              {props.ExportLeads[1]}
            </Button>  
          </Fade> 
        : null}
        {props.ImportLeads && props.ImportLeads[0] === true ?
          <Fade in direction='up' timeout={800}>
            <Button
              variant="outlined"
              sx={{ mx: 1, justifyContent: 'left', float: 'right', right: '8.5rem'}}
              startIcon={props.ImportLeads[3]}
              // component={Link}
              // to={}
              onClick={props.ImportLeads[2]}
            >
              {props.ImportLeads[1]}
            </Button>  
          </Fade> 
        : null}
      </Grid>
    </Grid>
  );
};

export default Breadcrumb;
