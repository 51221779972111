import React from "react";
import Button from "@mui/material/Button";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { CustomInputField } from "../../../Components/Common";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import Datatable from "../../../Components/Datatable/Datatable";
import { ToastContainer, toast } from 'react-toastify';
import StatusColor from "../../../Components/StatusColor/StatusColor";
import 'react-toastify/dist/ReactToastify.css';
import "./LeadStatus.css"
import { getAllLeadStatusData, setAddLeadStatus, setDeleteLeadStatus, getEditLeadStatus, setUpdateLeadStatus, setImportLeadStatus } from "./ApiCalls";
// import { useNavigate } from "react-router-dom";

const LeadStatus = () => {

    // const navigate = useNavigate();
    const [leadstatusdata, setAllLeadStatusData] = React.useState([]);
    const [color, setColor] = React.useState("");
    const [editleadstatus, setEditLeadStatus] = React.useState();
    const [editls_id, setEditLeadStatusID] = React.useState();

    const [myPage, setMyPage] = React.useState(0);

    const handleCallback = (childData) => { 
        setColor(childData.color_code); 
    };

    const notifyAddSuccess = () => toast.success("Lead Status Added Succefully");

    const notifyDeleteSuccess = () => toast.success("Lead Status Deleted Succefully");

    const notifyUpdateSuccess = () => toast.success("Lead Status Updated Succefully");

    // const notifyImportSuccess = () => toast.success("Lead Status CSV Import Succeful");

    const notifyformvalidate = () => toast.warn("Failed! Color Field is mandatory!");

    const notifyAddFailed = () => toast.error("Failed! Lead Status with same name already exist");

    const notifyDeleteFailed = () => toast.error("Failed! Not Deleted");

    const notifyUpdateFailed = () => toast.error("Failed! Lead Status with same name already exist");

    // const notifyImportFailed = () => toast.error("Failed! Lead Status with same name already exist");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        getAllLeadStatusData().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllLeadStatusData(response.data.data)
            } else {
                setAllLeadStatusData(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleDeleteLeadStatus = (ls_id) => (event) => {
        event.preventDefault();
        if (window.confirm('Are Your Sure! Delete the Lead Status?')) {
            // handleOpen();
            const data = ls_id;
            setDeleteLeadStatus(data)
                .then((response) => {
                console.log(response);
                // navigate("/leadstatus");
                if (response.data === "Lead Status deleted") {
                    // handleClose();
                    getAllLeadStatusData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllLeadStatusData(response.data.data)
                        } else {
                            setAllLeadStatusData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyDeleteSuccess();
                } else {
                    notifyDeleteFailed();
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    };

    const handleUpdateLeadStatus = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("lead_status"));
        // alert(data.get("status_color"));
        // alert(data.get("ls_id"));
        // console.log(data.get("ls_id"));
        setUpdateLeadStatus(data)
        .then((response) => {
            console.log(response);
            // navigate("/leadstatus");
            if (response.data === "Lead Status Updated") {
                handleClose();
                getAllLeadStatusData().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllLeadStatusData(response.data.data)
                    } else {
                        setAllLeadStatusData(response.data)
                    }
                }).catch(err => {
                    console.log(err);
                });
                notifyUpdateSuccess();
            } else {
                notifyUpdateFailed();
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const handleAddLeadStatus = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("status_color"));
        // console.log(data.get("status_color"));
        if(data.get("status_color") !== ""){
            setAddLeadStatus(data)
            .then((response) => {
                console.log(response);
                // navigate("/leadstatus");
                if (response.data === "Lead Status Added") {
                    handleClose();
                    getAllLeadStatusData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllLeadStatusData(response.data.data)
                        } else {
                            setAllLeadStatusData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    notifyAddSuccess();
                } else {
                    notifyAddFailed();
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }else{
            notifyformvalidate();
        }    
    };

    const handImportLeadStatus = (event) => {
        event.preventDefault();
        // alert('Improt Work');
        // const data = new FormData(event.currentTarget);
        // alert(data.get("leadstatus"));
        // console.log(data);
        setImportLeadStatus()
            .then((response) => {
                console.log(response);
                // navigate("/leadstatus");
                // if (response.data === "Lead Status Added") {
                    // handleClose();
                    // getAllLeadStatusData().then(response => {
                    //     console.log(response);
                    //     if (response.data === "No Data Found") {
                    //         setAllLeadStatusData(response.data.data)
                    //     } else {
                    //         setAllLeadStatusData(response.data)
                    //     }
                    // }).catch(err => {
                    //     console.log(err);
                    // });
                //     notifyImportSuccess();
                // } else {
                //     notifyImportFailed();
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "Editbtn":
                return {
                    LeadStatusLable : "Lead Status",
                    ModalTitle: "Edit Lead Status",
                    SubmitHandle: handleUpdateLeadStatus,
                    LeadStatusValue: editleadstatus,
                    BtnName: "Update Lead Status",
                }
            case "Addbtn":
                return {
                    LeadStatusLable : "Lead Status",
                    ModalTitle: "Add Lead Status",
                    SubmitHandle: handleAddLeadStatus,
                    LeadStatusValue: "",
                    BtnName: "Add Lead Status",
                }
            case "OnLeadStatusChange": {
                const newValue = action.payload;
                return {
                    LeadStatusLable : state.LeadStatusLable,
                    ModalTitle: state.ModalTitle,
                    SubmitHandle: state.SubmitHandle,
                    LeadStatusValue: newValue,
                    BtnName: state.BtnName,
                };
            }
            case "Importbtn": {
                return {
                    LeadStatusLable : "Upload CSV",
                    ModalTitle: "Import Lead Status",
                    SubmitHandle: handImportLeadStatus,
                    LeadStatusValue: "",
                    BtnName: "Import Lead Status",
                };
            }
            default:
                return state;
        }
    }

    const [state, dispatch] = React.useReducer(reducer, { LeadStatusLable: "Lead Status", ModalTitle: "Add Lead Status", SubmitHandle: "", LeadStatusValue: "", BtnName: "Add Lead Status" });

    const handleEditLeadStatus = (ls_id) => (event) => {
        event.preventDefault();
        const data = ls_id;
        // alert(data);
        getEditLeadStatus(data).then(response => {
            // console.log(response.data[0].status);
            // console.log(response.data[0].color);
            if (response.data === "No Lead Status Found") {
                // setEditLeadStatus(response.data.data)
            } else {
                dispatch({ type: "Editbtn" });
                setEditLeadStatus(response.data[0].status);
                setColor(response.data[0].color);
                setEditLeadStatusID(response.data[0].ls_id);
                handleOpen();
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const columns = [
        {
            name: " -",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            {/* <Tooltip
                                title="Edit"
                                component="label"
                                onClick={
                                    handleEditLeadStatus(leadstatusdata[dataIndex].ls_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Delete"
                                component="label"
                                onClick={
                                    handleDeleteLeadStatus(leadstatusdata[dataIndex].ls_id)
                                }>
                                <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip> */}
                            <Tooltip
                                title="Not allowed"
                                component="label"
                            >
                                <IconButton><NotInterestedIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: "ls_id",
            label: "Lead Status Id",
        },
        {
            name: "create_dt",
            label: "Posted On",
        },
        {
            name: "update_dt",
            label: "Updated On",
        },
        {
            name: "status",
            label: "Lead Status",
        },
        {
            name: "color",
            label: "Color",
        },
    ];

    const ModalOpenAdd = () => {
        dispatch({ type: "Addbtn" });
        handleOpen();
    }
    const ModalOpenImport = () => {
        dispatch({ type: "Importbtn" });
        handleOpen();
    }
    const handleChange = (rdata) => {
        console.log(rdata)
    }
    
    const handleCurrentPage = (currentpage) => {
        setMyPage(currentpage);
    }

    return (
        <>
            <ToastContainer />
            <Breadcrumb PageName="Lead Status" />
            <Box sx={{ mb: 2 }} className='cst-btn'>
                <CustomModal
                    BtnName="Add Lead Status"
                    ImportBtnName = "Import Lead Status"
                    OpenAddModalfun={ModalOpenAdd}
                    OpenImportModalfun={ModalOpenImport}
                    handeleclosefun={handleClose}
                    ModalTitle={state.ModalTitle}
                    open={open}
                    ModalContent={
                        <>
                            <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "600", letterSpacing: ".5px" }} mb={1} >{state.LeadStatusLable}</Typography>
                            <Box component="form" autoComplete="off"
                                onSubmit={state.SubmitHandle} method="post">
                                {state.ModalTitle === "Edit Lead Status" ?
                                    <CustomInputField
                                        type="hidden"
                                        name="ls_id"
                                        defaultVal={editls_id}
                                        placeholder="Enter Lead Status Id"
                                        required="required"
                                    />
                                : null}
                                {state.ModalTitle === "Add Lead Status" || state.ModalTitle === "Edit Lead Status" ?    
                                <>
                                    <CustomInputField
                                        type="text"
                                        name="lead_status"
                                        defaultVal={state.LeadStatusValue}
                                        onchange={event =>
                                            dispatch({ type: "OnLeadStatusChange", payload: event.target.value })}
                                        placeholder="Enter Lead Status"
                                        required="required"
                                    />
                                    <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "600", letterSpacing: ".5px" }} my={1}>Status Colour</Typography>
                                    <Box className="status-color-row">
                                        <CustomInputField
                                            type="hidden"
                                            name="status_color"
                                            defaultVal={color}
                                            placeholder="Enter Lead Status Color"
                                            required="required"
                                            onchange={()=>setColor}
                                        />
                                        <StatusColor sendDataToParent={handleCallback} />
                                    </Box>
                                </>
                                : null}
                                {state.ModalTitle === "Import Lead Status" ?
                                    <CustomInputField
                                        type="file"
                                        name="lead_status_csv"
                                        required="required"
                                        accept=".csv"
                                    />
                                : null}
                                <Button variant="contained" type="submit" startIcon={<AddIcon />} sx={{ mt: 2 }}>
                                    {state.BtnName}
                                </Button>
                            </Box>
                        </>
                    }
                />
            </Box>
            <Datatable sendDataToParent = {handleChange} TabelTitle="All Status" data={leadstatusdata?.map(lstatus => {
                return [
                    lstatus,
                    lstatus.ls_id,
                    // dayjs(lstatus.create_dt).format('DD-MM-YYYY HH:mm:ss'),
                    dayjs().format('YYYY-MM-DD') === dayjs(lstatus.create_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(lstatus.create_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(lstatus.create_dt).format('hh:MM a'),
                    dayjs().format('YYYY-MM-DD') === dayjs(lstatus.update_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(lstatus.update_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(lstatus.update_dt).format('hh:MM a'),
                    lstatus.status,
                    // lstatus.color,
                    <Box className="lead_status_box" sx={{background:lstatus.color}}></Box>
                ]
            })} columns={columns} currentPage = {myPage} changeCurrentPage = {handleCurrentPage} />

        </>
    );
};

export default LeadStatus;