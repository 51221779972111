import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import "./CustomModal.css";

import AddIcon from "@mui/icons-material/Add";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
};

const CutomModal = (props) => {
    return (
        <> 
            {props.BtnName !== "" ?
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddModalfun}
            >
                {props.BtnName}
            </Button> 
            :
            <>  
            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddCountryModalfun}
            >
                {props.AddCountryBtnName}
            </Button>

            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddStateModalfun}
            >
                {props.AddStateBtnName}
            </Button>

            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddCityModalfun}
            >
                {props.AddCityBtnName}
            </Button>

            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddLocalityModalfun}
            >
                {props.AddLocalityBtnName}
            </Button>

            <Button
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<AddIcon />}
                onClick={props.OpenAddSubLocalityModalfun}
            >
                {props.AddSubLocalityBtnName}
            </Button>

            </>
            }

            {/* <Button
                variant="contained"
                color="success"
                sx={{ mx: 1 }}
                startIcon={<UploadFileIcon />}
                onClick={props.OpenImportModalfun}
            >
                {props.ImportBtnName}
            </Button> */}

            <Modal
                open={props.open}
                onClose={props.onClosefun}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box className="modal-header">
                        <Typography className="modal-title" variant="h3">{props.ModalTitle}</Typography>
                        <button type="button" className="modal-close-btn" onClick={props.handeleclosefun}><span>×</span></button>
                    </Box>
                    <Box p={2}>
                        {props.ModalContent}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default CutomModal