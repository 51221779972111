import Axios from 'axios';
import { API_URL } from '../../App';

const getLeads = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/followups/getTodayLeads`);
        // const response = await Axios.post('http://localhost:3004/followups/getTodayLeads');
        return response;
    }catch(err){
        return err;
    }
}

const getMissedLeadCount = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/followups/getMissedLeadCount`);
        // const response = await Axios.post('http://localhost:3004/followups/getMissedLeadCount');
        return response;
    }catch(err){
        return err;
    }
}

const getUpcomingLeadCount = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/followups/getUpcomingLeadCount`);
        // const response = await Axios.post('http://localhost:3004/followups/getUpcomingLeadCount');
        return response;
    }catch(err){
        return err;
    }
}  

export {getLeads, getMissedLeadCount, getUpcomingLeadCount}