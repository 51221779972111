import React from "react";
// import { styled, alpha } from "@mui/material/styles";
import {
    // Card,
    Grid,
    // CardActions,
    // CardContent,
    // Typography,
    // IconButton,
    // Tooltip,
    // InputBase,
    // Divider,
} from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import PreviewIcon from "@mui/icons-material/Preview";
// import ContactMailIcon from "@mui/icons-material/ContactMail";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import SearchIcon from "@mui/icons-material/Search";
// import AssessmentIcon from "@mui/icons-material/Assessment";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
// import UserImg from "./img_avatar.png"; 
import "./AllUsers.css";
import { Link } from "react-router-dom";
// import CardHeader from '@mui/material/CardHeader';
// import Avatar from '@mui/material/Avatar';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SourceCountCard from '../../Components/SourceCountCard/SourceCountCard';
import { getUsers } from "./ApiCalls";
import { useQuery } from "react-query";
import { Alert } from "@mui/material";

// const Search = styled("div")(({ theme }) => ({
//     position: "relative",
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 1),
//     "&:hover": {
//         backgroundColor: alpha(theme.palette.common.white, 0.9),
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: "20ch",
//     [theme.breakpoints.up("sm")]: {
//         marginBottom: theme.spacing(3),
//         width: "auto",
//     },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: "inherit",
//     "& .MuiInputBase-input": {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create("width"),
//         width: "20ch",
//         [theme.breakpoints.up("md")]: {
//             width: "20ch",
//         },
//     },
// }));

// function userdetails(user_name, user_img, user_role) {
//     return {
//         user_name,
//         user_img,
//         user_role,
//     };
// }
// const UserData = [ 
//     userdetails("Himanshu Pandey", UserImg, "Sales Manager"),
// ];

// const CardIcon = [
//     { cardicon: <EditIcon />, title: "Edit", link: "" },
//     { cardicon: <PreviewIcon />, title: "View CRM", link: "" },
//     { cardicon: <ContactMailIcon />, title: "Update Profile", link: "" },
//     { cardicon: <VisibilityIcon />, title: "View Profile", link: "" },
//     // { cardicon: <AssessmentIcon />, title: "Report" },
// ];

// 
// function createsData(avatar, uname, uid, role, dated) {
//     return {
//       avatar,
//       uname,
//       uid,
//       role,
//       dated,
//     };
// }

const AllUsers = () => {

    const query = useQuery("allUsers", () => {
        return getUsers()
      },{})
    
      if(query.isLoading){
        return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
      }
      if(query.isError){
        return <Alert severity="info" sx={{ mt: 0 }}>Unable to fetch data, check after sometime</Alert>
      }
      console.log(query.data)

    return (
        <>
            <Breadcrumb PageName="All Users" />
            {/* <Search mb={2}>
                <SearchIconWrapper>
                <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                placeholder="Search User…"
                inputProps={{ "aria-label": "search" }}
                />
            </Search> */}
            <Grid container pt={2.5} spacing={4}>
                {/* {UserData.map((row, index) => { */}
                    {/* return ( */}
                        {/* <> */}
                        {/* <Grid item xs={12} md={4} > */}
                            {/* <Card className="user-card">
                                <img src={row.user_img} alt="Avatar" className="user-img" />
                                <CardContent className="user-card-body">
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h6"
                                        className="user-name"
                                    >
                                        {row.user_name}
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h6"
                                        className="user-subname"
                                    >
                                        {row.user_role}
                                    </Typography>
                                </CardContent>
                                <Divider className="bxdivider" />
                                <CardActions>
                                    {CardIcon.map((icon, id) => {
                                        return (
                                            <Link to={icon.link} key={id}>
                                                <Tooltip title={icon.title}>
                                                    <IconButton>{icon.cardicon}</IconButton>
                                                </Tooltip>
                                            </Link>
                                        );
                                    })}
                                </CardActions>
                            </Card> */}
                            
                            {/* <Card sx={{ maxWidth: '100%', borderRadius: '10px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'}}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ backgroundColor: '#3f51b5' }} aria-label="recipe">
                                            M
                                        </Avatar>
                                    }
                                    action={
                                        <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton>
                                        <small style={{paddingRight: '10px', color: '#252b3b', fontWeight: '500'}}>133</small>
                                    }
                                    title="Admin, Master"
                                    subheader="2023 / 01 / 30 12:26"
                                /> 
                            </Card> 
                        </Grid> */}

                        {/* <Grid item xs={12} md={4} >
                            
                        </Grid> */}
                        {/* </>
                    );
                })} */}

                {Object.entries(query.data).map(([arrayKey, arrayValue]) => {
                    return (
                        <Grid item xs={12} md={3} key={arrayKey} component={Link} to={`/users/${arrayKey}/`} sx={{textDecoration: 'none'}}>
                            <SourceCountCard
                                SourceImg={arrayKey.charAt(0)}
                                SourceName={arrayKey}
                                LeadCount={arrayValue.length}
                                // Percent={row.role}
                                // Description={row.dated}
                                cardRole='usercard'
                            />
                        </Grid>
                    )
                })}

            </Grid>
        </>
    );
};

export default AllUsers;
