import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./AddLead.css";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SaveIcon from "@mui/icons-material/Save";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade'; 
import { InputsLabel, AddLeadInput, IntlDropdown, InputEmail, InputMessage, InputAutocomplete, InputCountryStateCity, CarpetAreaInput, PricingInput, InputErrorMessage, InputMultiAutocomplete } from "../../Pages/Common"; 
import { setLead, getAllSourceList, getAllBrokerList, getAllProjectList, getAllConfigurationList, } from "./ApiCalls";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ResidentialPropertyCategory, CommercialPropertyCategory, Blank } from "../../Desktop/Data/Data";
import Cookies from 'js-cookie';

const AddLead = () => {

  const navigate = useNavigate();
  const [clientpershow, setclientpersShow] = useState(true);
  const [source_type, setSourceType] = useState("Direct");
  const [clientreqshow, setclientreqShow] = useState(false);
  const [prjdetailsshow, setprjdetailsShow] = useState(false);

  const [sourcelist, setAllSourceList] = React.useState([]);
  const [brokerlist, setAllBrokerList] = React.useState([]);
  const [leadstatuslist, setAllLeadStatusList] = React.useState([]);
  const [projectlist, setAllProjectList] = React.useState([]);
  const [configurationlist, setAllConfigurationList] = React.useState([]);

  const [ptype, setPtype] = useState();

  const refname = useRef(null);
  const refccode = useRef(null);
  const refnumber = useRef(null);
  const refemail = useRef(null); 
  const refrancename = useRef(null);
  const refrancecode = useRef(null);
  const refrancenum = useRef(null);
  const refranceemail = useRef(null);
  const refform = useRef(null);

  const [nameError, setNameError] = useState(false);
  const [numError, setNumError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [refNumError, setRefNumError] = useState(false);
  
  // Tooltip
  const [tooltipopen, setTooltipOpen] = useState(false);
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  
  useEffect(()=>{
    getAllSourceList().then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllSourceList(response.data.data)
      } else {
        setAllSourceList(response.data.map((sourcelist) => (sourcelist.source)))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllBrokerList().then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllBrokerList(response.data.data)
      } else {
        setAllBrokerList(response.data.map((brokerlist) => ({ value: brokerlist.brk_id, label: brokerlist.name })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllProjectList().then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllProjectList(response.data.data)
      } else {
        setAllProjectList(response.data.map((projectlist) => ({title : projectlist.pname})))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllConfigurationList(ptype).then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllConfigurationList(response.data.data)
      } else {
        setAllConfigurationList(response.data.map((configurationlist) => ({title : configurationlist.configuration})))
      }
    }).catch(err => {
      console.log(err);
    });
  }, [])

  const handleChangePtype = (event) => {
    setPtype(event.value);
    getAllConfigurationList(event.value).then(response => {
      console.log(response);
      if (response.data === "No Data Found") {
        setAllConfigurationList(response.data.data)
      } else {
        setAllConfigurationList(response.data.map((configurationlist) => ({ title: configurationlist.configuration })))
      }
    }).catch(err => {
      console.log(err);
    });

  };

  const validateForm = (valid) => {
    let validForm = valid;
    // lead name
    const leadname = refname.current;
    const nameregExp = /[a-zA-Z]/g;
    if(!nameregExp.test(leadname.value) || leadname.value.length === 0 || leadname.value === ''){
      window.scrollTo(0, 0);
      setNameError(true); 
      validForm = false;
    }else{
      setNameError(false);  
    }
    
    // lead number
    const ccode = refccode.current;
    const number = refnumber.current; 
    if(parseInt(ccode.value) === 0 || parseInt(number.value) === 0 || number.value === ''){
      window.scrollTo(0, 0);
      setNumError(true);
      validForm = false;  
    }else{ 
      setNumError(false);
      validForm = true; 
    }
    
    // lead email
    const email = refemail.current;
    if(email.value.length === 0 || email.value === ''){
      window.scrollTo(0, 0);
      setEmailError(true); 
      validForm = false;
    }else{ 
      setEmailError(false);  
    } 
    
    // refrence
    // const reflname = refrancename.current;
    const reflcode = refrancecode.current;
    const reflnum = refrancenum.current;
    // const reflemail = refranceemail.current;
    if(source_type === 'Reference'){
      if(parseInt(reflcode.value) === 0 || parseInt(reflnum.value) === 0 || reflnum.value === ''){
        setRefNumError(true); 
        validForm = false;
      }else{
        setRefNumError(false); 
        // validForm = true;
      }      
    } 

    // const reflform = refform.current;
    // const data = new FormData(reflform);

    return validForm;
  }; 
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(validateForm() === true){
      const data = new FormData(event.currentTarget); 
      setLead(data)
      .then((response) => {
        notify("Lead is added.");
        setTimeout(() => {
          navigate("/leads");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      validateForm(false);
      console.log('Error submitting form.'); 
    } 
  };
  
  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  return (
    
    <Fade in timeout={800}>
      <div className="createstatus-container">
        <div className="create-status-header" style={{ position: 'fixed'}}>
          <Button size="small" className="close-btn" component={Link} to="/leads">
            <KeyboardBackspaceIcon />
          </Button>
          <h3>
            Add New Lead 
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipopen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <React.Fragment>
                    <Typography color="inherit">Special characters not allowed.</Typography>
                    <em><br />{"Example: ` ~ ! # $ % ^ + = | } { > < [ ] /"}</em>
                  </React.Fragment>
                } //"special character are not allowed"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="bottom-start" 
                arrow
              > 
                <QuestionMarkIcon
                  sx={{position: 'absolute', top: '0.7rem', right: '1rem', color: '#17a2b8'}}
                  onClick={handleTooltipOpen}
                />
              </Tooltip>
            </ClickAwayListener>
          </h3>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{paddingTop: '3.5rem'}}
          ref={refform}
          id="leadform"
        >
          <Grid 
            style={{
              background: "#fff",
            }}
            container
          >
            <Grid item={true} xs={12}>
              <Button
                sx={{ p: 1.6 }}
                className="lead-moredetails-btn"
                onClick={() => setclientpersShow(!clientpershow)}
              >
                <div className="add-leadmore">Client Personal Details</div>
                {clientpershow ? (
                  <KeyboardArrowUpIcon className="add-leadmore-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="add-leadmore-icon" />
                )}
              </Button>  
            </Grid> 
            {clientpershow ? 
              <React.Fragment>
                <Divider variant="hr" sx={{width: '100%'}} />
                <Grid
                  p={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                >
                  <Grid item={true} xs={12}>
                    {nameError === false ?
                      <InputsLabel InputsLabel="Enter Lead Name*" />
                    : 
                      <InputErrorMessage errormsg="name is required." />
                    }
                  </Grid> 
                  <Grid item={true} pt={1} xs={12}>
                    <AddLeadInput
                      InputLabel="alphabets & space only"
                      InputType="search"
                      InputName="lname"
                      Required={nameError === false ? false : true}
                      inputRef={refname}
                      inputId="leadname" 
                    />
                  </Grid>
                </Grid>
                <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
                <Grid
                  p={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                >  
                  <Grid item={true} xs={12}> 
                    {numError === false ?
                      <InputsLabel InputsLabel="Enter Mobile No*" />
                    : 
                      <InputErrorMessage errormsg="invalid mobile no." />
                    }
                  </Grid> 
                  <Grid item={true} pt={1} xs={12}>
                    <IntlDropdown 
                      Inputname={["lccode", "lmobile"]} 
                      InputRequired={true} 
                      InputRef={[refccode, refnumber]} 
                      InputId={['leadccode', 'leadnumber']}
                    />
                  </Grid> 
                </Grid> 
                <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
                <Grid
                  p={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                > 
                  <Grid item={true} xs={12}> 
                    {emailError === false ?
                      <InputsLabel InputsLabel="Enter Email Id*" />
                    : 
                      <InputErrorMessage errormsg="email id is required." />
                    }
                  </Grid> 
                  <Grid item={true} pt={1} xs={12}>
                    <InputEmail
                      InputLabel="alphabets, numbers, @, -, . only"
                      InputType="email"
                      InputName="lemail"
                      Required={emailError === false ? false : true}
                      InputRef={refemail}
                      InputId="leademail"
                    /> 
                  </Grid>
                </Grid>
                <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
                <Grid
                  p={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                > 
                  {/* <Grid item={true} xs={12}>
                    <InputsLabel InputsLabel="Service type*" /> */}
                    {/* <Alert className="common-error" variant="outlined" severity="error">
                      service type is required
                    </Alert> */}
                  {/* </Grid> */}
                  <Grid item={true} pt={1} xs={12} >
                    <InputAutocomplete 
                      inputLable="Service type"
                      sourcetype="Service Type"
                      services={["New Lead", "Resale", "Rent"]}
                      inputname="service_type"
                      inputRequired={true}
                    />
                  </Grid> 
                  {/* <Grid item={true} xs={12}> */}
                  {/* <InputsLabel InputsLabel="Source type*" /> */}
                  {/* <Alert className="common-error" variant="outlined" severity="error">
                      source type is required
                    </Alert> */}
                  {/* </Grid>  */}
                  <Grid item={true} mt={2} xs={12} > 
                    <FormControl variant="outlined" fullWidth> 
                      <InputLabel id="demo-simple-select-label">Source type*</InputLabel>
                      <Select
                        label="Source type*"
                        id="demo-simple-select-outlined"
                        name="source_type" 
                        className="inputautocompletemenuchange"
                        required={true}
                        value={source_type}
                        onChange={(event) => setSourceType(event.target.value)} 
                      > 
                        {["Direct", "Reference", "Broker"]?.map((v, i) => (
                          <MenuItem value={v} key={i}>{v}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> 
                  {/* Dynamic menu */}
                  {source_type === "Direct" ? 
                    <React.Fragment>
                      {/* <Grid item={true} xs={12}>
                        <InputsLabel InputsLabel="Source*" /> */}
                        {/* <Alert className="common-error" variant="outlined" severity="error">
                          source is required
                        </Alert> */}
                      {/* </Grid>  */}
                      <Grid item={true} mt={2} xs={12} >
                        <InputAutocomplete 
                          inputLable="Source"
                          sourcetype="Direct"
                          directServices={sourcelist}
                          inputname="source"
                          inputRequired={true}
                        />
                      </Grid>    
                    </React.Fragment> 
                  : source_type === "Reference" ? 
                    <React.Fragment>
                      <Grid item={true} mt={2} xs={12}>
                        <InputsLabel InputsLabel="Reference*" />
                        {/* <Alert className="common-error" variant="outlined" severity="error">
                          reference details is required
                        </Alert> */}
                      </Grid> 
                      <Grid item={true} mt={1} pb={1} xs={12}>
                        <AddLeadInput
                          InputLabel="Reference Name"
                          InputType="search"
                          InputName="ref_name"
                          Required={true}
                          inputRef={refrancename}
                          inputId="refleadname"
                        />    
                      </Grid>
                      <Grid item={true} mb={1.5} xs={12}>
                        <Divider variant="inset" sx={{ m: 0, width: '100%'}} /> 
                      </Grid>
                      <Grid item={true} xs={12}>
                        {refNumError === false ? null : 
                          <InputErrorMessage errormsg="number is invalid." />
                        }
                        <IntlDropdown 
                          Inputname={["ref_ccode", "ref_number"]} 
                          InputRequired={refNumError === false ? false : true}  
                          InputRef={[refrancecode, refrancenum]}
                          InputId={['reflcode', 'reflnum']}
                        /> 
                      </Grid>
                      {/* <Grid item={true} pl={2} xs={8}>
                        <NumberInput
                          InputDots={false}
                          InputLabel="Reference Mobile No"
                          InputType="search"
                          InputName="ref_number"
                          Required={true}
                        />
                      </Grid> */}
                      <Grid item={true} mt={1.5} xs={12}><Divider variant="inset" sx={{ m: 0, width: '100%'}} /></Grid>
                      <Grid item={true} pt={1} pb={1} xs={12}>
                        <InputEmail
                          InputLabel="Reference Email Id"
                          InputType="email"
                          InputName="ref_email"
                          Required={true}
                          InputRef={refranceemail}
                          InputId="reflemail"
                        />   
                      </Grid>
                    </React.Fragment>
                  : source_type === "Broker" ?
                    <React.Fragment>
                      {/* <Grid item={true} xs={12}>
                        <InputsLabel InputsLabel="Broker*" /> */}
                        {/* <Alert className="common-error" variant="outlined" severity="error">
                          broker is required
                        </Alert> */}
                      {/* </Grid>  */}
                      <Grid item={true} mt={2} xs={12} > 
                        <InputAutocomplete 
                          inputLable="Broker"
                          sourcetype="Broker"
                          brokerServices={["Vinayak Jaiswal", "Nitin Parikh", "Rajan Saraf"]}
                          inputname="broker_name"
                          inputRequired={true}
                        />
                      </Grid>
                    </React.Fragment>
                  : null
                  }
                </Grid>
                <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
                <Grid
                  p={2}
                  style={{
                    background: "#fff",
                  }}
                  container
                >
                  <Grid item={true} xs={12}>
                    <InputsLabel InputsLabel="Other Details" /> 
                  </Grid> 
                  <Grid item={true} pt={1} xs={12}>
                    <InputMessage inputName="other_details" />
                  </Grid>
                </Grid> 
                <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
              </React.Fragment>
            : null}
          </Grid>
          <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
          <Grid 
            style={{
              background: "#fff",
            }}
            container
          >
            <Grid item={true} xs={12}>
              <Button
                sx={{ p: 1.6 }}
                className="lead-moredetails-btn"
                onClick={() => setclientreqShow(!clientreqshow)}
              >
                <div className="add-leadmore">Client Requirements</div>
                {clientreqshow ? (
                  <KeyboardArrowUpIcon className="add-leadmore-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="add-leadmore-icon" />
                )}
              </Button>  
            </Grid> 
            {clientreqshow ?
              <React.Fragment>
                <Divider variant="hr" sx={{width: '100%'}} />
                <Grid 
                  sx={{px: 1.6, pt: 1.5}}
                  container
                >
                  {/* <Grid item={true} xs={12}>
                    <InputsLabel InputsLabel="Project type" />
                  </Grid>  */}
                  <Grid item={true} pt={1} xs={12} >
                    <InputAutocomplete 
                      inputLable="Project type"
                      sourcetype="Project Type"
                      services={["Residential", "Commercial"]}
                      inputname="ptype"
                      inputRequired={false}
                      onChange={handleChangePtype}
                    />
                  </Grid> 
                  <Grid item={true} mt={2} xs={12} >
                    <InputAutocomplete 
                      inputLable="Project Category"
                      sourcetype="Project Category"
                      services={ptype === "Residential" ? ResidentialPropertyCategory : ptype === "Commercial" ? CommercialPropertyCategory : Blank}
                      inputname="pcategory"
                      inputRequired={false}
                    />
                  </Grid>  
                  <Grid item={true} mt={2} xs={12} > 
                    <InputMultiAutocomplete
                      inputLable="Select Configuration"
                      options={configurationlist}
                      inputname="pconfiguration"
                    />
                  </Grid>
                  
                  <Grid item={true} mt={1.5} xs={12}>
                    <InputsLabel InputsLabel="Carpet area" />
                  </Grid>
                  <Grid container pt={2}>
                    <Grid item={true} xs={12}>
                      <CarpetAreaInput
                        unitsname="units_type"
                        minareaname="min_area"
                        maxareaname="max_area"
                        unitlist={["Sq Feet", "Sq Meter", "Sq Yards", "Acres", "Marla", "Cents", "Biggha", "Kottah", "Canals", "Grounds", "Ares", "Guntha", "Biswa", "Hectars", "Roods", "Chataks", "Perch"]}
                      /> 
                    </Grid> 
                  </Grid> 

                  <Grid item={true} mt={1.5} xs={12}>
                    <InputsLabel InputsLabel="Pricing" /> 
                  </Grid> 
                  <Grid container pt={2}> 
                    <Grid item={true} xs={12}>
                      <PricingInput
                        currencyname="currency_type"
                        minpricename="min_price"
                        maxpricename="max_price"
                      /> 
                    </Grid> 
                  </Grid>   
                  <Grid item={true} xs={12} ><br /></Grid>
                </Grid>
              </React.Fragment> 
            : null }   
          </Grid>
          <Divider variant="inset" sx={{ ml: 0, width: '100%'}} />
          <Grid 
            style={{
              background: "#fff",
            }}
            container
          >
            <Grid item={true} xs={12}>
              <Button
                sx={{ p: 1.6 }}
                className="lead-moredetails-btn"
                onClick={() => setprjdetailsShow(!prjdetailsshow)}
              >
                <div className="add-leadmore">Project Details</div>
                {prjdetailsshow ? (
                  <KeyboardArrowUpIcon className="add-leadmore-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="add-leadmore-icon" />
                )}
              </Button> 
            </Grid>
            {prjdetailsshow ? 
              <React.Fragment>
                <Divider variant="hr" sx={{width: '100%'}} />
                <Grid 
                  sx={{px: 1.6, pt: 1.5}}
                  container
                > 
                  <Grid item={true} pt={1} xs={12} > 
                    <InputMultiAutocomplete
                      inputLable="Select Project"
                      options={projectlist}
                      inputname="pname"
                    />
                  </Grid>  
                  <Grid item={true} pt={2} xs={12} >
                    <InputCountryStateCity 
                      inputNames={["country", "state", "city", "locality", "sub_locality"]}
                    />
                  </Grid>    
                  <Grid item={true} xs={12} ><br /></Grid>
                </Grid>
              </React.Fragment>
            : null }
          </Grid>
          <Divider variant="div" sx={{ mb: 4, width: '100%'}} />
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            className="add-submit-button"
            type="submit"
            // type="button"
            // onClick={validateForm}
          >
            Add Lead
          </Button>
        </form>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </Fade>
  );
};

export default AddLead;