import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import FlareIcon from '@mui/icons-material/Flare';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

function createucData(avatar, title, desc) {
    return {
        avatar,
        title,
        desc,
    };
}
const crmdetails = [
    createucData(<SwitchAccountIcon />,'CRM Users', 2),
    createucData(<ContactPhoneIcon />,'CTMS Users', 3),
    createucData(<AccountTreeIcon />,'CRM & CTMS Users', 5),
    createucData(<PeopleRoundedIcon />,'Total Users', 10),
    createucData(<EventBusyRoundedIcon />,'CRM Expiry Date', "31/12/2023"),
    createucData(<CurrencyRupeeRoundedIcon />,'Pay Slips Generated', 10),
    createucData(<FlareIcon />,'Rental Data', 10),
    createucData(<HomeWorkIcon />,'Commercial Properties', 10)
];

const CrmDetail = () => {
  return (
    <Box className="cstbox" > 
        <h5 className="ltitle" style={{marginBottom: '10px'}}>CRM Details</h5>
        <List sx={{height: '360px', overflowY: 'scroll'}}>
            {crmdetails.map((row, index) => {
                return (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar>
                                {row.avatar}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={row.title} secondary={row.desc} />
                    </ListItem>
                )
            })}
        </List>
    </Box>
  )
}

export default CrmDetail;