import Axios from 'axios';
import { API_URL } from '../../App';

const setNewStatus = async(status, color) =>{
  try{
    const response = await Axios.post(`${API_URL}/status/addStatus`,{
      status: status,
      color: color,
    });
    return response;
  }catch(err){
    return err;
  }
}

export {setNewStatus}