import React, { useState, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { getStatus } from "./ApiCalls";

export default function StatusColor ({sendDataToParent}) {
  const [selected, setSelected] = useState(null);
  const[colors, setColors] = useState([]);

  useEffect(()=>{
    getStatus().then((response) => {
      response.data?.map((status) => (
        setColors(arr => [...arr, status.color])
    ))
    }).catch((err) => {
      console.log(err)
    })
  },[])

  const toggle = (color) => {
    console.log(color);
    setSelected(color);
    sendDataToParent(color);
  };

  return (
    <>
      {data?.map((color, id) => (
        colors.includes(color.color_code) === true ? 
          <button
            key={id}
            type="button"
            className="status-color-card"
            style={{ pointerEvents: 'none', cursor: "none", background:color.color_code, opacity: 0.3}}
          >
            <NotInterestedIcon sx={{color: 'red'}} />
          </button>
        : 
          <button
            key={id}
            type="button"
            className="status-color-card"
            style={{ background: color.color_code }}
            onClick={() => toggle(color)}
          >
          {selected === color ? <CheckIcon /> : null}
        </button>
      ))}
    </>
  );
}

const data = [
  {
    color_code: "#006398",
  },
  {
    color_code: "#13a764",
  },
  {
    color_code: "#fdac64",
  },
  {
    color_code: "#f678c3",
  },
  {
    color_code: "#fa4e64",
  },
  {
    color_code: "#43516c",
  },
  {
    color_code: "#3d7a44",
  },
  {
    color_code: "#c47933",
  },
  {
    color_code: "#ab408b",
  },
  {
    color_code: "#ab4040",
  },
  {
    color_code: "#333333",
  },
  {
    color_code: "darkblue",
  },
  {
    color_code: "yellow",
  },
  {
    color_code: "orange",
  },
  {
    color_code: "purple",
  },
];