import React from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts"
import Box from "@mui/material/Box";
import "./Charts.css"
import Fade from '@mui/material/Fade';

const getIntroOfPage = label => {
  if (label === "Facebook") {
    return "Leads report"
  }
  if (label === "Google") {
    return "Leads report"
  }
  if (label === "Housing") {
    return "Leads report"
  }
  if (label === "MagicBrick") {
    return "Leads report"
  }
  if (label === "99 Acres") {
    return "Leads report"
  }
  if (label === "Olx") {
    return "Leads report"
  }
  return ""
}

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} leads : ${payload[0].value}`}</p>
        <p className="intro">{getIntroOfPage(label)}</p>
        {/* <p className="desc"></p> */}
      </div>
    )
  }
  return null
}

export default function Charts(props) {
  return (
    <Fade in direction='up' timeout={800}>
      <Box className="cstbox">
        <h5 className="ltitle">Leads Analytics</h5>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={props.data}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="Leads" barSize={20} fill="#7b809a">
              {props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Fade>
  )
}