import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getStatus = async() => {
  try{
      const response = await Axios.post(`${API_URL}/status/getAllStatus`);
      // const response = await Axios.post('http://localhost:3004/status/getAllStatus');
      return response;
  }catch(err){
      return err;
  }
}

const assignLead = async(lead_id, user, status, condition) => {
    try{
      const response = await Axios.post(`${API_URL}/assign/assignlead`, {
        lid: lead_id,
        assign_uid: user,
        status: status,
        condition: condition
      });
      return response;
    }catch(err){
      return err;
  }
};

const getAssignUser = async() => {
  try{
    const response = await Axios.post(`${API_URL}/users/getUserList`);
    return response;
  }catch(err){
      return err;
  }
};

const deleteandassignLead = async(lead_id, user, status, condition) => {
  try{
    const response = await Axios.post(`${API_URL}/assign/deleteandassignLead`, {
      lid: lead_id,
      assign_uid: user,
      status: status,
      condition: condition 
    });
    return response;
  }catch(err){
      return err;
  }
};

export{assignLead, getAssignUser, getStatus, deleteandassignLead}