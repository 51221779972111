import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const setChangePassword = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/profile/change_userpass`, {
            update_dt: date,
            user_id: data.get('user_id'),    
            current_password: data.get('current_password'),
            new_password: data.get('new_password'),
            confirm_password: data.get('confirm_password'),
        });

        return response;

    }catch(err){

      return err;

    }
}

export {setChangePassword}