import React, { useRef } from "react"; 
import { Link, useParams, useNavigate } from "react-router-dom";
import BottomSlide from "../../Lib/BottomSlide";
import ReactWhatsapp from 'react-whatsapp';
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box"; 
import Grid from "@mui/material/Grid"; 
import IconButton from "@mui/material/IconButton"; 
import Button from "@mui/material/Button"; 
import List from "@mui/material/List"; 
import ListItem from "@mui/material/ListItem"; 
import ListItemText from "@mui/material/ListItemText"; 
import Divider from "@mui/material/Divider"; 
import Typography from "@mui/material/Typography"; 
import Timeline from "@mui/lab/Timeline"; 
import TimelineItem from "@mui/lab/TimelineItem"; 
import TimelineSeparator from "@mui/lab/TimelineSeparator"; 
import TimelineConnector from "@mui/lab/TimelineConnector"; 
import TimelineContent from "@mui/lab/TimelineContent"; 
import TimelineDot from "@mui/lab/TimelineDot"; 
import { timelineItemClasses } from "@mui/lab/TimelineItem"; 
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CallIcon from "@mui/icons-material/Call";
import SmsIcon from "@mui/icons-material/Sms";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined"; 
import DoorBackIcon from "@mui/icons-material/DoorBack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"; 
import dayjs from "dayjs"; 
import {
  getLeadData,
  setStatus,
  setOtherDetails,
  setComment,
  unSetLead,
  setFollowupDateNo
} from "./ApiCalls";
import "./Viewlead.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fade from '@mui/material/Fade';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import Cookies from 'js-cookie';
import Switch from "react-switch";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// Follow up lead modal
const Viewlead = () => {
  
  const [leadDetail, setLeadDetail] = React.useState(""); 
  const [currentStatusColor, setCurrentStatusColor] = React.useState();
  const [followupsdate, setFollowupsDate] = React.useState("No Followup");
  const [other_details, setOther_details] = React.useState();
  const [checkDate, setCheckDate] = React.useState();
  const [comment, setComments] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  // const [time, setTime] = React.useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  const queryParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  
  let currentStatus = useRef(""); 

  let lead_id = useParams(); 

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  React.useEffect(() => {
  
    getLeadData(lead_id).then((response) => {
      // for leads details
      setLeadDetail(response.data[0]);
      console.log(response.data[0]);
      
      // for quick lead status
      response.data[0].status !== "" && response.data[0].status !== null ? 
        (currentStatus.current = response.data[0].status) && (setCurrentStatusColor(response.data[0].color)) 
      : (currentStatus.current = "No status") && (setCurrentStatusColor('#10aabe'));

      // for quick follow up
      if (response.data[0].followup_dt !== "0000-00-00 00:00:00"){
        setFollowupsDate(dayjs(response.data[0].followup_dt).format("MMM DD, YYYY h:mm A"))
        // for check current followup
        if(dayjs(response.data[0].followup_dt).format("MMM-DD-YYYY") === dayjs().format("MMM-DD-YYYY")){
          setCheckDate("Today")
        }else if(dayjs(response.data[0].followup_dt).format("MMM-DD-YYYY") === dayjs().add(1, 'day').format("MMM-DD-YYYY")){
          setCheckDate("Tomorrow")
        }else if(dayjs(response.data[0].followup_dt).format("MMM-DD-YYYY") === dayjs().add(3, 'day').format("MMM-DD-YYYY")){
          setCheckDate("3 days from now")
        }else if(dayjs(response.data[0].followup_dt).format("MMM-DD-YYYY") === dayjs().add(1, 'week').format("MMM-DD-YYYY")){
          setCheckDate("1 week from now")
        }else if(dayjs(response.data[0].followup_dt).format("MMM-DD-YYYY") === dayjs().add(1, 'month').format("MMM-DD-YYYY")){
          setCheckDate("1 months from now")
        }
        // else if(dayjs(response.data[0].followup_dt).format("YYYY-MM-DD HH:mm:ss") < dayjs().format("YYYY-MM-DD HH:mm:ss")){
        //   setCheckDate("missed")
        // }
        else{
          setCheckDate("custom")
        }
      }

      // for quick other details
      setOther_details(response.data[0].other_details);

      // for quick other details
      if(response.data[0].followup === 'Yes'){
        setChecked(true);
      }else{
        setChecked(false);
      }
      
      
      // for quick comments  
      if (response.data[0].comments){
        setComments(response.data[0].comments.split("~"));
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }, [lead_id, followupsdate, other_details]);
  
  // for quick follow up
  const handleCallBack = (fdata) => {
    setFollowupsDate(fdata);
  }

  const updateStatus = () => async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("status") !== null && data.get("status").length !== 0) {
      const splitStatusColor = data.get("status").split('-')
      currentStatus.current = splitStatusColor[0];
      setCurrentStatusColor(splitStatusColor[1])
      setStatus(splitStatusColor[0], leadDetail.l_id, leadDetail.lname, leadDetail.p_mob, leadDetail.pname, leadDetail.followup, leadDetail.followup_dt)
      .then((response) => { 

      })
      .catch((err) => {
        console.log(err);
      });
      notify("Status Updated");
    }
  };

  const updateOtherDetails = () => async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget); 
    const regex = /[a-zA-Z]/; // check alphabets 
    if (data.get("other_details") !== null && regex.test(data.get("other_details")) === true && data.get("other_details").length !== 0) {
      setOther_details(data.get("other_details"))
      setOtherDetails(data.get("other_details"), lead_id)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
      notify("Other Details Updated");
    }
  };

  const updateComment = () => async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget); 
    const regex = /[a-zA-Z]/; // check alphabets 
    if (data.get("comment") !== null && regex.test(data.get("comment")) === true && data.get("comment").length !== 0) {
      // const usercomments = dayjs().format("MMM DD,YYYY h:mm A") + "= " + data.get("comment");
      const usercomments = "By "+Cookies.get('name')+", "+dayjs().format("MMM DD,YYYY h:mm A") + "= " + data.get("comment");

      setComments(arr => [...arr, usercomments]);
      comment.push(usercomments);
      let implode = "";
      if (comment !== null) {
        implode = comment?.map((item) => item).join("~");
      } else {
        implode = comment;
      }
      setComment(implode, leadDetail.l_id, leadDetail.lname, leadDetail.p_mob, leadDetail.pname, leadDetail.followup, leadDetail.followup_dt, leadDetail.status, data.get("comment") ? usercomments : '')
      .then((response) => {
          
      })
      .catch((err) => {
        console.log(err);
      });
      notify("Comments Updated");
    }
  };

  const deleteLead = () => async (event) => {
    event.preventDefault();
    unSetLead(lead_id)
    .then((response) => {
      if(queryParams.has('missedfollowup')){
        navigate("/missedlead");
      }else if(queryParams.has('upcomingfollowup')){
        navigate("/upcominglead");
      }else if(queryParams.has('todayfollowup')){
        navigate("/followup");
      }else{
        navigate("/leads");
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
  
  // Read more
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = React.useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 84) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? " ... read more" : ". show less"}
        </span>
      </p>
    );
  };

  // switch
  
  const handleChecked = nextChecked => {
    setChecked(nextChecked);
    if(nextChecked === false){
      setFollowupDateNo(lead_id.leadId, checked)
      .then((response) => {
        console.log(response)
      })
      .catch((err) => {
        console.log(err);
      });
    }
    console.log(nextChecked)
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={6}
            sx={{
              background: "#f2f2f7",
              position: "fixed",
              width: "100%",
              left: "0",
              zIndex: 1000,
            }}
          >
            <Fade in timeout={800}>
              <Button
                variant="text"
                component={Link} 
                to={queryParams.has('missedfollowup') ? "/missedlead" : 
                    queryParams.has('upcomingfollowup') ? "/upcominglead" :
                    queryParams.has('todayfollowup') ? "/followup" :
                    queryParams.has('searched') ? "/search" :
                    "/leads"
                }
                className="backbutton"
              >
                <KeyboardBackspaceIcon sx={{ color: "#333", fontSize: "26px", py: 0.5 }} />
              </Button>
            </Fade>
          </Grid>
          <Fade in timeout={800} >
            <Grid item xs={6}
              sx={{
                background: "#f2f2f7",
                position: "fixed",
                width: "100%",
                right: "0",
                zIndex: 1000,
              }}
            >
              {/* view lead options */}
              {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ?
              <BottomSlide 
                sliderole="editoption" 
                slideid={lead_id.leadId}
                slidename="options" 
                slidebtnclass="options" 
                slidecloseclass="closeicon"
                slidelisttype={["Add", "Edit", "Delete"]}
                slidedeletefun={deleteLead()}
              />
              : <BottomSlide 
              sliderole="editoption" 
              slideid={lead_id.leadId}
              slidename="options" 
              slidebtnclass="options" 
              slidecloseclass="closeicon"
              slidelisttype={["Add", "Edit"]}
              slidedeletefun={deleteLead()}
            />}
            </Grid>
          </Fade>
          <Grid item xs={12} sx={{ mt: 6 }}>
            <Fade in timeout={800}>
              <h1>{leadDetail.lname}</h1>
            </Fade>
            <Box sx={{ display: "flex", marginLeft: "1.5rem" }}>
              <AutoAwesomeOutlinedIcon sx={{ margin: "auto 5px auto 0px", fontSize: "1rem" }} />
              <Fade in timeout={800}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{ fontSize: "1rem" }}
                >
                  {leadDetail.source} Lead Via {leadDetail.pname}
                </Typography>
              </Fade>
            </Box>
            <Box sx={{ display: "flex", marginLeft: "1.5rem" }}>
              <TimelineOutlinedIcon sx={{ margin: "auto 5px auto 0px", fontSize: "1rem" }} />
              <Fade in timeout={800}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{ fontSize: "1rem", paddingRight: '1rem', wordWrap: 'break-word', overflow: 'auto'}}
                >
                  Lead Source: {leadDetail.source}
                </Typography>
              </Fade>
            </Box>
            {(leadDetail.locality && leadDetail.locality !== "null") ||
              (leadDetail.city && leadDetail.city !== "null") ? (
              <Box sx={{ display: "flex", marginLeft: "1.5rem" }}>
                {/* <Fade in timeout={800}> */}
                  <LocationOnOutlinedIcon sx={{ margin: "auto 5px auto 0px", fontSize: "1rem" }} />
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{ fontSize: "1rem" }}
                  >
                    {leadDetail.locality ? leadDetail.locality + ", " : ""}
                    {leadDetail.city ? leadDetail.city : ""}
                  </Typography>
                {/* </Fade> */}
              </Box>
            ) : (
              null
            )} 
          </Grid>
          <Grid item xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: 1,
              pt: 1,
              pb: 2,
              pl: 1.4,
              borderBottom: "1px solid #0000001f",
            }}
          > 
            <Fade in timeout={800}>
              <IconButton 
                aria-label="call" 
                onClick={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 
                  null : (e) => {
                    e.preventDefault();
                    window.location.href='tel:+'+leadDetail.p_ccode+leadDetail.p_mob;
                  }
                }
              >
                <CallIcon className={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 'licondisabled' : 'licon'} />
              </IconButton>  
            </Fade>
            <Fade in timeout={800}>
              <IconButton 
                aria-label="sms"
                onClick={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ?
                  null : (e) => {
                    e.preventDefault();
                    window.location.href='sms://+'+leadDetail.p_ccode+leadDetail.p_mob+'?body=Hello,';
                  }
                }
              >
                <SmsIcon className={leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 'licondisabled' : "licon"} />
              </IconButton>  
            </Fade>
            <Fade in timeout={800}>
              <IconButton 
                aria-label="mail"
                onClick={leadDetail.p_email === '' || leadDetail.p_email === null ?
                  null : (e) => {
                    e.preventDefault();
                    window.location.href='mailto:'+leadDetail.p_email;
                  }
                }
              >
                <MailIcon className={leadDetail.p_email === '' || leadDetail.p_email === null ? 'licondisabled' : 'licon'} />
              </IconButton>   
            </Fade>
            {leadDetail.p_mob === '' || leadDetail.p_mob === 0 ? 
              <Fade in timeout={800}>
                <IconButton aria-label="whatsapp">
                  <WhatsAppIcon className='licondisabled' />
                </IconButton>   
              </Fade>
              :
              <ReactWhatsapp 
                number={'+'+leadDetail.p_ccode+leadDetail.p_mob} 
                message="Hello," 
                style={{marginTop: '5px', backgroundColor: 'transparent', border: 'none'}}
              >
                <Fade in timeout={800}>
                  <WhatsAppIcon className="licon" />
                </Fade>
              </ReactWhatsapp>
            }
          </Grid>
          <Grid item xs={12}>
           <Fade in timeout={800} >
            <List
              sx={{
                width: "100%",
                height: "auto",
                p: 0,
                pb: 2,
                bgcolor: "background.paper",
              }}
            >
              {/* view lead assigned to */}
              <BottomSlide 
                sliderole="assignoption"
                slideid={lead_id.leadId}
                slidename="assigned to"
                slidebtnclass="assignedstatus"
                slidecloseclass="closeicon"
                slidelisttype={["No user found."]}
                // slidelisttype={["User 1", "User 2", "User 3", "User 4", "User 5"]}
              />

              <ListItem
                button 
              >
                <ListItemText  
                  className="viewleadfollowup"
                  sx={{ pl: 1}}
                  primary='Want to set follow Up?' 
                />
                <Switch
                  onChange={handleChecked}
                  checked={checked}
                  className="react-switch"
                />
              </ListItem>
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />

              {checked && checked === true ?   
              <>          
              <BottomSlide
                sliderole="followup"
                slideid={lead_id.leadId}
                slidename="follow up"
                slidesubname={followupsdate}
                slidetxtclass="viewleadfollowup"
                slidecloseclass="viewleadcloseicon"
                slidecustomtimeclass="viewlead-time"
                slidecustomdateclass="viewlead-date"
                slidelisttype={["Today", "Tomorrow", "3 days from now", "1 week from now", "1 months from now", "Custom"]}
                slideupdatefollowup={handleCallBack}
                slideSelectedDate={checkDate}
                status={currentStatus}
                followupstatus = {checked}
              />
              <Divider variant="inset" component="li" sx={{ ml: 0 }} /> </>  : null }
              
              {/* view lead status */}
              <BottomSlide
                sliderole="leadstatus"
                slideid={lead_id.leadId}
                slidename="Status" 
                slidecreatetitle="Create New"
                slidetxtclass="viewleadstatus"
                slidecloseclass="viewleadcloseicon" 
                slidecreateclass="createstatus"
                slidestatusbtnclass="lbutton"
                slidestatuscolorclass="status-color"
                slidestatuslableclass="statuslable"
                slidestatusbgcolor={currentStatusColor}
                slidecurrentstatus={currentStatus.current}
                slideupdatestatus={updateStatus()}
              />
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              
              {/* view lead others details */}
              <BottomSlide
                sliderole="otherdetail"
                slideid={lead_id.leadId}
                slidename="other details"
                slidesubname={leadDetail.other_details !== '' && leadDetail.other_details !== null ? other_details : "add other details regarding project or client requirements"}
                slidetxtclass="viewleadotherdetail"
                slidecloseclass="closeicon"
                slidetextclass="addotherdetails"
                slideotherdetails={updateOtherDetails()}
              />
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              
              {/* leads details */}
              <Box sx={{ display: "flex" }}>
                <Fade in timeout={800}>
                  <ListItem>
                    <ListItemText
                      className="viewleadtitle"
                      sx={{ pl: 1 }}
                      primary="Category"
                      secondary={
                        leadDetail.pcategory !== " " && leadDetail.pcategory !== "null" ? leadDetail.pcategory : "-"
                      }
                    />
                  </ListItem>
                </Fade>
                <Fade in timeout={800}>
                  <ListItem>
                    <ListItemText
                      className="viewleadtitle"
                      sx={{ pl: 1 }}
                      primary="Apt. Type"
                      secondary={
                        leadDetail.ptype !== " " && leadDetail.ptype !== "null" ? leadDetail.ptype : "-"
                      }
                    />
                  </ListItem>
                </Fade>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Fade in timeout={800}>
                  <ListItem>
                    <ListItemText
                      className="viewleadtitle"
                      sx={{ pl: 1 }}
                      primary="Carpet Area"
                      secondary={
                        <React.Fragment>
                          Min: {leadDetail.min_area} <small>{leadDetail.area_unit}</small>
                          <br />
                          Max: {leadDetail.max_area} <small>{leadDetail.area_unit}</small>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Fade>
                <Fade in timeout={800}>
                  <ListItem>
                    <ListItemText
                      className="viewleadtitle"
                      sx={{ pl: 1 }}
                      primary="Pricing"
                      secondary={
                        <React.Fragment>
                          Min: {leadDetail.min_price}
                          <br />
                          Max: {leadDetail.max_price}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Fade>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Fade in timeout={800}>
                  <ListItem>
                    <ListItemText
                      className="viewleadtitle"
                      sx={{ pl: 1 }}
                      primary="Service Type"
                      secondary={
                        leadDetail.service_type !== "" && leadDetail.service_type !== "null" ? leadDetail.service_type : "-"
                      }
                    />
                  </ListItem>
                </Fade>
                <Fade in timeout={800}>
                  <ListItem>
                    <ListItemText
                      className="viewleadtitle"
                      sx={{ pl: 1 }}
                      primary="Configuration"
                      secondary={
                        leadDetail.pconfiguration !== "" && leadDetail.pconfiguration !== "null" ? leadDetail.pconfiguration : "-"
                      }
                    />
                  </ListItem>
                </Fade>
              </Box>
              <Divider variant="inset" component="li" sx={{ ml: 0 }} />
              {/* ends */}
              
              {/* TimeLine left aligned */}
              <Fade in timeout={800}>
                <ListItem>
                  <ListItemText 
                    className="viewleadtitle" 
                    sx={{ pl: 1 }} 
                    primary="Timeline" 
                  />
                </ListItem>
              </Fade>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <Fade in timeout={800}>
                  <TimelineItem sx={{mt: '-1rem', ml: 1}}>
                    <TimelineSeparator>
                      {/* view lead timeline */}
                      <BottomSlide
                        sliderole="timelines"
                        slideid={lead_id.leadId}
                        slidename="add message"
                        slidecloseclass="closeicon"
                        slidemessageclass="addmessagebox"
                        slidetimelineplus="timelinedot"
                        slideaddtimeline={updateComment()}
                      />
                      <TimelineConnector /> 
                    </TimelineSeparator>
                    <TimelineContent sx={{ pl: 1 }}>
                      <BottomSlide
                        sliderole="timelines"
                        slideid={lead_id.leadId}
                        slidename="add comments"
                        slidecloseclass="closeicon"
                        slidemessageclass="addmessagebox"
                        slidetimelinetext="add activity"
                        slideaddtimeline={updateComment()}
                      />
                    </TimelineContent>
                  </TimelineItem> 
                </Fade>
                {/* dynamic comments */}
                {comment ?
                  comment?.slice(0).reverse().map((c, i) => ( 
                    <Fade in timeout={800} key={i}>
                      <TimelineItem key={i} sx={{ml: 1}}>
                        <TimelineSeparator>
                          <TimelineDot sx={{ background: "#c863ff" }}>
                            <SmsOutlinedIcon
                              color="white"
                              sx={{ width: "1em", fontSize: "1em" }}
                            />
                          </TimelineDot>
                          <TimelineConnector /> 
                        </TimelineSeparator> 
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              variant="h6"
                              component="span"
                              sx={{
                                fontSize: ".9rem",
                                fontWeight: "500",
                                marginRight: "0.5rem",
                                letterSpacing: "0.05em",
                              }}
                            >
                              {c.includes("=") ? c.split("=")[0] : c} 
                            </Typography> 
                          </Box>
                          {/* <Typography
                            variant="h3"
                            component="span"
                            sx={{
                              fontSize: "0.95rem",
                              fontWeight: "500",
                            }}
                          >
                            Message
                          </Typography> */}
                          {c.includes("=") ?     
                          <Box sx={{ display: "flex" }} color="text.secondary">
                            <Typography
                              variant="h6"
                              component="span"
                              sx={{
                                fontSize: "0.8rem",
                                margin: "auto 0",
                              }} 
                            > 
                              {c.split("=")[1].length <= 84 ? 
                                c.split("=")[1] 
                              :
                                <ReadMore>
                                  {c.split("=")[1]}
                                </ReadMore>
                              }
                            </Typography>
                          </Box> : ''}
                      
                        </TimelineContent>
                    </TimelineItem>
                  </Fade>
                  ))
                : null}
              </Timeline>
              {/* ends */}
            </List>
           </Fade>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Viewlead;