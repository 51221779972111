import Axios from 'axios';
import { API_URL } from '../../App';

const getLeads = async(LIMIT, page) => {
    try{
        const response = await Axios.post(`${API_URL}/followups/getUpcomingLeads`,{limit: LIMIT, page: page});
        // const response = await Axios.post('http://localhost:3004/followups/getUpcomingLeads');
        return {response, nextPage: page + 10 };
    }catch(err){
        return err;
    }
}

const getUpcomingLeadCount = async() => {
    try{
        const response = await Axios.post(`${API_URL}/followups/getUpcomingLeadCount`);
        // const response = await Axios.post('http://localhost:3004/followups/getMissedLeads');
        return response.data[0].leadcount;
    }catch(err){
        return err;
    }
}  

export {getLeads, getUpcomingLeadCount}