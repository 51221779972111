import * as React from 'react';
import Box from '@mui/material/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';  
import Chip from '@mui/material/Chip';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';

function createuaData(color, avatar, user, status) {
  return {
    color,
    avatar,
    user,
    status,
  };
}
const userattenance = [
    createuaData("success", <TaskAltIcon />, 'Shaziya', "Online"),
    createuaData("error", <CancelIcon />, 'Neha Shaikh', "Offline"),
    createuaData("error", <CancelIcon />, 'Mursaleen', "Offline"),
    createuaData("success", <TaskAltIcon />, 'Master', "Online"),
    createuaData("error", <CancelIcon />, 'Majid Khan', "Offline"),
    createuaData("success", <TaskAltIcon />, 'Mahek Propmasterd', "Online"),
    createuaData("success", <TaskAltIcon />, 'Mahek', "Online"),
    createuaData("success", <TaskAltIcon />, 'Arif Shaikh', "Online")
];

export default function Attendance() {
  return (
    <Box className="cstbox" > 
        <h5 className="ltitle" style={{marginBottom: '10px'}}>Todays Attendance</h5>
        <Timeline sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            height: '360px', 
            overflowY: 'scroll'
        }} >
            {userattenance.map((row, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot color={row.color} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{row.user}</TimelineContent>
                    <h6 style={{float: 'right'}}>
                      <Chip
                        color={row.color}
                        icon={row.avatar}
                        label={row.status}
                        size="small"
                      />
                    </h6>
                  </TimelineItem>
                )
            })}
        </Timeline>
    </Box>
  );
}