import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../../../DesktopApp'; 

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

const getAllLeadStatusData = async() =>{
    try{
        const response = await Axios.post(`${API_URL}/desktop/leadstatus/getAllLeadStatusData`);
        // const response = await Axios.post('http://localhost:3004/leadstatus/getAllLeadStatusData');
        return response;
    
    }catch(err){
    
        return err;
    
    }
} 

const setAddLeadStatus = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/leadstatus/addleadstatus`, {
            // const response = await Axios.post('http://localhost:3004/leadstatus/addleadstatus', {
            create_dt: date,
            update_dt: date,
            status: data.get('lead_status'),
            color: data.get('status_color'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setDeleteLeadStatus = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/leadstatus/deleteleadstatus`, {
            // const response = await Axios.post('http://localhost:3004/leadstatus/deleteleadstatus', {
            ls_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}

const getEditLeadStatus = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/leadstatus/editleadstatus`, {
            // const response = await Axios.post('http://localhost:3004/leadstatus/editleadstatus', {
            ls_id: data,
        });

        return response;

    }catch(err){

      return err;

    }
}


const setUpdateLeadStatus = async(data) =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/leadstatus/updateleadstatus`, {
            // const response = await Axios.post('http://localhost:3004/leadstatus/updateleadstatus', {
            update_dt: date,    
            ls_id: data.get('ls_id'),
            status: data.get('lead_status'),
            color: data.get('status_color'),
        });

        return response;

    }catch(err){

      return err;

    }
}

const setImportLeadStatus = async() =>{
  
    try{
        const response = await Axios.post(`${API_URL}/desktop/leadstatus/importleadstatus`, {
            // const response = await Axios.post('http://localhost:3004/leadstatus/importleadstatus', {
        });

        return response;

    }catch(err){

      return err;

    }
}

export {getAllLeadStatusData, setAddLeadStatus, setDeleteLeadStatus, getEditLeadStatus, setUpdateLeadStatus, setImportLeadStatus}