import React, { useEffect, useState } from "react";
import {Country, State, City} from "../../Data/CountryStateCity"
// import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  ServiceType,
  Locality,
  SubLocality,
  PropertyArea,
  PropertyPrice,
  PropertyType,
  ResidentialPropertyCategory,
  CommercialPropertyCategory,
  FollowUp,
  Ccode,
  Blank,
} from '../../Data/Data';
import {
  CustomSelectFiled,
  CustomInputField,
  CustomFormLabel,
  CustomFormGroup,
  CustomTextareaField,
  CustomMobileFiled,
  CustomMultiSelectFiled
} from '../../Components/Common';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import PropTypes from 'prop-types';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getAllSourceList, getAllBrokerList, getAllLeadStatusList, getAllProjectList, getAllConfigurationList, setLead, getLead, getLeadAssignUserList, getLeadAssignUserData } from "./ApiCalls";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';

// 
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EditLead = () => {

  const navigate = useNavigate();
  const lead_id = useParams(); 
  const lastlocation = useParams(); 
  const [leadsDetails, setLeadsDetails] = useState('');
  const [name, setName] = useState('');

  const [source_type, setSourceType] = useState("Direct");
  const [follow_up, setFollowUp] = useState();
  const [leadStatus, setLeadStaus] = useState();
  const [ptype, setPtype] = useState();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [sourcelist, setAllSourceList] = React.useState([]);
  const [brokerlist, setAllBrokerList] = React.useState([]);
  const [leadstatuslist, setAllLeadStatusList] = React.useState([]);
  const [projectlist, setAllProjectList] = React.useState([]);
  const [configurationlist, setAllConfigurationList] = React.useState([]);
  const [configuration, setAllConfiguration] = React.useState([]);
  const [leadAssignUserList, setLeadAssignUserList] = React.useState([]);

    // tabs
    const [myTabs, setMyTabs] = React.useState('default');
    const handleTabChange = (event, newValue) => {
      setLeadsDetails('')   
      console.log(leadsDetails)   
      // if(newValue === 'default'){
      //   leadDetailData()
      // }else{
      //   let countries = Country.getAllCountries();
      //   let states = State?.getStatesOfCountry(selectedCountry?.isoCode);
      //   let cities = City.getCitiesOfState(selectedState?.countryCode,selectedState?.isoCode);
      //   getLeadAssignUserData(newValue, lead_id).then((response)=>{
      //     console.log(response)
      //     setLeadsDetails(response.data[0]);
      //     setSourceType(response.data[0].source_type ? response.data[0].source_type : null); 
    
      //     if(!selectedCountry && response.data[0].country){
      //       countries.map((cname, key) => (
      //         cname.name === response.data[0].country ?  
      //           setSelectedCountry(Country.getAllCountries()[key])
      //         : null 
      //       ))
      //     } 
      //     if(!selectedState && response.data[0].state){ 
      //       states.map((sname, key) => (
      //         sname.name === response.data[0].state ?  
      //           setSelectedState(State?.getStatesOfCountry(selectedCountry?.isoCode)[key])
      //         : null 
      //       ))
      //     }
      //     if(!selectedCity && response.data[0].city){
      //       cities.map((cityname, key) => (
      //         cityname.name === response.data[0].city ?  
      //           setSelectedCity(City.getCitiesOfState(selectedState?.countryCode,selectedState?.isoCode)[key])
      //         : null 
      //       ))
      //     }
    
      //     // 
      //     setPtype(response.data[0].ptype);
      //   }).catch(err => {
      //     console.log(err)
      //   })
      // }
      setMyTabs(newValue);
      // console.log(leadsDetails)
    };

  useEffect(() => {

    getLeadAssignUserList(lead_id).then(response => {
      setLeadAssignUserList(response.data)
    }).catch(err => {
       console.log(err)
    })

    getAllSourceList().then(response => {
      if (response.data === "No Data Found") {
        setAllSourceList(response.data.data)
      } else {
        setAllSourceList(response.data.map((sourcelist) => ({ value: sourcelist.source, label: sourcelist.source })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllBrokerList().then(response => {
      if (response.data === "No Data Found") {
        setAllBrokerList(response.data.data)
      } else {
        setAllBrokerList(response.data.map((brokerlist) => ({ value: brokerlist.brk_id, label: brokerlist.name })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllLeadStatusList().then(response => {
      if (response.data === "No Data Found") {
        setAllLeadStatusList(response.data.data)
      } else {
        setAllLeadStatusList(response.data.map((leadstatuslist) => ({ value: leadstatuslist.status, label: leadstatuslist.status })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllProjectList().then(response => {
      if (response.data === "No Data Found") {
        setAllProjectList(response.data.data)
      } else {
        setAllProjectList(response.data.map((projectlist) => ({ value: projectlist.pname, label: projectlist.pname })))
      }
    }).catch(err => {
      console.log(err);
    });

    getAllConfigurationList(ptype).then(response => {
      if (response.data === "No Data Found") {
        setAllConfigurationList(response.data.data)
      } else {
        setAllConfigurationList(response.data.map((configurationlist) => ({ value: configurationlist.configuration, label: configurationlist.configuration })))
      }
    }).catch(err => {
      console.log(err);
    });

    leadDetailData()
    
    
  }, [lead_id, selectedCountry, selectedState, selectedCity]);

  const notify = (msg) => toast(msg, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
});

  // 
  function filterCity(selectedState){
    let myCity = City.filter(City => selectedState.countryCode.indexOf(City.countryCode) !== -1);
    return myCity.filter(citynew => selectedState.isoCode.indexOf(citynew.stateCode) !== -1);
  }

  const leadDetailData = () => {
      // 
      let countries = Country;
      let states = selectedCountry !== null ? State.filter(State => selectedCountry.isoCode.indexOf(State.countryCode) !== -1) : [];
      let cities = selectedState !== null && selectedState.length !== 0 ? filterCity(selectedState) : [];
      getLead(lead_id)
      .then((response) => { 
        setLeadsDetails(response.data[0]);
        setSourceType(response.data[0].source_type ? response.data[0].source_type : null); 
        setFollowUp(response.data[0].followup);
        if(!selectedCountry && response.data[0].country){
          countries.map((cname, key) => (
            cname.name === response.data[0].country ?  
              setSelectedCountry(Country[key])
            : null 
          ))
        } 
        if(!selectedState && response.data[0].state){ 
          states.map((sname, key) => (
            sname.name === response.data[0].state ?  
            setSelectedState(State.filter(State => selectedCountry.isoCode.indexOf(State.countryCode) !== -1)[key])
            : null 
          ))
        }
        if(!selectedCity && response.data[0].city){
          cities.map((cityname, key) => (
            cityname.name === response.data[0].city ?  
            setSelectedCity(filterCity(selectedState)[key])
            : null 
          ))
        }
  
        // 
        setPtype(response.data[0].ptype);
      })
      .catch((err) => {
        console.log(err);
      }); 

  }
    
  const handleChangePtype = (event) => {
    setPtype(event.value);
    getAllConfigurationList(event.value).then(response => {
      if (response.data === "No Data Found") {
        setAllConfigurationList(response.data.data)
      } else {
        setAllConfigurationList(response.data.map((configurationlist) => ({ value: configurationlist.configuration, label: configurationlist.configuration })))
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const handleEditLead = (event) => {
    event.preventDefault(); 
    const data = new FormData(event.currentTarget); 
    if(parseInt(data.get("lmobile")) === 0){
      notify("Please fill out required fields.")
    }else{
      setLead(data, lead_id)
      .then((response) => { 
        notify("Lead is Updated.")
        console.log(data.get('pname'))
        setTimeout(() => {  
          lastlocation.lastlocation === 'upcomingleads' ?
            navigate('/upcomingleads')
          : 
          lastlocation.lastlocation === 'missedleads' ?
            navigate('/missedleads')
          : 
          lastlocation.lastlocation === 'todaysleads' ?
            navigate('/todaysleads')
          :  
          lastlocation.lastlocation === 'assignleads' ?
            navigate('/assignleads')
          :
          lastlocation.lastlocation === 'nonassignleads' ?
            navigate('/nonassignleads')
          :  
          lastlocation.lastlocation ?
            navigate('/leads-by-status/'+lastlocation.lastlocation+'/')
          :
            navigate("/total-leads/mypage="+lead_id.pageId.split('=')[1]);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const handleChangeStatus = (event) => {
    if(event !== null){
      setLeadStaus(event.value);
    }
  };

  return (
    <>
      <Breadcrumb PageName="Edit Lead" BackButton={[true, 'Back',
        lastlocation.lastlocation === 'upcomingleads' ? '/upcomingleads' :
        lastlocation.lastlocation === 'missedleads' ? '/missedleads' :
        lastlocation.lastlocation === 'todaysleads' ? '/todaysleads' :
        lastlocation.lastlocation === 'assignleads' ? '/assignleads' :
        lastlocation.lastlocation === 'nonassignleads' ? '/nonassignleads' :
        lastlocation.lastlocation ? '/leads-by-status/'+lastlocation.lastlocation+'/' : '/total-leads/mypage='+lead_id.pageId.split('=')[1], 
      <ArrowBackIosOutlinedIcon />]} />

      <Box component="form" autoComplete="off" onSubmit={handleEditLead} method="post">
        
        <Box className="custom-card">
        {/* {leadAssignUserList !== 'No Data Found' ?   
            <Grid item xs={6} md={6} style={{ padding: '0%'}}>
              <Box sx={{borderBottom: 1, borderColor: 'divider' , paddingTop: '0.1rem'}}>
                <Tabs 
                  value={myTabs} 
                  onChange={handleTabChange} 
                  aria-label="scrollable auto tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={'Default(Master/Admin)'} {...a11yProps(0)} value={'default'} />
                  {leadAssignUserList.map((users)=><Tab label={users.username+'('+users.urole+')'} {...a11yProps(0)} value={users.assignto_id} />)}
                </Tabs>
              </Box>
            </Grid> : ''}
            {leadAssignUserList !== 'No Data Found' ?
            <Box p={2} >
             <Grid container spacing={2} mb={2} style={{paddingTop: '0%'}}>
              <Grid item md={12}>
                <Alert severity="info" sx={{borderRadius: '10px'}}>
                  <AlertTitle>Info.,</AlertTitle>
                  Client Requirement Data is - <strong>Loading!</strong>
                </Alert>
              </Grid> 
            </Grid>
           </Box> 
           : ''  } */}
          <Typography variant="h4" className="custom-card-head">
            Client Personal Details :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <CustomFormLabel formlabel="Lead Type" star="*" />
              </Grid>
              {/* <input type="text" name="user" value={myTabs}/> */}
              <Grid item xs={6} md={10}>
                <RadioGroup
                  row
                  name="source_type"
                  value={source_type}
                  onChange={(event) => setSourceType(event.target.value)}
                >
                  <FormControlLabel
                    value="Direct"
                    control={<Radio />}
                    label="Direct"
                    disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                  />
                  <FormControlLabel
                    value="Broker"
                    control={<Radio />}
                    label="Broker"
                    disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                  />
                  <FormControlLabel
                    value="Reference"
                    control={<Radio />}
                    label="Reference"
                    disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>

              {source_type === "Direct" ? (
                <CustomFormGroup
                  formlabel="Source"
                  star="*"
                  FormField={
                    <CustomSelectFiled 
                      name="source" 
                      required={true}
                      defaultVal={leadsDetails.source}
                      options={sourcelist} 
                      disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                      inputrole="Edit Lead"
                    />
                  }
                />
              ) : null}

              {source_type === "Broker" ? (
                <CustomFormGroup
                  formlabel="Broker Name"
                  star="*"
                  FormField={
                    <CustomSelectFiled
                      name = "brk_id"
                      required = {true} 
                      options={brokerlist} 
                      disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                      inputrole="Edit Lead"
                    />
                  }
                />
              ) : null}

              {source_type === "Reference" ? (
                <>
                  <CustomFormGroup
                    formlabel="Reference Name"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="ref_name"
                        required={true}
                        placeholder="Enter Reference Name"
                        defaultVal={leadsDetails.ref_name}
                        disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                      />
                    }
                  />
                  <CustomMobileFiled
                    formlabel="Ref. Mobile No"
                    star="*"
                    Inputname={['ref_ccode', 'ref_number']}
                    InputRequired={true}
                    defaultVal={leadsDetails.ref_ccode+' '+leadsDetails.ref_mob}
                    disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                  />
                  <CustomFormGroup
                    formlabel="Reference Email"
                    FormField={
                      <CustomInputField
                        type="email"
                        name="ref_email"
                        placeholder="Enter Reference Email"
                        defaultVal={leadsDetails.ref_email}
                        disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                      />
                    }
                  />
                </>
              ) : null}
              
              <CustomFormGroup
                formlabel="Lead Name"
                star="*"
                FormField={
                  <CustomInputField
                    type="text"
                    name="lname"
                    placeholder="Enter Lead Name"
                    required={true}
                    defaultVal={leadsDetails.lname}
                    disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                  />
                }
              />

              <CustomMobileFiled
                formlabel="Mobile No."
                star="*"
                type="text"
                placeholder="Mobile No."
                Inputname={['lccode', 'lmobile']}
                options={Ccode}
                defaultVal={leadsDetails.p_ccode+' '+leadsDetails.p_mob}
                disabled={true}
                // disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
              />
              <CustomMobileFiled
                formlabel="Alternate Mobile No."
                type="number"
                placeholder="Alternate Mobile No."
                Inputname={['sccode', 'smobile']}
                options={Ccode}
                defaultVal={leadsDetails.s_ccode+' '+leadsDetails.s_mob}
              />
              <CustomFormGroup
                formlabel="Email Id."
                star="*"
                FormField={
                  <CustomInputField
                    type="email"
                    name="lemail"
                    placeholder="Enter Email Id."
                    required={true} 
                    defaultVal={leadsDetails.p_email}
                    disabled={Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? false : true}
                  />
                }
              />
              <CustomFormGroup
                formlabel="Alternate Email Id."
                FormField={
                  <CustomInputField
                    type="email"
                    name="semail"
                    placeholder="Enter Alternate Email Id."
                    defaultVal={leadsDetails.s_email}
                  />
                }
              />
            </Grid>
          </Box>
        </Box>

        {/* <Box className="custom-card" mt={3}>
          <Typography variant="h4" className="custom-card-head">
            Client Status :-
          </Typography>
          <Box p={2}> */}
            {/* <Grid container spacing={2} mb={2}> */}
              {/* <CustomFormGroup
                formlabel="Lead Status"
                star="*"
                FormField={
                  // <CustomSelectFiled 
                  //   name="lead_status" 
                  //   required={true}
                  //   defaultVal = {leadsDetails.status}
                  //   options={leadstatuslist} 
                  // />
                  <CustomSelectFiled 
                    name="lead_status" 
                    required={true} 
                    options={leadstatuslist}  
                    inputrole="Edit Lead"
                    defaultVal={leadsDetails.status}
                    onChange={handleChangeStatus} 
                  />
                }
              /> */}
              
{/* 
              <CustomFormGroup
                formlabel="Follow Up"
                star="*"
                FormField={
                  <CustomSelectFiled
                    name="followup" 
                    required={true}
                    options={FollowUp}
                    onChange={(event) => setFollowUp(event.value)}
                    defaultVal={follow_up}
                  />
                }
              /> */}
              {/* {follow_up === "Yes" ? (
                <>
                  <CustomFormGroup
                    formlabel="Follow Up Date"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="datetime-local"
                        name="followup_dt"
                        required={true}
                        defaultVal={leadsDetails.followup_dt}
                      />
                    }
              />
                </>
              ) : null} */}

              {/* <CustomFormGroup
                formlabel="Project Name"
                FormField={
                  <CustomMultiSelectFiled
                    name="pname" 
                    defaultValue={leadsDetails.pname}
                    options={projectlist} 
                  />
                }
              /> */}
            {/* </Grid> */}

            {/* <Grid container spacing={2} my={.5}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Add Comment <span className="required-label"></span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <CustomTextareaField
                  name="comments"
                  placeholder="Enter Comments ...."
                />
              </Grid>
            </Grid> */}
          {/* </Box>
        </Box> */}

        <Box className="custom-card" mt={3}>
          <Typography variant="h4" className="custom-card-head">
            Client Requirement Details :-
          </Typography>
          <Box p={2}>
            <Grid container spacing={2} mb={2}>

            <CustomFormGroup
                formlabel="Project Name"
                FormField={
                  <CustomMultiSelectFiled
                    name="pname" 
                    defaultValue={leadsDetails.pname}
                    options={projectlist} 
                  />
                }
              />

              <CustomFormGroup
                formlabel="Service Type"
                FormField={
                  <CustomMultiSelectFiled 
                    name="service_type"
                    options={ServiceType} 
                    defaultValue={leadsDetails.service_type}
                  />
                }
              />

              <CustomFormGroup
                formlabel="Property Type"
                FormField={
                  <CustomSelectFiled
                    name="ptype"
                    inputrole="Edit Lead"
                    onChange={handleChangePtype} 
                    options={PropertyType}
                    defaultVal={ptype}
                  />
                }
              />

              <CustomFormGroup
                formlabel="Property Category"
                FormField={
                  <CustomSelectFiled 
                    name="pcategory"
                    options={ptype === "Residential" ? ResidentialPropertyCategory : ptype === "Commercial" ? CommercialPropertyCategory : Blank} 
                    inputrole="Edit Lead"
                    defaultVal={leadsDetails.pcategory} 
                  />
                }
              />
              <CustomFormGroup
                formlabel="Configuration"
                FormField={
                  <CustomMultiSelectFiled
                    name="pconfiguration"
                    options={configurationlist} 
                    defaultValue={leadsDetails.pconfiguration}
                  />
                }
              />
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2} className="custom-from-group">
                    <CustomFormLabel formlabel="Property Area" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="min_area"
                      placeholder="Enter Minimum Area"
                      defaultVal={leadsDetails.min_area}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="max_area"
                      placeholder="Enter Maximum Area"
                      defaultVal={leadsDetails.max_area}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CustomSelectFiled 
                      name = "units_type"
                      options={PropertyArea} 
                      placeholder='Select Unit' 
                      inputrole="Edit Lead"
                      defaultVal={leadsDetails.area_unit}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2} className="custom-from-group">
                    <CustomFormLabel formlabel="Property Price" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="min_price"
                      placeholder="Enter Minimum Price"
                      defaultVal={leadsDetails.min_price}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <CustomInputField
                      type="number"
                      name="max_price"
                      placeholder="Enter Maximum Price"
                      defaultVal={leadsDetails.max_price}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CustomSelectFiled
                      name="currency_type"
                      options={PropertyPrice}
                      placeholder='Select Currency' 
                      inputrole="Edit Lead"
                      defaultVal={leadsDetails.price_unit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Country
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Select
                  name = "country"
                  options={Country}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry} 
                  onChange={
                    (item) => {
                      setSelectedCountry(item);
                      setSelectedState([]);
                      setSelectedCity([]);
                    }
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  State
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Select
                  name = "state"
                  options={selectedCountry !== null ? State.filter(State => selectedCountry.isoCode.indexOf(State.countryCode) !== -1) : []}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                    setSelectedCity([]);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  City
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Select
                    name = "city"
                    options={selectedState !== null && selectedState.length !== 0 ? 
                      filterCity(selectedState)
                    : []}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
              </Grid>

              <CustomFormGroup
                formlabel="Locality" 
                FormField={
                  <CustomInputField
                    type="text"
                    name="locality"
                    placeholder="Enter Locality" 
                    defaultVal={leadsDetails.locality}
                  />
                }
              />

              {/* <CustomFormGroup
                formlabel="Locality"
                FormField={
                  <CustomMultiSelectFiled 
                    name = "locality"
                    options={Locality} 
                  />
                }
              /> */}

              {/* <CustomFormGroup
                formlabel="sub Locality"
                FormField={
                  <CustomMultiSelectFiled
                    name = "sub_locality"
                    options={SubLocality} 
                  />
                }
              /> */}
            </Grid>
            <Grid container spacing={2} my={1}>
              <Grid item xs={6} md={2} className="custom-from-group">
                <Typography variant="h6" className="custom-form-label">
                  Other Details <span className="required-label"></span>
                </Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <CustomTextareaField
                  name="other_details"
                  placeholder="Enter Other Details ...."
                  defaultVal={leadsDetails.other_details}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} my={1}>
              <Grid item xs={6} md={2}></Grid>
              <Grid item xs={6} md={10}>
                <Button variant="contained" type="submit" >
                  Save Lead
                </Button>
              </Grid>
            </Grid>
            
          </Box>
        </Box>

      </Box>

      <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

    </>
  );
};

export default EditLead;