import React from "react";
import Button from "@mui/material/Button";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import { Box, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { CustomInputField, CustomSelectFiled, CustomFormGroup } from "../../../Components/Common";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import Datatable from "../../../Components/Datatable/Datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllGeolocationData, getAllCountryList, getAllStateList, getAllCityList, getAllLocalityList, setAddCountry, setAddState, setAddCity, setAddLocality, setAddSubLocality, setDeleteSubLocality, getEditSubLocality, getEditLocality, setUpdateSubLocality, setUpdateLocality, setImportConfiguration } from "./ApiCalls";
// import { useNavigate } from "react-router-dom";

const Geolocation = () => {

    // const navigate = useNavigate();
    const [geolocationdata, setAllGeolocationData] = React.useState([]);
    const [countrylist, setAllCountryList] = React.useState([]);
    const [statelist, setAllStateList] = React.useState([]);
    const [citylist, setAllCityList] = React.useState([]);
    const [localitylist, setAllLocalityList] = React.useState([]);
    const [country, setCountry] = React.useState();
    const [countryid, setCountryID] = React.useState();
    const [statec, setStatec] = React.useState();
    const [statecid, setStatecID] = React.useState();
    const [city, setCity] = React.useState();
    const [cityid, setCityID] = React.useState();
    const [locality, setLocality] = React.useState();
    const [localityid, setLocalityID] = React.useState();
    const [editsublocality, setEditSubLocality] = React.useState();
    const [editsublocality_id, setEditSubLocalityID] = React.useState();
    const [editlocality, setEditLocality] = React.useState();
    const [editlocality_id, setEditLocalityID] = React.useState();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [myPage, setMyPage] = React.useState(0);

    React.useEffect(() => {
        getAllGeolocationData().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllGeolocationData(response.data.data)
            } else {
                setAllGeolocationData(response.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleDeleteSubLocality = (sublocality_id) => (event) => {
        event.preventDefault();
        if (window.confirm('Are Your Sure! Delete the Sub Locality?')) {
            // handleOpen();
            const data = sublocality_id;
            setDeleteSubLocality(data)
                .then((response) => {
                    console.log(response);
                    // navigate("/configuration");
                    if (response.data === "Sub Locality deleted") {
                        // handleClose();
                        getAllGeolocationData().then(response => {
                            console.log(response);
                            if (response.data === "No Data Found") {
                                setAllGeolocationData(response.data.data)
                            } else {
                                setAllGeolocationData(response.data)
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                        toast.success("Sub Locality Deleted Successfully");
                    } else {
                        toast.error("Failed! Sub Locality Not Deleted");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleUpdateSubLocality = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("sublocality_id"));
        // alert(data.get("country_id"));
        // alert(data.get("state_id"));
        // alert(data.get("city_id"));
        // alert(data.get("locality_id"));
        // alert(data.get("sub_locality"));
        setUpdateSubLocality(data)
            .then((response) => {
                console.log(response);
                // navigate("/configuration");
                if (response.data === "Sub Locality Updated") {
                    handleClose();
                    getAllGeolocationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllGeolocationData(response.data.data)
                        } else {
                            setAllGeolocationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    toast.success("Sub Location Updated Successfully");
                } else {
                    toast.error("Failed! Sub Locality with same name already exist");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdateLocality = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("locality_id"));
        // alert(data.get("country_id"));
        // alert(data.get("state_id"));
        // alert(data.get("city_id"));
        // alert(data.get("locality"));
        setUpdateLocality(data)
            .then((response) => {
                console.log(response);
                // navigate("/configuration");
                if (response.data === "Locality Updated") {
                    handleClose();
                    getAllGeolocationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllGeolocationData(response.data.data)
                        } else {
                            setAllGeolocationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    toast.success("Location Updated Successfully");
                } else {
                    toast.error("Failed! Locality with same name already exist");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAddCountry = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("country_code"));
        // alert(data.get("country"));
        setAddCountry(data)
            .then((response) => {
                console.log(response);
                if (response.data === "Country Added") {
                    handleClose();
                    getAllGeolocationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllGeolocationData(response.data.data)
                        } else {
                            setAllGeolocationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    toast.success("Country Added Successfully");
                } else {
                    toast.error("Failed! Country With Same Name And Country Code Already Added");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAddState = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("country_id"));
        // alert(data.get("state"));
        setAddState(data)
            .then((response) => {
                console.log(response);
                if (response.data === "State Added") {
                    handleClose();
                    getAllGeolocationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllGeolocationData(response.data.data)
                        } else {
                            setAllGeolocationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    toast.success("State Added Successfully");
                } else {
                    toast.error("Failed! State With Same Name Already Added");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };  
    
    const handleAddCity = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("country_id"));
        // alert(data.get("state_id"));
        // alert(data.get("city"));
        setAddCity(data)
            .then((response) => {
                console.log(response);
                if (response.data === "City Added") {
                    handleClose();
                    getAllGeolocationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllGeolocationData(response.data.data)
                        } else {
                            setAllGeolocationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    toast.success("City Added Successfully");
                } else {
                    toast.error("Failed! City With Same Name Already Added");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    
    const handleAddLocality = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("country_id"));
        // alert(data.get("state_id"));
        // alert(data.get("city_id"));
        // alert(data.get("locality"));
        setAddLocality(data)
            .then((response) => {
                console.log(response);
                if (response.data === "Locality Added") {
                    handleClose();
                    getAllGeolocationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllGeolocationData(response.data.data)
                        } else {
                            setAllGeolocationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    toast.success("Locality Added Successfully");
                } else {
                    toast.error("Failed! Locality With Same Name Already Added");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };  

    const handleAddSubLocality = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // alert(data.get("sublocality_id"));
        // alert(data.get("country_id"));
        // alert(data.get("state_id"));
        // alert(data.get("city_id"));
        // alert(data.get("locality_id"));
        // alert(data.get("sub_locality"));
        setAddSubLocality(data)
            .then((response) => {
                console.log(response);
                if (response.data === "Sub Locality Added") {
                    handleClose();
                    getAllGeolocationData().then(response => {
                        console.log(response);
                        if (response.data === "No Data Found") {
                            setAllGeolocationData(response.data.data)
                        } else {
                            setAllGeolocationData(response.data)
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    toast.success("Sub Locality Added Successfully");
                } else {
                    toast.error("Failed! Sub Locality With Same Name Already Added");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };             

    const handImportConfiguration = (event) => {
        event.preventDefault();
        // alert('Improt Work');
        // const data = new FormData(event.currentTarget);
        // alert(data.get("configuration"));
        // console.log(data);
        setImportConfiguration()
            .then((response) => {
                console.log(response);
                // navigate("/configuration");
                // if (response.data === "Configuration Added") {
                // handleClose();
                // getAllGeolocationData().then(response => {
                //     console.log(response);
                //     if (response.data === "No Data Found") {
                //         setAllGeolocationData(response.data.data)
                //     } else {
                //         setAllGeolocationData(response.data)
                //     }
                // }).catch(err => {
                //     console.log(err);
                // });
                //     notifyImportSuccess();
                // } else {
                //     notifyImportFailed();
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "AddCountrybtn":
                return {
                    GeolocationLable: "Country",
                    ModalTitle: "Add Country",
                    SubmitHandle: handleAddCountry,
                    CountryCodeValue: "",
                    CountryValue: "",
                    BtnName: "Add Country",
                } 
            case "OnCountryCodeChange": {
                const newCountryCode = action.payload;
                return {
                    GeolocationLable: state.GeolocationLable,
                    ModalTitle: state.ModalTitle,
                    SubmitHandle: state.SubmitHandle,
                    CountryCodeValue: newCountryCode,
                    CountryValue: state.CountryValue,
                    BtnName: state.BtnName,
                };
            }
            case "OnCountryChange": {
                const newCountry = action.payload;
                return {
                    GeolocationLable: state.GeolocationLable,
                    ModalTitle: state.ModalTitle,
                    SubmitHandle: state.SubmitHandle,
                    CountryCodeValue: state.CountryCodeValue,
                    CountryValue: newCountry,
                    BtnName: state.BtnName,
                };
            }
            case "AddStatebtn":
                return {
                    GeolocationLable: "State",
                    ModalTitle: "Add State",
                    SubmitHandle: handleAddState,
                    StateValue: "",
                    BtnName: "Add State",
                }
                case "OnStateChange": {
                    const newState = action.payload;
                    return {
                        GeolocationLable: state.GeolocationLable,
                        ModalTitle: state.ModalTitle,
                        SubmitHandle: state.SubmitHandle,
                        StateValue: newState,
                        BtnName: state.BtnName,
                    };
                }  
            case "AddCitybtn":
                return {
                    GeolocationLable: "City",
                    ModalTitle: "Add City",
                    SubmitHandle: handleAddCity,
                    CityValue: "",
                    BtnName: "Add City",
                }  
                case "OnCityChange": {
                    const newCity = action.payload;
                    return {
                        GeolocationLable: state.GeolocationLable,
                        ModalTitle: state.ModalTitle,
                        SubmitHandle: state.SubmitHandle,
                        CityValue: newCity,
                        BtnName: state.BtnName,
                    };
                }                                
                case "AddLocalitybtn":
                    return {
                        GeolocationLable: "Locality",
                        ModalTitle: "Add Locality",
                        SubmitHandle: handleAddLocality,
                        LocalityValue: "",
                        BtnName: "Add Locality",
                    }  
                    case "OnLocalityChange": {
                        const newLocality = action.payload;
                        return {
                            GeolocationLable: state.GeolocationLable,
                            ModalTitle: state.ModalTitle,
                            SubmitHandle: state.SubmitHandle,
                            LocalityValue: newLocality,
                            BtnName: state.BtnName,
                        };
                    }
                    case "EditLocalitybtn":
                        return {
                            GeolocationLable: "Locality",
                            ModalTitle: "Edit Locality",
                            SubmitHandle: handleUpdateLocality,
                            LocalityValue: editlocality,
                            CityValue: city,
                            CityIDValue: cityid,
                            StateValue: statec,
                            StateIDValue: statecid,
                            CountryValue: country,
                            CountryIDValue: countryid,
                            BtnName: "Update Locality",
                        }                      
                    case "AddSubLocalitybtn":
                        return {
                            GeolocationLable: "Sub Locality",
                            ModalTitle: "Add Sub Locality",
                            SubmitHandle: handleAddSubLocality,
                            SubLocalityValue: "",
                            BtnName: "Add Sub Locality",
                        }  
                    case "OnSubLocalityChange": {
                        const newSubLocality = action.payload;
                        return {
                            GeolocationLable: state.GeolocationLable,
                            ModalTitle: state.ModalTitle,
                            SubmitHandle: state.SubmitHandle,
                            SubLocalityValue: newSubLocality,
                            BtnName: state.BtnName,
                        };
                    } 
                    case "EditSubLocalitybtn":
                        return {
                            GeolocationLable: "Sub Locality",
                            ModalTitle: "Edit Sub Locality",
                            SubmitHandle: handleUpdateSubLocality,
                            SubLocalityValue: editsublocality,
                            LocalityValue: locality,
                            LocalityIDValue: localityid,
                            CityValue: city,
                            CityIDValue: cityid,
                            StateValue: statec,
                            StateIDValue: statecid,
                            CountryValue: country,
                            CountryIDValue: countryid,
                            BtnName: "Update Sub Locality",
                        }                                                               
            case "Importbtn": {
                return {
                    GeolocationLable: "Upload CSV",
                    ModalTitle: "Import Configuration",
                    SubmitHandle: handImportConfiguration,
                    CountryCodeValue: "",
                    CountryValue: "",
                    BtnName: "Import Configuration",
                };
            }
            default:
                return state;
        }
    }

    const [state, dispatch] = React.useReducer(reducer, { GeolocationLable: "", ModalTitle: "", SubmitHandle: "", CountryCodeValue: "", CountryValue: "", BtnName: "" })

    const handleEditSubLocality = (sublocality_id) => (event) => {
        event.preventDefault();
        const data = sublocality_id;
        // alert(data);
        getEditSubLocality(data).then(response => {
            console.log(response.data[0].locality_id);
            if (response.data === "No Sub Locality Found") {
                // setEditSubLocality(response.data.data)
            } else {

                getAllCountryList().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllCountryList(response.data.data)
                    } else {
                        setAllCountryList(response.data.map((countrylist)=>({value: countrylist.country_id, label: countrylist.country})))
                    }
                }).catch(err => {
                    console.log(err);
                });
        
                getAllStateList().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllStateList(response.data.data)
                    } else {
                        setAllStateList(response.data.map((statelist)=>({value: statelist.state_id, label: statelist.state})))
                    }
                }).catch(err => {
                    console.log(err);
                });
        
                getAllCityList().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllCityList(response.data.data)
                    } else {
                        setAllCityList(response.data.map((citylist)=>({value: citylist.city_id, label: citylist.city})))
                    }
                }).catch(err => {
                    console.log(err);
                });
        
                getAllLocalityList().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllLocalityList(response.data.data)
                    } else {
                        setAllLocalityList(response.data.map((localitylist)=>({value: localitylist.locality_id, label: localitylist.locality})))
                    }
                }).catch(err => {
                    console.log(err);
                }); 
                
                dispatch({ type: "EditSubLocalitybtn" });
                setCountry(response.data[0].country);
                setCountryID(response.data[0].country_id);
                setStatec(response.data[0].state);
                setStatecID(response.data[0].state_id);
                setCity(response.data[0].city);
                setCityID(response.data[0].city_id);
                setLocality(response.data[0].locality);
                setLocalityID(response.data[0].locality_id);
                setEditSubLocality(response.data[0].sub_locality);
                setEditSubLocalityID(response.data[0].sublocality_id);
                handleOpen();
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const handleEditLocality = (locality_id) => (event) => {
        event.preventDefault();
        const data = locality_id;
        // alert(data);
        getEditLocality(data).then(response => {
            console.log(response.data[0].locality_id);
            if (response.data === "No Locality Found") {
                // setLocality(response.data.data)
            } else {

                getAllCountryList().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllCountryList(response.data.data)
                    } else {
                        setAllCountryList(response.data.map((countrylist)=>({value: countrylist.country_id, label: countrylist.country})))
                    }
                }).catch(err => {
                    console.log(err);
                });
        
                getAllStateList().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllStateList(response.data.data)
                    } else {
                        setAllStateList(response.data.map((statelist)=>({value: statelist.state_id, label: statelist.state})))
                    }
                }).catch(err => {
                    console.log(err);
                });
        
                getAllCityList().then(response => {
                    console.log(response);
                    if (response.data === "No Data Found") {
                        setAllCityList(response.data.data)
                    } else {
                        setAllCityList(response.data.map((citylist)=>({value: citylist.city_id, label: citylist.city})))
                    }
                }).catch(err => {
                    console.log(err);
                });
                
                dispatch({ type: "EditLocalitybtn" });
                setCountry(response.data[0].country);
                setCountryID(response.data[0].country_id);
                setStatec(response.data[0].state);
                setStatecID(response.data[0].state_id);
                setCity(response.data[0].city);
                setCityID(response.data[0].city_id);
                setEditLocality(response.data[0].locality);
                setEditLocalityID(response.data[0].locality_id);
                handleOpen();
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const columns = [
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Box sx={{ display: "flex" }}>
                            {/* <Tooltip
                                title="Edit Sub Locality"
                                component="label"
                                onClick={
                                    handleEditSubLocality(geolocationdata[dataIndex].sublocality_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Delete Sub Locality"
                                component="label"
                                onClick={
                                    handleDeleteSubLocality(geolocationdata[dataIndex].sublocality_id)
                                }>
                                <IconButton><DeleteIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                            <Tooltip
                                title="Edit Locality"
                                component="label"
                                onClick={
                                    handleEditLocality(geolocationdata[dataIndex].locality_id)
                                }>
                                <IconButton><EditIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip> */}
                            <Tooltip
                                title="Not allowed"
                                component="label"
                            >
                                <IconButton><NotInterestedIcon sx={{ fontSize: "20px" }} /></IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        // {
        //     name: "create_dt",
        //     label: "Posted On",
        // },
        // {
        //     name: "update_dt",
        //     label: "Updated On",
        // },
        {
            name: "country",
            label: "Country",
        },
        {
            name: "state",
            label: "State",
        },
        {
            name: "city",
            label: "City",
        },
        {
            name: "locality",
            label: "Locality",
        },
        {
            name: "sub_locality",
            label: "Sub Locality",
        },
    ];

    const ModalOpenAddCountry = () => {
        dispatch({ type: "AddCountrybtn" });
        handleOpen();
    }

    const ModalOpenAddState = () => {
        
        getAllCountryList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllCountryList(response.data.data)
            } else {
                setAllCountryList(response.data.map((countrylist)=>({value: countrylist.country_id, label: countrylist.country})))
            }
        }).catch(err => {
            console.log(err);
        });

        dispatch({ type: "AddStatebtn" });
        handleOpen();

    }

    const ModalOpenAddCity = () => {
        
        getAllCountryList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllCountryList(response.data.data)
            } else {
                setAllCountryList(response.data.map((countrylist)=>({value: countrylist.country_id, label: countrylist.country})))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllStateList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllStateList(response.data.data)
            } else {
                setAllStateList(response.data.map((statelist)=>({value: statelist.state_id, label: statelist.state})))
            }
        }).catch(err => {
            console.log(err);
        });

        dispatch({ type: "AddCitybtn" });
        handleOpen();

    }    

    const ModalOpenAddLocality = () => {
        
        getAllCountryList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllCountryList(response.data.data)
            } else {
                setAllCountryList(response.data.map((countrylist)=>({value: countrylist.country_id, label: countrylist.country})))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllStateList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllStateList(response.data.data)
            } else {
                setAllStateList(response.data.map((statelist)=>({value: statelist.state_id, label: statelist.state})))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllCityList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllCityList(response.data.data)
            } else {
                setAllCityList(response.data.map((citylist)=>({value: citylist.city_id, label: citylist.city})))
            }
        }).catch(err => {
            console.log(err);
        });

        dispatch({ type: "AddLocalitybtn" });
        handleOpen();

    }     

    const ModalOpenAddSubLocality = () => {
        
        getAllCountryList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllCountryList(response.data.data)
            } else {
                setAllCountryList(response.data.map((countrylist)=>({value: countrylist.country_id, label: countrylist.country})))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllStateList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllStateList(response.data.data)
            } else {
                setAllStateList(response.data.map((statelist)=>({value: statelist.state_id, label: statelist.state})))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllCityList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllCityList(response.data.data)
            } else {
                setAllCityList(response.data.map((citylist)=>({value: citylist.city_id, label: citylist.city})))
            }
        }).catch(err => {
            console.log(err);
        });

        getAllLocalityList().then(response => {
            console.log(response);
            if (response.data === "No Data Found") {
                setAllLocalityList(response.data.data)
            } else {
                setAllLocalityList(response.data.map((localitylist)=>({value: localitylist.locality_id, label: localitylist.locality})))
            }
        }).catch(err => {
            console.log(err);
        });        

        dispatch({ type: "AddSubLocalitybtn" });
        handleOpen();

    }         

    const ModalOpenImport = () => {
        dispatch({ type: "Importbtn" });
        handleOpen();
    }

    const handleChange = (rdata) => {
        console.log(rdata)
    }

    const handleCurrentPage = (currentpage) => {
        setMyPage(currentpage);
    }
    
    return (
        <>
            <ToastContainer />
            <Breadcrumb PageName="Geolocation" />
            <Box sx={{ mb: 2 }} className='cst-btn'>
                <CustomModal
                    BtnName = ""
                    AddCountryBtnName="Add Country"
                    OpenAddCountryModalfun={ModalOpenAddCountry}
                    AddStateBtnName="Add State"
                    OpenAddStateModalfun={ModalOpenAddState}
                    AddCityBtnName="Add City"
                    OpenAddCityModalfun={ModalOpenAddCity}
                    AddLocalityBtnName="Add Locality"
                    OpenAddLocalityModalfun={ModalOpenAddLocality}
                    AddSubLocalityBtnName="Add Sub Locality"
                    OpenAddSubLocalityModalfun={ModalOpenAddSubLocality}
                    ImportBtnName="Import Configuration"
                    OpenImportModalfun={ModalOpenImport}
                    handeleclosefun={handleClose}
                    ModalTitle={state.ModalTitle}
                    open={open}
                    ModalContent={
                        <>
                            <Box component="form" autoComplete="off"
                                onSubmit={state.SubmitHandle} method="post">
                                {state.ModalTitle === "Edit Country" ?
                                    <CustomInputField
                                        type="hidden"
                                        name="confi_id"
                                        value={editsublocality_id}
                                        placeholder="Enter Country Id"
                                        required="required"
                                    />
                                    : null}   
                                   {state.ModalTitle === "Add Country" || state.ModalTitle === "Edit Country" ?
                                    <>  
                                        <CustomFormGroup 
                                            mtprop="0rem"
                                            formlabel="Country Code"
                                            FormField={
                                                <CustomInputField
                                                    type="number"
                                                    name="country_code"
                                                    value={state.CountryCodeValue}
                                                    onchange={event =>
                                                        dispatch({ type: "OnCountryCodeChange", payload: event.target.value })}
                                                    placeholder="Enter Country Code"
                                                    required="required"
                                                />
                                            }
                                        />
                                        <CustomFormGroup 
                                            mtprop="1rem"
                                            formlabel="Country"
                                            FormField={
                                                <CustomInputField
                                                    type="text"
                                                    name="country"
                                                    value={state.CountryValue}
                                                    onchange={event =>
                                                        dispatch({ type: "OnCountryChange", payload: event.target.value })}
                                                    placeholder="Enter Country"
                                                    required="required"
                                                />
                                            }
                                        />
                                    </>
                                    : null}
                                    
                                    {state.ModalTitle === "Add State" || state.ModalTitle === "Edit State" ?
                                    <>  
                                        <CustomFormGroup
                                            formlabel="Country"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="country_id"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add State" 
                                                        ? 
                                                        state.CountryValue
                                                        : 
                                                        {label: state.CountryValue, value: state.CountryValue}
                                                    }
                                                    options={countrylist}
                                                    onChange={(event) => setCountry(event.value)}
                                                />
                                            }
                                        />
                                        <CustomFormGroup 
                                            mtprop="1rem"
                                            formlabel="State"
                                            FormField={
                                                <CustomInputField
                                                    type="text"
                                                    name="state"
                                                    value={state.StateValue}
                                                    onchange={event =>
                                                        dispatch({ type: "OnStateChange", payload: event.target.value })}
                                                    placeholder="Enter State"
                                                    required="required"
                                                />
                                            }
                                        />
                                    </>
                                    : null} 

                                    {state.ModalTitle === "Add City" || state.ModalTitle === "Edit City" ?
                                    <>  
                                        <CustomFormGroup
                                            formlabel="Country"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="country_id"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add City" 
                                                        ? 
                                                        state.CountryValue
                                                        : 
                                                        {label: state.CountryValue, value: state.CountryValue}
                                                    }
                                                    options={countrylist}
                                                    onChange={(event) => setCountry(event.value)}
                                                />
                                            }
                                        />

                                        <CustomFormGroup
                                            mtprop="1rem"
                                            formlabel="State"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="state_id"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add City" 
                                                        ? 
                                                        state.StateValue
                                                        : 
                                                        {label: state.StateValue, value: state.StateValue}
                                                    }
                                                    options={statelist}
                                                    onChange={(event) => setStatec(event.value)}
                                                />
                                            }
                                        />                                        
                                        <CustomFormGroup 
                                            mtprop="1rem"
                                            formlabel="City"
                                            FormField={
                                                <CustomInputField
                                                    type="text"
                                                    name="city"
                                                    value={state.CityValue}
                                                    onchange={event =>
                                                        dispatch({ type: "OnCityChange", payload: event.target.value })}
                                                    placeholder="Enter City"
                                                    required="required"
                                                />
                                            }
                                        />
                                    </>
                                    : null} 
                                    
                                    {state.ModalTitle === "Edit Locality" ?
                                    <CustomInputField
                                        type="hidden"
                                        name="locality_id"
                                        value={editlocality_id}
                                        placeholder="Enter Locality Id"
                                        required="required"
                                    />
                                    : null} 

                                    {state.ModalTitle === "Add Locality" || state.ModalTitle === "Edit Locality" ?
                                    <>  
                                        <CustomFormGroup
                                            formlabel="Country"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="country_id"
                                                    required="required"
                                                    disabled = {
                                                        state.ModalTitle === "Edit Locality" 
                                                        ?
                                                        "disabled"
                                                        :
                                                        null  
                                                    }
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Locality" 
                                                        ? 
                                                        state.CountryValue
                                                        : 
                                                        {label: state.CountryValue, value: state.CountryIDValue}
                                                    }
                                                    options={countrylist}
                                                    onChange={(event) => setCountry(event.value)}
                                                />
                                            }
                                        />

                                        <CustomFormGroup
                                            mtprop="1rem"
                                            formlabel="State"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="state_id"
                                                    required="required"
                                                    disabled = {
                                                        state.ModalTitle === "Edit Locality" 
                                                        ?
                                                        "disabled"
                                                        :
                                                        null  
                                                    }
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Locality" 
                                                        ? 
                                                        state.StateValue
                                                        : 
                                                        {label: state.StateValue, value: state.StateIDValue}
                                                    }
                                                    options={statelist}
                                                    onChange={(event) => setStatec(event.value)}
                                                />
                                            }
                                        />  

                                        <CustomFormGroup
                                            mtprop="1rem"
                                            formlabel="City"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="city_id"
                                                    required="required"
                                                    disabled = {
                                                        state.ModalTitle === "Edit Locality" 
                                                        ?
                                                        "disabled"
                                                        :
                                                        null  
                                                    }
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Locality" 
                                                        ? 
                                                        state.CityValue
                                                        : 
                                                        {label: state.CityValue, value: state.CityIDValue}
                                                    }
                                                    options={citylist}
                                                    onChange={(event) => setCity(event.value)}
                                                />
                                            }
                                        />

                                        <CustomFormGroup 
                                            mtprop="1rem"
                                            formlabel="Locality"
                                            FormField={
                                                <CustomInputField
                                                    type="text"
                                                    name="locality"
                                                    value={state.LocalityValue}
                                                    onchange={event =>
                                                        dispatch({ type: "OnLocalityChange", payload: event.target.value })}
                                                    placeholder="Enter Locality"
                                                    required="required"
                                                />
                                            }
                                        />
                                    </>
                                    : null}  

                                    {state.ModalTitle === "Edit Sub Locality" ?
                                    <CustomInputField
                                        type="hidden"
                                        name="sublocality_id"
                                        value={editsublocality_id}
                                        placeholder="Enter Sub Locality Id"
                                        required="required"
                                    />
                                    : null} 
                                    {state.ModalTitle === "Add Sub Locality" || state.ModalTitle === "Edit Sub Locality" ?
                                    <>  
                                        <CustomFormGroup
                                            formlabel="Country"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="country_id"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Sub Locality" 
                                                        ? 
                                                        state.CountryValue
                                                        : 
                                                        {label: state.CountryValue, value: state.CountryIDValue}
                                                    }
                                                    options={countrylist}
                                                    onChange={(event) => setCountry(event.value)}
                                                />
                                            }
                                        />

                                        <CustomFormGroup
                                            mtprop="1rem"
                                            formlabel="State"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="state_id"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Sub Locality" 
                                                        ? 
                                                        state.StateValue
                                                        : 
                                                        {label: state.StateValue, value: state.StateIDValue}
                                                    }
                                                    options={statelist}
                                                    onChange={(event) => setStatec(event.value)}
                                                />
                                            }
                                        />  

                                        <CustomFormGroup
                                            mtprop="1rem"
                                            formlabel="City"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="city_id"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Sub Locality"
                                                        ? 
                                                        state.CityValue
                                                        : 
                                                        {label: state.CityValue, value: state.CityIDValue}
                                                    }
                                                    options={citylist}
                                                    onChange={(event) => setCity(event.value)}
                                                />
                                            }
                                        />

                                        <CustomFormGroup
                                            mtprop="1rem"
                                            formlabel="Locality"
                                            FormField={
                                                <CustomSelectFiled
                                                    name="locality_id"
                                                    required="required"
                                                    defaultValue = {
                                                        state.ModalTitle === "Add Sub Locality" 
                                                        ? 
                                                        state.LocalityValue
                                                        : 
                                                        {label: state.LocalityValue, value: state.LocalityIDValue}
                                                    }
                                                    options={localitylist}
                                                    onChange={(event) => setLocality(event.value)}
                                                />
                                            }
                                        />

                                        <CustomFormGroup 
                                            mtprop="1rem"
                                            formlabel="Sub Locality"
                                            FormField={
                                                <CustomInputField
                                                    type="text"
                                                    name="sub_locality"
                                                    value={state.SubLocalityValue}
                                                    onchange={event =>
                                                        dispatch({ type: "OnSubLocalityChange", payload: event.target.value })}
                                                    placeholder="Enter Sub Locality"
                                                    required="required"
                                                />
                                            }
                                        />
                                    </>
                                    : null}                                                                               

                                   {state.ModalTitle === "Import Configuration" ?
                                    <CustomInputField
                                        type="file"
                                        name="configuration_csv"
                                        required="required"
                                        accept=".csv"
                                    />
                                    : null}
                                <Button variant="contained" type="submit" startIcon={<AddIcon />} sx={{ mt: 2 }}>
                                    {state.BtnName}
                                </Button>
                            </Box>
                        </>
                    }
                />
            </Box>

            <Datatable sendDataToParent = {handleChange} TabelTitle="Add Geolocation" data={geolocationdata?.map(geoloc => {
                return [
                    geoloc,
                    // dayjs().format('YYYY-MM-DD') === dayjs(geoloc.create_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(geoloc.create_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(geoloc.create_dt).format('hh:MM a'),
                    // dayjs().format('YYYY-MM-DD') === dayjs(geoloc.update_dt).format('YYYY-MM-DD') ? 'Today at ' + dayjs(geoloc.update_dt).format('hh:mm a') : 'Yesterday at ' + dayjs(geoloc.update_dt).format('hh:MM a'),
                    geoloc.country,
                    geoloc.state,
                    geoloc.city,
                    geoloc.locality,
                    geoloc.sublocality,
                ]
            })} columns={columns} currentPage = {myPage} changeCurrentPage = {handleCurrentPage} />

        </>
    );
};

export default Geolocation;