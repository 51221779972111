import React from 'react'
import styled from 'styled-components'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Button, Tooltip, Divider, Grid, TextField} from "@mui/material";
import { Link } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// import { getLeads, unSetLead } from './ApiCalls';
import AddIcon from "@mui/icons-material/Add";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import '../reacttable.css';
import { useQueryClient, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { isSameDay, subDays } from 'date-fns';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import ReactWhatsapp from 'react-whatsapp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import {AdvanceSearchInput, CustomInputField, CustomFormGroup} from '../../Components/Common';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { getStatusCount, getLeadsReport } from './ApiCalls';
import { getAssignUser } from '../AssignLeadForm/ApiCalls';
import { CSVLink } from "react-csv";

function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
}) {
  const [controlledPageIndex, setControlledPage] = React.useState(0)
  const [open, setOpen] = React.useState(false);
  const [leadId, setLeadId] = React.useState([]);
  const [search, setSearch] = React.useState('false');
  const [searchData, setSearchData] = React.useState('');
  const [from, setFrom] = React.useState();
  const [to, setTo] = React.useState();
  const [usersList, setUsersList] = React.useState([]);  
  
  // reset
  const [clearEmpty, setClearEmpty] = React.useState(false);
  const formRef = React.useRef(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: localStorage.getItem('tablePageIndex') !== 'null' ? Number(localStorage.getItem('tablePageIndex')) : controlledPageIndex, pageSize: 30}, 
      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount
    },
    useSortBy,
    usePagination
  )

  React.useEffect(() => {
    if(search === 'true'){
    fetchData({ pageIndex, pageSize, searchData, from, to })
    }
    getAssignUser().then((response)=>{
      //console.log(response)
      setUsersList(response.data !== 'No Data Found' ? response.data.map((users)=>({'title':users.username, 'value':users.u_id})) : [])
      // setUsersList(groupBy(response.data.map((users)=>({label: users.username, value: users.u_id, urole:users.urole})), 'urole'))
    }).catch((err)=>{
      console.log(err);
    })
  }, [fetchData, pageIndex, pageSize, search, from, to, searchData])

  // React.useEffect(() => {
  //   setList(page);  
  // }, [page, list, leadId]);

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget);
    setSearch('true') 
    setSearchData(data)  
  }

   const handleClose = () => {
    setOpen(false);
  };

  const deleteLead = async(lids) => {
    // unSetLead(lids)
    // .then((response) => {
    //   if(response.data === 'lead deleted'){
    //     handleClose();
    //     alert("Leads Deleted Successfully");
    //   }
    //   console.log(response)
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };
  
  const next = () =>{
    nextPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.ceil(pageIndex+1));
  }

  const prev = () =>{
    previousPage()
    setControlledPage(pageIndex)
    localStorage.setItem('tablePageIndex', Math.ceil(pageIndex-1));
  }

  function clearField(){
    setClearEmpty(true); 
    // setSearch('false');
    // setSearchData('');
    setFrom();
    setTo();
    setTimeout(() => {
      setClearEmpty(false);
    }, 100);
  }

  return (
    <>

      {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' || Cookies.get('role') === 'Team Leader' || Cookies.get('role') === 'Sales Manager' || Cookies.get('role') === 'Branch Admin' ? 
        <Breadcrumb 
          PageName="Leads Reports" 
          // BackButton={[Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? true : false, 'Add', '/add-lead/leadsreports', <AddIcon />]} 
          // AssignButton={[leadId.length !== 0 ? true : false, 'Assign Leads', `/assign-leads-to/${leadId}/mypage=${pageIndex}`, <AddIcon />]}
        />  
      :
        null 
      }

      {/* {leadId.length !== 0 && (Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin') ? <Button
        variant="outlined"
        sx={{ mx: 1, justifyContent: 'left', position: 'absolute',
        right: '1rem',
        top: '5.5rem',
        zIndex: 1000 }}
        startIcon={<DeleteIcon />}
        onClick={() => handleClickOpen(leadId)}
      >Delete</Button>  : null} */}

      {data.length !== 0 ?
        <CSVLink
          data={data}
          filename="leads-report.csv" 
          style={{position: 'absolute', right: '2rem', top: '5.5rem'}}
        >
          <Button
            variant="outlined" 
            startIcon={<FileUploadOutlinedIcon />} 
          >
            Export
          </Button>
        </CSVLink>
      : null}

      {/* onSubmit={handleSubmit} */}
        <Box component="form" onSubmit={handleSubmit} ref={formRef}>
          <Accordion style={{margin: '0%'}} className="accordion" expanded={true}>

            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{paddingLeft: '24px', paddingRight: '24px'}}
            >
              <Typography variant="h6" component='h6' m={0} sx={{fontSize: '16px', fontWeight: 600}}>
                Generate User Lead Reports 
              </Typography> 
            </AccordionSummary>
            <Divider />
            <AccordionDetails sx={{px: '0'}}> 
              
              <Grid container pt={2} px={3} columnGap={2} width='100%' sx={{justifyContent: ''}} alignItems='center' >  
                {/* <Grid item md={2} >  */}
                  {/* <AdvanceSearchInput 
                    inputLable="Select Users"
                    inputname="user"
                    inputRole="advanceSearch"
                    resetField={clearEmpty}
                    // options={[]}
                    options={usersList}
                  /> */}
                  {Cookies.get('role') === 'Master' || Cookies.get('role') === 'Admin' ? 
                    <Grid item md={2} >
                      <AdvanceSearchInput 
                        inputLable="Select Users"
                        inputname="user"
                        inputRole="advanceSearch"
                        resetField={clearEmpty}
                        // options={[]}
                        options={usersList}
                      />
                    </Grid> 
                    : <input type="hidden" name="user" value="null"/>
                  }
                {/* </Grid> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item md={2} >
                    <MobileDatePicker 
                      className="mobiledatepick"
                      label="Select Date From"
                      inputFormat="MM/DD/YYYY"
                      value={from}
                      onChange={(value)=>{setFrom(dayjs(value).format('YYYY-MM-DD'))}}
                      // onAccept={}
                      renderInput={(params) => <TextField {...params} />}
                      // disablePast
                    /> 
                  </Grid>
                  <Grid item md={2} >
                    <MobileDatePicker 
                      className="mobiledatepick"
                      label="Select Date To"
                      inputFormat="MM/DD/YYYY"
                      value={to}
                      onChange={(value)=>{setTo(dayjs(value).format('YYYY-MM-DD'))}}
                      // onAccept={}
                      renderInput={(params) => <TextField {...params} />}
                      //disablePast
                    /> 
                  </Grid>  
                </LocalizationProvider>   
              </Grid> 
        
              <Grid container>
                <Grid item md={12} justifyContent='left' textAlign='left'>
                  <Divider sx={{my: 2}} /> 
                  <Button type="reset" style={{float: 'right', right: '2rem'}} variant="outlined" size="medium" startIcon={<RestartAltIcon />} sx={{textTransform: 'capitalize'}} onClick={() => clearField()}>
                  Reset Search Result
                  </Button>
                  <Button type="submit" style={{float: 'right', right: '3rem'}} variant="outlined" size="medium" startIcon={<SearchIcon />} sx={{textTransform: 'capitalize'}}>
                    Search Leads
                  </Button>
                </Grid>  
              </Grid> 
            </AccordionDetails>
          </Accordion>
        </Box>
    <br />
    <div className='table'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              <th style={{width: '55px', position: 'relative'}}>No</th>
              {headerGroup.headers.map((column, index) => (
                column.Header === 'Date' ?
                  <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())} style={{position: 'relative', left: '0'}}>
                    {column.render('Header')}
                    <span style={{color: '#e9e9e9', position: 'absolute', top: '1rem', right: '0.3rem'}}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                        <FilterAltIcon />
                        ) : (
                        <FilterAltOffIcon />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                :
                  <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())} style={{position: 'relative'}}>
                    {column.render('Header')}
                    <span style={{color: '#e9e9e9', position: 'absolute', top: '1rem', right: '0.3rem'}}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                        <FilterAltIcon />
                        ) : (
                        <FilterAltOffIcon />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => { 
            prepareRow(row)
            // console.log(row)
            return (
              <tr key={i} {...row.getRowProps()}>
                <td style={{width: '55px', position: 'relative'}}>{i}</td>
                {row.cells.map((cell, i) => (
                  <>              
                  {cell.column.Header === 'Date' ? 
                    <td key={i} {...cell.getCellProps()} style={{position: 'relative', left: '0', overflow: 'hidden', whiteSpace: 'break-spaces'}}>
                      <Tooltip
                        title={cell.render('Cell')}
                        sx={{py: 0}}
                      >
                        <span>{cell.render('Cell')}</span>
                      </Tooltip>
                    </td>  
                  : 
                    <td key={i} {...cell.getCellProps()}>
                      <Tooltip
                        title={cell.render('Cell')}
                        sx={{py: 0}}
                      >
                        <span>{cell.render('Cell')}</span>
                      </Tooltip>
                    </td>  
                  }
                  </>
                ))}
              </tr> 
            )
          })}

          <tr style={{backgroundColor:'#ffffff'}}>
            <td style={{width: '100%'}}></td> 
          </tr>

          {/* <tr style={{backgroundColor:'#ffffff'}}>
            {loading ? (
              <td style={{border: 'none'}}>Loading...</td>
            ) : (
              <td style={{border: 'none'}}>
                Showing {page.length} of {controlledPageCount}{' '}
                results
              </td>
            )}

            <td className="pagination" style={{border: 'none'}}>
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => prev()} disabled={!canPreviousPage}>
                {'<'}
              </Button>{' '}

              <span>
                &nbsp;Page{' '}
                <strong>
                  {Math.ceil(pageIndex + 1)} of {Math.ceil(controlledPageCount/pageSize)}
                </strong>{' '}&nbsp;
              </span>

              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => next()} disabled={!canNextPage}>
                {'>'}
              </Button>{' '}
              <Button sx={{p: 0, minWidth: '2rem'}} onClick={() => gotoPage(Math.ceil(controlledPageCount/pageSize - 1))} disabled={!canNextPage}>
                {'>>'}
              </Button>  */}

              {/* <span>
                | Go to page:{' '}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px' }}
                />
              </span>{' '} */} 

              {/* <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                style={{fontSize: '13px', border: 'none'}}
              >
                {[30, 50, 100, 200].map((pageSize, i) => (
                  <option key={i} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </td>
          </tr> */}

        </tbody>
       </table>
      </div>

      {/* delete confirm */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => deleteLead(leadId)} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function LeadsReports() {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const fetchIdRef = React.useRef(0)
  const queryClient = useQueryClient();
  const totalleadcount = queryClient.getQueryData('missedcount');
  const [status, setStatus] = React.useState([]);

    React.useEffect(() => {
      getStatusCount()
      .then((response) => {
        setStatus(response.data)
      })
      .catch((err) => {
        console.log(err);
      }); 
  }, []);

  const columns = React.useMemo(() => {
    const staticColumns = [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Total Count',
        accessor: 'total_count',
      },
    ];
  
    return staticColumns.concat(
      status.map((status) => ({
        Header: status.status,
        accessor: status.status,
      }))
    );

  }, [status]); 

  const fetchData = React.useCallback(({ pageIndex, pageSize, searchData, from, to })  => {

    const fetchId = ++fetchIdRef.current
    
   // console.log('hi')
   if(searchData){
    getLeadsReport(searchData.get('user').split(','), from, to).then((response)=>{
      console.log(response)
      setData(response.data !== 'No Data Found' ? response.data : [])
    }).catch((err)=>{
      console.log(err);
    })
   }
  
      //  getLeads(pageSize, Math.ceil(startRow))
      //   .then((response) => {
      //     console.log(response)
      //     setData(response.data !== 'No Data Found' ? response.data : [])
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });  
    // setLoading(true)
    // setTimeout(() => {
      // if (fetchId === fetchIdRef.current) {
      //   const startRow = pageSize * pageIndex
      //   const endRow = startRow + pageSize
      //   getLeads(pageSize, Math.ceil(startRow))
      //   .then((response) => {
      //     console.log(response)
      //     setData(response.data !== 'No Data Found' ? response.data : [])
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });      
      //   setLoading(false)
      // }
    // }, 1000)
  }, [])

  const memoizedData = React.useMemo(() => data, [data]);

  return (
    <Table
      columns={columns}
      data={memoizedData}
      fetchData={fetchData}
      loading={loading}
      pageCount={Number(totalleadcount)}
    />
  )
}

export default LeadsReports;