import React, { useState }  from 'react'; 
import { useNavigate } from 'react-router-dom';
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Skeleton from "@mui/material/Skeleton";
import LeadCard from "../../Components/LeadCard/LeadCard";
import { InnerPageHeader } from "../../Components/PageHeader/PageHeader";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery } from "react-query";
import Fade from '@mui/material/Fade';
import { getLeads, getNewLeadCount } from "./ApiCalls";
import "./NewLead.css";
import Cookies from 'js-cookie';

const NewLead = () => {
  // const [newLeads, setNewLeads] = useState([]);
  const [statusCount, setStatusCount] = useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    getNewLeadCount().then(response => {
      setStatusCount(response)
      console.log(response);
    }).catch(err => {
      console.log(err);
    });
  }, [])

  const LIMIT = 10

  const {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    ...result
  } = useInfiniteQuery({
    queryKey : 'new_lead',
    queryFn: ({ pageParam = 0 }) => getLeads(LIMIT, pageParam, 'New Lead'),
    getNextPageParam: (lastPage, allPages) =>  {
      if (lastPage.nextPage < statusCount) return lastPage.nextPage;
      return undefined; 
    }
  })

  return (
    <>
      <InnerPageHeader pagename="New Leads" backto="/leads?statustab" />
      <Divider variant="hr" sx={{ mt: 2 }} />
      <List sx={{ width: "100%", p: 0, mb: 0, bgcolor: "background.paper" }}>
        {!result.isSuccess ? Array.from({ length: 15 }, (value, key) => <ListItem key={key}>
          <ListItemAvatar>
            <Skeleton
              variant="rectangular"
              sx={{ p: '7px 8px', width: '40px', height: '40px' }}
              animation="wave"
            />
          </ListItemAvatar>
          <Skeleton
            variant="rectangular"
            sx={{ ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px' }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            className="leadbutton"
            sx={{ width: '50px', height: '15px', mt: -3.5 }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            className="leaddate"
            sx={{ width: '50px', height: '15px' }}
            animation="wave"
          />
        </ListItem>)
          : ( result.data.pages[0].response.data !== 'No Data Found' ?
            (<InfiniteScroll loader={<div className="loader" key={0}>Loading ...</div>} useWindow={true} threshold={50} hasMore={hasNextPage} loadMore={fetchNextPage}>
              <Fade in timeout={800}>
                <List sx={{ width: '100%'}}>
                  {
                    result.data.pages?.map((page) => (
                      page.response.data?.map((lead) => (
                        <LeadCard key={lead.l_id}
                          lead_id={lead.l_id}
                          lead_name={lead.lname}
                          lead_pname={lead.pname}
                          lead_source={lead.source}
                          lead_color={lead.color}
                          lead_status={lead.status}
                          lead_create_dt={lead.create_dt}
                        />
                      ))
                    ))
                  }
                </List>
              </Fade>
            </InfiniteScroll>) : <Alert severity="info">No new lead found!</Alert>
          )}
      </List> 
    </>
  );
};

export default NewLead;