import * as React from 'react';
import Box from '@mui/material/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Face6RoundedIcon from '@mui/icons-material/Face6Rounded';
import Face4RoundedIcon from '@mui/icons-material/Face4Rounded';

function createuData(color, avatar, user, login, logout) {
    return {
        color,
        avatar,
        user,
        login,
        logout
    };
}
const useractivity = [
    createuData("primary", <Face4RoundedIcon />, 'Shaziya', "10:00 am", "12:00 pm"),
    createuData("secondary", <Face4RoundedIcon />, 'Neha Shaikh',"10:30 am", "02:00 pm"),
    createuData("primary", <Face6RoundedIcon />, 'Mursaleen', "11:00 am", "01:00 pm"),
    createuData("secondary", <Face6RoundedIcon />, 'Master', "12:00 pm", "03:00 pm"),
    createuData("primary", <Face6RoundedIcon />, 'Majid Khan', "01:00 pm", "06:00 pm"),
    createuData("secondary", <Face4RoundedIcon />, 'Mahek Propmasterd', "03:00 pm", "07:00 pm"),
    createuData("primary", <Face4RoundedIcon />, 'Mahek', "04:30 pm", "05:00 pm"),
    createuData("secondary", <Face6RoundedIcon />, 'Arif Shaikh', "05:30 pm", "06:00 pm")
];

export default function Activity() {
  return (
    <Box className="cstbox" > 
        <h5 className="ltitle" style={{marginBottom: '10px'}}>Todays Activity</h5>
        <Timeline sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            height: '360px', 
            overflowY: 'scroll'
        }} >
            {useractivity.map((row, index) => {
                return (
                    <TimelineItem key={index}>
                        <TimelineSeparator>
                            <TimelineConnector />
                                <TimelineDot color={row.color}>
                                    {row.avatar}
                                </TimelineDot>
                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '15px', px: 2 }}>
                            <Typography variant="div" component="span">
                                {row.user}
                            </Typography>
                            <h6 style={{margin: 0, color: '#7b809a'}}>
                                {'Login: '+row.login}
                                <br />
                                {'Logout: '+row.logout}
                            </h6>
                        </TimelineContent>
                    </TimelineItem>
                )
            })}
        </Timeline>
    </Box>
  );
}