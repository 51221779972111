import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Skeleton from "@mui/material/Skeleton";
// import Backdrop from '@mui/material/Backdrop';
// import SpeedDial from '@mui/material/SpeedDial';
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';
// import SpeedDialAction from '@mui/material/SpeedDialAction';
// import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import dayjs from 'dayjs';
import './FollowLeadCard.css';

// Backdrop & + icon
// const actions = [
//   { icon: <PlaylistAddIcon />, name: 'Add' },
//   // { icon: <SaveIcon />, name: 'Save' },
// ];

const FollowLeadCard = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  });

  // Backdrop & + icon
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const navigate = useNavigate();
  
  // function addLead() {
  //   navigate("/addlead");
  // }
  
  return (
    <>
      <ListItem button className='listitem' component={Link}
        to={window.location.pathname === "/followup" ? `/viewlead/${props.lead_id}?todayfollowup` : 
            window.location.pathname === "/missedlead" ? `/viewlead/${props.lead_id}?missedfollowup` :
            window.location.pathname === "/upcominglead" ? `/viewlead/${props.lead_id}?upcomingfollowup` :
            `/viewlead/${props.lead_id}`}
      >
        <ListItemAvatar>
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{p:'7px 8px', width: '40px', height: '40px'}}
            animation="wave"
          />
        ) : (
          <Avatar className='leadicon' variant="square">{props.lead_name.charAt(0).toUpperCase() }</Avatar>
        )}
        </ListItemAvatar>
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{ml: 1.6, mt: -3.5, mb: 0, width: '100%', height: '35px'}}
            animation="wave"
          />
        ) : (
          <ListItemText className='lead-title' sx={{pl: 1.6, mt: -3.8, mb: 0}} primary={props.lead_name} secondary={props.lead_source+" lead via "+props.lead_pname} />
        )}
        {loading ? (
          <Skeleton
            variant="rectangular"
            className="leadbutton"
            sx={{width: '50px', height: '15px'}}
            animation="wave"
          />
        ) : (
          <>
            <Chip 
              className="leadbutton"
              avatar={<Avatar>{
                props.lead_source === 'Facebook' ? "F" : null || 
                props.lead_source === 'Google' ? "G" : null || 
                props.lead_source === 'Housing' ? "H" : null || 
                props.lead_source === 'MagicBricks' ? "MB" : null ||
                props.lead_source === '99 Acres' ? "99" : null ||
                props.lead_source === 'Olx' ? "O" : null || 
                props.lead_source === 'Quicker' ? "Q" : null  
              }</Avatar>} 
              label={props.lead_source} 
            /> 
            <Button className="leadbutton1" sx={{backgroundColor: props.lead_color, color: 'white'}} size="small">{props.lead_status}</Button> 
          </>
        )} 
      </ListItem>
      <ListItem sx={{mt: '-2px', pt: 0, pl: 11}}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            className="flleaddate"
            sx={{width: '50px', height: '15px'}}
            animation="wave"
          />
        ) : (
          <> 
            <small className='flleaddate'>{props.lead_due}</small>
            <small className='flleaddate'>{dayjs(props.lead_due_time).format('hh:mm a')}</small>
          </>
        )}
      </ListItem>
      <Divider variant="inset" component="li" sx={{ml:0}} />
    </>
  );
}

export default FollowLeadCard;