import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';
import React from 'react'

const getStatusCount = async() => {
    try{
        const response = await Axios.post(`${API_URL}/status/getAllStatus`);
        // const response = await Axios.post('http://localhost:3004/status/getAllStatus');
        return response;
    }catch(err){
        return err;
    }
}

const getLeadsReport = async(uid, from, to) => {
    try{
        const response = await Axios.post(`${API_URL}/desktop/leads/getLeadReport`,{uid:uid, from:from, to:to});
        // const response = await Axios.post('http://localhost:3004/status/getAllStatus');
        return response;
    }catch(err){
        return err;
    }
}
export {getStatusCount, getLeadsReport}