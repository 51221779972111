import Axios from 'axios';
import { API_URL } from '../../../DesktopApp';

const getLeads = async(LIMIT, page, status) => {
    try{
      if(status === 'new_leads'){
        const response = await Axios.post(`${API_URL}/status/getNewLeads`, {limit: LIMIT, page: page, status: status});
        return response

      }else if(status === 'undefined'){
        const response = await Axios.post(`${API_URL}/status/getUndefinedLeads`, {limit: LIMIT, page: page, status: status});
        return response
      }else{
        const response = await Axios.post(`${API_URL}/status/getStatusWiseLeads`, {limit: LIMIT, page: page, status: status});
        return response
      }
    }catch(err){
        return err;
    }
}

const unSetLead = async(lead_id) => {
  try{
    const response = await Axios.post(`${API_URL}/leads/deletelead`, {
      lid: lead_id,
    });
    return response;
  }catch(err){
      return err;
  }
};

export {getLeads, unSetLead}