import React from 'react';
import DashboardPage from './DashboardPage/DashboardPage';

const Dashboard = () => {
  return (
    <React.Fragment>
      <DashboardPage /> 
    </React.Fragment>
  )
}

export default Dashboard;